import React from "react";
import {
    AccessTime,
    AcUnit,
    Commute,
    DateRange,
    Description,
    EmojiTransportation,
    Equalizer,
    EventNote,
    Group,
    LocationOn,
    Money,
    NoteAdd,
    PeopleAlt,
    PersonAdd,
    QueryBuilder,
    Settings,
    ShoppingCart,
    Toc,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { BiBarcodeReader } from "react-icons/bi";
import { FaPen } from "react-icons/fa";
import ClassIcon from "@mui/icons-material/Class";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import QuizIcon from "@mui/icons-material/Quiz";
import { useSelector } from "react-redux";
import { BsGrid1X2 } from "react-icons/bs";
import SchoolIcon from "@mui/icons-material/School";
import BallotIcon from "@mui/icons-material/Ballot";
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import KeyIcon from '@mui/icons-material/Key';
import FindInPageIcon from '@mui/icons-material/FindInPage';

export const items = [
    {
        title: "داشبورد",
        icon: <DashboardIcon />,
        color: "rgb(244, 67, 54)",
        category: "mission",
        accessLevel: ["Owner", "Admin", "Expert", "Teacher", "Student"],
        route: "/",
        children: [
            {
                title: "داشبورد مالک",
                icon: <BsGrid1X2 />,
                route: "/ownerdashboard",
                accessLevel: ["Owner"],
            },
            {
                title: "داشبورد ادمین",
                icon: <BsGrid1X2 />,
                accessLevel: ["Admin"],
                route: "/admindashboard",
            },
            {
                title: "داشبورد کارشناس",
                icon: <BsGrid1X2 />,
                accessLevel: ["Expert"],
                route: "/expertdashboard",
            },
            {
                title: "داشبورد استاد",
                icon: <BsGrid1X2 />,
                accessLevel: ["Teacher"],
                route: "/teacherdashbaord",
            },
            {
                title: "داشبورد دانشجو",
                icon: <BsGrid1X2 />,
                accessLevel: ["Student"],
                route: "/studentdashboard",
            },
        ],
    },
    {
        title: "دوره",
        icon: <ClassIcon />,
        color: "rgb(255, 152, 0)",
        category: "course",
        accessLevel: ["Owner", "Admin"],
        children: [
            {
                title: "درج دوره",
                icon: <AddBoxIcon />,
                route: "/addcourse",
                accessLevel: ["Owner", "Admin"],
            },
            {
                title: "لیست دوره‌ها",
                icon: <Toc />,
                accessLevel: ["Owner", "Admin"],
                route: "/courseslist",
            },
        ],
    },
    {
        title: "کلاس",
        icon: <HomeWorkIcon />,
        color: "rgb(76, 175, 80)",
        category: "class",
        accessLevel: ["Owner", "Admin" , "Teacher"],
        children: [
            {
                title: "درج کلاس",
                icon: <AddBoxIcon />,
                accessLevel: ["Owner", "Admin"],
                route: "/addclass",
            },
            {
                title: "لیست کل کلاس‌ها",
                icon: <FormatListBulletedIcon />,
                accessLevel: ["Owner", "Admin"],
                route: "/classlist",
            },
            {
                title: "لیست کلاس‌ها(استاد)",
                icon: <FormatListBulletedIcon />,
                accessLevel: ["Teacher"],
                route: "/scoreClassList",
            },
        ],
    },
    {
        title: "مدیریت کاربران",
        icon: <PeopleAlt />,
        color: "#304ffe",
        category: "users",
        accessLevel: ["Owner", "Admin"],
        children: [
            {
                title: "درج کاربر",
                icon: <PersonAdd />,
                accessLevel: ["Owner", "Admin"],
                route: "/adduser",
            },
            {
                title: "لیست کاربران",
                icon: <Toc />,
                accessLevel: ["Owner", "Admin"],
                route: "/userslist",
            },
            {
                title: "درج لایسنس",
                icon: <KeyIcon />,
                accessLevel: ["Owner", "Admin" , "Expert"],
                route: "/addlicenselist",
            },
        ],
    },
    // {
    //     title: "نمرات",
    //     icon: <QuizIcon />,
    //     color: "#304ffe",
    //     category: "users",
    //     accessLevel: ["Owner", "Admin", "Teacher"],
    //     children: [
    //         {
    //             title: "مدیریت نمره",
    //             icon: <FaPen />,
    //             accessLevel: ["Owner", "Admin", "Teacher"],
    //             route: "/scoreClassList",
    //         },
    //         // {
    //         //   title:'لیست نمرات',
    //         //   icon:<FormatListBulletedIcon/>,
    //         //   accessLevel:["Owner","Admin","Teacher"],
    //         //   route:'/scorelist'
    //         // },
    //     ],
    // },
    {
        title: "کلاس‌ها(ثبت نام کرده)",
        icon: <HolidayVillageIcon />,
        color: "#304ffe",
        category: "users",
        accessLevel: ["Student"],
        children: [
            {
                title: "لیست کلاس‌ها",
                icon: <FormatListBulletedIcon />,
                accessLevel: ["Student"],
                route: "/studentClassesList",
            },
        ],
    },
    {
        title: "مدرک",
        icon: <SchoolIcon />,
        color: "#304ffe",
        category: "users",
        accessLevel: ["Owner", "Admin", "Teacher","Expert","Student"],
        children: [
            {
                title: "ساخت مدرک",
                icon: <AddBoxIcon />,
                accessLevel: ["Owner", "Admin", "Teacher","Expert"],
                route: "/addDegree",
            },
            {
              title:'لیست کل مدرک‌ها',
              icon:<FormatListBulletedIcon/>,
              accessLevel:["Owner", "Admin","Expert"],
              route:'/allDegreeList'
            },
            {
              title:'استعلام مدرک',
              icon:<FindInPageIcon/>,
              accessLevel:["Owner", "Admin","Expert"],
              route:'/degreeTracking'
            },
            {
              title:'لیست مدرک‌ها',
              icon:<FormatListBulletedIcon/>,
              accessLevel:["Student"],
              route:'/studentDegreeList'
            },
        ],
    },
    // {
    //     title: "نظرسنجی",
    //     icon: <BallotIcon />,
    //     color: "#304ffe",
    //     category: "users",
    //     accessLevel: ["Student"],
    //     children: [
    //         {
    //             title: "ثبت نظر",
    //             icon: <ThumbsUpDownIcon />,
    //             accessLevel: ["Student"],
    //             route: "/surveyMainPage",
    //         },
    //     ],
    // },
    // {
    //   title:'مدیریت ',
    //   icon:<PeopleAlt />,
    //   color:'#304ffe',
    //   category:'users',
    //   accessLevel:["Teacher", "Owner"],
    //   children:[
    //     {
    //       title:'درج',
    //       icon:<PersonAdd/>,
    //       accessLevel:["Admin"],
    //       route:'/adduser'
    //     },
    //     {
    //       title:'لیست',
    //       icon:<Toc/>,
    //       accessLevel:["Teacher"],
    //       route:'/userslist'
    //     },
    //   ]
    // },
];
