import {IconButton, Toolbar, Typography, CssBaseline, Box, useMediaQuery , useTheme} from "@mui/material";

import React, {useState} from "react";
import {AiOutlineMenu} from "react-icons/ai";
import {MenuHeader} from "./menu.header";
import {MenuLayout} from "../menu/menu.layout";
// import {Copyright} from "../../../components";
import { styled,} from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { ProfileHeader } from "./profile.header";
import { MiniSetting } from "../miniSetting/mini.setting";
import { Sidebar } from "../sidebar/sidebar.layout";
import logo from "../../../asset/images/Logo-Eliyeen.png"






const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Content = styled('main')(({theme})=>({
    overflowX:"hidden",
    flexGrow:1,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3.5)
  }))

  const StyledToolbar = styled('div')(({theme})=>({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }))

  function HeaderLayout(props: any){
    const matchesLogo = useMediaQuery('(max-width:768px)');
    const matchesHiddenLogo = useMediaQuery('(max-width:425px)');
    
    const [open , setOpen] = useState(matchesLogo ? false: true);

    const handleDrawerOpen = () =>{
        setOpen(true);
    }

    const handleDrawerClose = () =>{
        setOpen(false);
    }
    const matches = useMediaQuery(useTheme().breakpoints.down("md"))

    return(
        <Box sx={{
            display: "flex",
            minHeight:"100vh"
        }}>
        <CssBaseline/>
        <AppBar position="fixed"
         open={open}
         sx={{backgroundColor:(theme)=> theme.palette.background.paper}}
         >
            <Toolbar style={{justifyContent:"space-between" , paddingLeft:0}}>
            <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
            size="large">
            <AiOutlineMenu/>
          </IconButton>

          {/* <Typography variant="h6" noWrap> */}
            {/* سامانه مدیریت اختر برق */}
          {/* </Typography> */}
           {/* {open ? <img src={tempLoogo} alt="logo" style={{width: 50, boxShadow:'none' , marginRight:"auto" , marginLeft:"auto"}}/>
            : 
           <img src={tempLoogo} alt="logo" style={{width: 50, boxShadow:'none' , marginLeft:matchesLogo ? "28px" : null}}/> 
           } */}
           {!open && !matchesHiddenLogo ? <img src={logo} alt="logo" style={{width: 58, boxShadow:'none' , marginRight:"auto" , marginLeft:"auto"}}/>
            : !open ? 
           <img src={logo} alt="logo" style={{width: 58, boxShadow:'none' , marginLeft:matchesLogo ? "28px" : null}}/> 
           :<img src="" alt="logo" style={{width: 58, boxShadow:'none',visibility:"hidden" , marginLeft:matchesLogo ? "28px" : null}}/> }

          {/* <MenuHeader/> */}
            <ProfileHeader/>
            </Toolbar>
        </AppBar>
        <MenuLayout open={open} onClose={handleDrawerClose}/>
        <Content>
            <StyledToolbar/>
            {props.children}
        </Content>
           {matches ? <MiniSetting/> : <Sidebar/>}

        <Box m={2} sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}>
        {/* <Copyright/> */}
      </Box>
        </Box>
    )
  }

  export {HeaderLayout}