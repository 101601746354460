import { HeaderLayout } from "./components/header/header.layout";
import { MiniSetting } from "./components/miniSetting/mini.setting";
import { Sidebar } from "./components/sidebar/sidebar.layout";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";


function MainLayout (props: any) {

const matches = useMediaQuery(useTheme().breakpoints.down("md"))

    return(
        <>
        <HeaderLayout>
            {props.children}
            <Outlet/>
        </HeaderLayout>
        {/* {matches ? <MiniSetting/> : <Sidebar/>} */}
        </>
    )
}
export {MainLayout};