import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireAuth = ({ allowedRoles }: any) => {
   const user = useSelector((state: any) => state.global.user);
   const location = useLocation();

   // console.log(user.roles);
   // console.log(allowedRoles);
   // const tt = user.roles?.find((role: any) => allowedRoles?.includes(role));
   // console.log(tt);

   return user?.roles?.find((role: any) => allowedRoles?.includes(role)) ? (
      <Outlet />
   ) :
    user ? (
      <Navigate to="/" state={{ from: location }} replace />
   ) :
    (
      <Navigate to="/login" state={{ from: location }} replace />
   );
};

export { RequireAuth };
