import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
//     return await client.get('users')
// })

const globalAdapter = createEntityAdapter();

// export const {
//     selectById: selectUserById,
//     selectAll: selectAllUsers,
// } = usersAdapter.getSelectors(state => state.users)

const initialState = globalAdapter.getInitialState({
  dark: false,
  loader: false,
  menu: false,
  fontFamily: localStorage.getItem("fontFamilyIpa") ?? "IRANSans",
  // borderRadius: +localStorage?.getItem("borderRadiusIpa") ?? 10,
  borderRadius: 1,
  fontSize: localStorage.hasOwnProperty("fontSizeIpa") ? +localStorage?.getItem("fontSizeIpa") : 14,
  user:
    localStorage.getItem("EllenUser") !== "undefined"
      ? localStorage.hasOwnProperty("EllenUser") && JSON.parse(localStorage.getItem("EllenUser"))
      : null,
  detailsTable: true,
  confirmDetailsTable:false,
});

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleDark: {
      reducer(state, action) {
        state.dark = !state.dark;
      },
    },
    toggleLoader: {
      reducer(state, action) {
        state.loader = action.payload;
      },
    },
    actionLogin: {
      reducer(state, action) {
        localStorage.setItem("EllenUser", JSON.stringify(action.payload));
        state.user = action.payload;
      },
    },
    actionExit: {
      reducer(state) {
        localStorage.removeItem("EllenUser");
        state.user = null;
      },
    },
    changeFontFamily: {
      reducer(state, action) {
        localStorage.setItem("fontFamilyIpa", action.payload);
        state.fontFamily = action.payload;
      },
    },
    toggleDetailsTable: {
      reducer(state, action) {
        state.detailsTable = action.payload;
      },
    },
    toggleConfirmDetailsTable: {
      reducer(state, action) {
        state.confirmDetailsTable = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    // builder
    // .addCase(addNewComment, (state, action) => {
    //     const { postId, comment } = action.payload
    //     state.byId[postId].comments.push(comment.id)
    // }),
    // [fetchUsers.pending]: (state) => {
    //     state.status = 'loading'
    // },
  },
});

export const { toggleDark, toggleLoader, actionLogin, actionExit, changeFontFamily, toggleDetailsTable , toggleConfirmDetailsTable} = globalSlice.actions;

export default globalSlice.reducer;
