import { Widget2 } from "../widgets/widget2";
import { motion } from "framer-motion";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { GetLogedInStudentClasses } from "api/student/studentApi";
import { toast } from "react-toastify";
import { TeacherDashboardInfo } from "api/teacher/teacherApi";

function TeacherDashboard() {
  const [dashboardInfo, setDashboardInfo] = useState<any>([]);

  const getAllNeedData = async () => {
    const res: any = await TeacherDashboardInfo();
    console.log(res);
    if (!(res instanceof Error)) {
      setDashboardInfo(res);
    } else {
      toast.error("دریافت داده‌ها با مشکل مواجه شد");
    }
  };

  useEffect(() => {
    getAllNeedData();
  }, []);

  const data1 = {
    id: "card1-teach",
    title: "دانشجویان",
    data: {
      name: "کل دانشجویان",
      count: dashboardInfo?.totalStudent ?? 0,
      extra: {
        name: "تعداد دانشجویان قبول شده:",
        count: dashboardInfo?.totalPasedStudent ?? 0,
      },
    },
    color: "#2196F3",
  };
  const data2 = {
    id: "card1-teach",
    title: "‌کلاس‌ها",
    data: {
      name: "کل کلاس‌ها",
      count: dashboardInfo?.totalClasses ?? 0,
      // extra: {
      //    name: "تعداد کلاس‌های تمام شده",
      //    count: "",
      // },
    },
    color: "#4CAF50",
  };
  const data3 = {
    id: "card1-teach",
    title: "‌ساعت‌ها",
    data: {
      name: "مجموع ساعت کلاس‌ها",
      count: dashboardInfo?.totalClassHour ?? 0,
      extra: {
        name: "مجموع ساعت‌های رد شده:",
        count: dashboardInfo?.totalPasedClassHour ?? 0,
      },
    },
    color: "#FF9800",
  };
  const data4 = {
    id: "card1-teach",
    title: "نمرات",
    data: {
      name: "میانگین کل نمرات",
      // count: dashboardInfo?.averageGrades !== "NaN" || String(dashboardInfo?.averageGrades) !== "undefined"  ? String(dashboardInfo?.averageGrades)?.length > 3 ? (dashboardInfo?.averageGrades).toFixed(2) : (dashboardInfo?.averageGrades)  : 0 ,
      count: typeof dashboardInfo?.averageGrades === "number" ? (dashboardInfo?.averageGrades).toFixed(2) : 0,
      // extra: {
      //    name: "تعداد نمره‌های رد نشده",
      //    count: "",
      // },
    },
    color: "#C0C0C0",
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Grid container justifyContent={"space-between"} spacing={4}>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.4 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data1} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.7 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data2} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data3} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1.3 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data4} />
        </div>
      </Grid>
    </Grid>
  );
}

export { TeacherDashboard };
