import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useDispatch } from 'react-redux';
import { toggleDetailsTable } from 'redux/globalSlice';

function MenuActionTable({items, icon, user, menuId}: any) {
  const [open, toggleOpen] = React.useState<HTMLElement | null>(null);
  const dispatch = useDispatch()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(toggleDetailsTable(false))
    toggleOpen(e.currentTarget);
  };
  
  const handleClose = () => {
    dispatch(toggleDetailsTable(true))
    toggleOpen(null);
  };

  return (
      <>
        <Button aria-controls={menuId} aria-haspopup="true" onClick={handleClick}>
          {icon}
        </Button>
        <Menu
            id={menuId}
            anchorEl={open}
            keepMounted
            open={!!open}
            onClose={handleClose}
            TransitionComponent={Fade}
            // getContentAnchorEl={null}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
          {items.map((item: any, index: number) => (
              <MenuItem key={index} onClick={() => item.onClick(user)}>{item.title}</MenuItem>
          ))}
        </Menu>
      </>
  );
}

export {MenuActionTable};
