import React, { useEffect, useState, useRef } from "react";
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    TableCell,
    TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { useParams } from "react-router-dom";
import { ColumnPro } from "../../../../components/table-pro/table-pro.component";
import { sortDates, sortNumbers, sortStrings } from "../../../utils/sort.utils";
import moment from "jalali-moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, Settings } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { accessLevelName } from "utils/accessLevel.utils";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { changeUserActivation, deleteUser, getAllUsers, getRoles, putRoles, putUser } from "api/user/userApi";
import PasswordIcon from '@mui/icons-material/Password';

const columns: any = [
    {
        id: "counter",
        label: "#",
        align: "center",
        minWidth: 15,
        state: 0,
        numeric: true,
        show: true,
    },
    {
        id: "fullName",
        label: "نام کامل",
        align: "left",
        minWidth: 60,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "fatherName",
        label: "نام پدر",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },

    {
        id: "age",
        label: "سن",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: true,
        show: true,
        sort: sortNumbers,
    },
    // {
    //     id: "userName",
    //     label: "نام کاربری",
    //     align: "left",
    //     minWidth: 60,
    //     state: 0,
    //     numeric: false,
    //     show: true,
    //     sort: sortStrings,
    // },
    {
        id: "gender",
        label: "جنسیت",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "roles",
        label: "نقش‌ها",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "isActive",
        label: "فعال",
        align: "left",
        minWidth: 5,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "nationalCode",
        label: "کد ملی",
        align: "center",
        minWidth: 60,
        state: 0,
        numeric: false,
        show: true,
        // sort: sortStrings,
    },
    {
        id: "nationality",
        label: "ملیت",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },

    // {
    //   id: "numberOfSessions",
    //   label: "تعداد جلسات",
    //   align: "center",
    //   minWidth: 20,
    //   state: 0,
    //   numeric: true,
    //   show: true,
    //   sort: sortNumbers,
    // },
    // {
    //   id: "hasExam",
    //   label: "امتحان",
    //   align: "left",
    //   minWidth: 60,
    //   state: 0,
    //   numeric: false,
    //   show: true,
    //   sort: sortStrings,
    // },
    // {
    //   id: "description",
    //   label: "توضیحات دوره",
    //   align: "left",
    //   minWidth: 60,
    //   state: 0,
    //   numeric: false,
    //   show: true,
    //   sort: sortStrings,
    // },
    // {
    //   id: "createdAt",
    //   label: "تاریخ ثبت",
    //   align: "center",
    //   minWidth: 30,
    //   state: 0,
    //   numeric: true,
    //   show: true,
    //   sort: sortDates,
    //   format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
    // },
    {
        id: "action",
        label: "عملیات",
        align: "center",
        minWidth: 10,
        state: 0,
        numeric: true,
        show: true,
        // sort: sortDates,
    },
];

interface CellProps extends TableCellProps {
    active: boolean;
}

const TableCellStyled = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
    backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function UsersList({ startDate, endDate }: any) {
    const [rows, setRows] = useState<any>([]);
    const [edit, setEdit] = useState(null);
    const [roleEdit, setRoleEdit] = useState(null);
    const [isActiveEdit, setIsActiveEdit] = useState(null);
    const [remove, setRemove] = useState<any>(null);
    const [passEdit, setPassEdit] = useState(null)
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [rolesItem, setRolesItems] = useState([]);

    const refImage = useRef<HTMLInputElement>(null);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    useEffect(() => {
        getAllNeedData();
    }, [startDate, endDate]);

    const getAllNeedData = async () => {
        const resUser: any = await getAllUsers();
        const resRoles: any = await getRoles();
        if (!(resUser instanceof Error) && !(resRoles instanceof Error)) {
            setRows(resUser);
            setRolesItems(resRoles);
        } else {
            toast.error("دریافت داده‌ها با مشکل مواجه شد");
        }
    };

    const validation = Yup.object({
        // firstName: Yup.string()
        // .required("نباید خالی باشد")
        // .test(
        //     "نام را به درستی وارد کنید",
        //     "نام را به درستی وارد کنید",
        //     //@ts-ignore
        //     function (name = "") {
        //         const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
        //         return re.test(String(name).toLowerCase());
        //     }
        // ),
        // lastName: Yup.string()
        // .required("نباید خالی باشد")
        // .test(
        //     "نام خانوادگی را به درستی وارد کنید",
        //     "نام خانوادگی را به درستی وارد کنید",
        //     //@ts-ignore
        //     function (name = "") {
        //         const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
        //         return re.test(String(name).toLowerCase());
        //     }
        // ),
         fullName: Yup.string()
        .required("نباید خالی باشد")
        .test(
            "نام را به درستی وارد کنید",
            "نام را به درستی وارد کنید",
            //@ts-ignore
            function (name = "") {
                const re = /^(?=.{1,70}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
                return re.test(String(name).toLowerCase());
            }
        ),
        fatherName: Yup.string()
            .required("نباید خالی باشد")
            .test(
                "نام پدر را به درستی وارد کنید",
                "نام پدر را به درستی وارد کنید",
                //@ts-ignore
                function (name = "") {
                    const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
                    return re.test(String(name).toLowerCase());
                }
            ),
      gender:Yup.string().required("نباید خالی باشد"),
      nationalCode: Yup.string()
      .required("نباید خالی باشد")
      .test(
          "کد ملی را به درستی وارد کنید",
          "کد ملی را به درستی وارد کنید",
          //@ts-ignore
          function (code = "") {
              const re = /^\d+$/;
              return re.test(String(code).toLowerCase());
          }
      ),
    //   nationality:Yup.string()
    //   .required("نباید خالی باشد")
    //   .test(
    //       "نام پدر را به درستی وارد کنید",
    //       "نام پدر را به درستی وارد کنید",
    //       //@ts-ignore
    //       function (name = "") {
    //           const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
    //           return re.test(String(name).toLowerCase());
    //       }
    //   ),
    //   roles: Yup.array().nullable().required("نباید خالی باشد"),
      email: Yup.string()
        .required("نباید خالی باشد")
        .test("ایمیل معتبر نیست", "ایمیل معتبر نیست", function (email = "") {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        }),
      //   image: Yup.string().required("نباید خالی باشد"),
      //   imageName: Yup.string().required("نباید خالی باشد"),
      // imageDesc: Yup.string().required("نباید خالی باشد"),
     birthDate: Yup.string()
      .nullable()
      .required("نباید خالی باشد")
      .test("تاریخ معتبر نیست", "تاریخ معتبر نیست", function (value: string) {
          return moment(value).isValid();
      }),
      phoneNumber:Yup.string()
      .nullable()
      .required('نباید خالی باشد')
      .test("شماره موبایل را به درستی وارد کنید", "شماره موبایل را به درستی وارد کنید", function (value = '') {
        const reg = /^09[0-9]{9}/
        return reg.test(String(value).toLocaleLowerCase())
      }),
    });

    const roleEditValidation = Yup.object({
      roles: Yup.array().nullable().required("نباید خالی باشد"),
    });



    const editFormik = useFormik({
        initialValues: {
            // firstName: "",
            // lastName: "",
            // userName: "",
            fullName:"",
            fatherName: "",
            nationalCode: "",
            gender: "Male",
            // isActive: true,
            // roles: null,
            nationality: "",
            birthDate: null,
            // age: null,
            // image: "",
            // imageName: "",
            // imageDesc: "",
            email: "",
            phoneNumber:""
        },
        validationSchema: validation,
        onSubmit: async (values, { resetForm }) => {
            // const FullName = `${values.firstName} ${values.lastName}`;
            // const nameAccessLevel:object[] = values?.roles?.map((item: any) => {
            //   return item.name;
            // });
            // delete values.firstName;
            // delete values.lastName;
            // delete values.roles;
            const res: any = await putUser({
                UserName:values.nationalCode,
                FullName:values.fullName,
                FatherName:values.fatherName,
                NationalCode:values.nationalCode,
                Nationality:values.nationality,
                Gender:values.gender,
                BirthDate:values.birthDate,
                PhoneNumber:values.phoneNumber,
                Email:values.email 
               }, edit.id);
            if (!(res instanceof Error)) {
               getAllNeedData();
               setEdit(false);
               toast.success("ویرایش اطاعات کاربر با موفقیت انجام شد");
            } else {
               toast.error("ثبت اطلاعات با خطا مواجه شد");
            }
        },
    });

    useEffect(() => {
        if (edit) {
            console.log(edit);
            // const tempRoles: object[] = [];
            // rolesItem.map((role: any) => {
            //     edit.roles.map((item: any) => {
            //         if (item === role.name) {
            //             tempRoles.push(role);
            //         }
            //     });
            // });
            // setFilteredRoles(tempRoles);

            editFormik.setValues({
                // firstName: edit.fullName.trim().split(/\s+/)[0],
                // lastName: edit.fullName.trim().split(/\s+/)[1],
                // userName: edit.userName,
                fullName:edit.fullName,
                fatherName: edit.fatherName,
                nationalCode: edit.nationalCode,
                gender: edit.gender,
                // isActive: edit.isActive,
                // roles: edit.roles,
                nationality: edit.nationality,
                birthDate: edit.birthDate,
                // age: edit.age,
                // image: edit.image,
                // imageName: edit.imageName,
                // imageDesc: edit.imageDesc,
                email: edit.email,
                phoneNumber:edit.phoneNumber
            });
        }
    }, [edit]);


    const roleEditFormik = useFormik({
        initialValues: {
            roles: null,
        },
        validationSchema: roleEditValidation,
        onSubmit: async (values, { resetForm }) => {
            const nameAccessLevel:object[] = values?.roles?.map((item: any) => {
              return item.name;
            });
            console.log(nameAccessLevel);
            const res: any = await putRoles(nameAccessLevel, roleEdit.id);
            if (!(res instanceof Error)) {
               console.log(res);
               getAllNeedData();
               setRoleEdit(false);
               toast.success("ویرایش نقش کاربر با موفقیت انجام شد");
            } else {
                toast.error("ثبت اطلاعات با خطا مواجه شد");
                setRoleEdit(false);
            }
        },
    });

    useEffect(() => {
        if (roleEdit) {
            const tempRoles: object[] = [];
            rolesItem?.map((role: any) => {
                roleEdit?.roles?.map((item: any) => {
                    console.log(item)
                    if (item === role?.name) {
                        tempRoles.push(role);
                    }
                });
            });
            console.log(tempRoles);
            setFilteredRoles(tempRoles);

            roleEditFormik.setValues({
                roles: roleEdit.roles,
            });
        }
    }, [roleEdit]);


    
    useEffect(() => {
        roleEditFormik.setFieldValue("roles", filteredRoles)
    }, [filteredRoles])



    const isActiveFormik = useFormik({
        initialValues: {
            isActive: true,
        },
        // validationSchema: validation,
        onSubmit: async (values, { resetForm }) => {
            const res: any = await deleteUser(isActiveEdit.id);
            if (!(res instanceof Error)) {
               console.log(res);
               getAllNeedData();
               setIsActiveEdit(null);
               toast.success("ویرایش وضعیت فعال کاربر با موفقیت انجام شد");
            } else {
               toast.error("ثبت اطلاعات با خطا مواجه شد");
            }
        },
    });

    useEffect(() => {
        if (isActiveEdit) {
            console.log(isActiveEdit);
            isActiveFormik.setValues({
                isActive: isActiveEdit.isActive,
            });
        }
    }, [isActiveEdit]);
    
    
    useEffect(() => {
        getAllNeedData();
    }, []);
    
    const handleRemoveUser = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     const res = await deleteUser(remove);
    //     if (!(res instanceof Error)) {
    //         const tmpRows = [...rows];
    //         const index = tmpRows.findIndex((row) => row.id === remove);
    //         await tmpRows.splice(index, 1);
    //         setRows(tmpRows);
    //         setRemove(null);
    //         toast.success("کاربر با موفقیت حذف شد");
    //     } else {
    //         toast.error("حذف کاربر با خطا مواجه شد");
    //     }
    };

    const itemsOfAction = [
        {
            title: (
                <Button color="inherit">
                <Edit style={{fontSize:"1.4rem"}}/>
                    &nbsp;  ویرایش اطلاعات کاربر
                </Button>
            ),
            onClick: async (user: any) =>
                await new Promise((resolve, reject) => {
                    setEdit(user);
                }),
        },
        // {
        //     title: (
        //         <Button color="inherit">
        //         <PasswordIcon style={{fontSize:"1.5rem"}}/>
        //             &nbsp;  ویرایش رمز عبور کاربر
        //         </Button>
        //     ),
        //     onClick: async (user: any) =>
        //         await new Promise((resolve, reject) => {
        //             setPassEdit(user);
        //         }),
        // },
        {
            title: (
                <Button color="inherit">
                <AdminPanelSettingsIcon style={{fontSize:"1.5rem"}}/>
                    &nbsp;  ویرایش نقش کاربر
                </Button>
            ),
            onClick: async (user: any) =>
                await new Promise((resolve, reject) => {
                    setRoleEdit(user);
                }),
        },
        {
            title: (
                <Button color="inherit">
                <HowToRegIcon style={{fontSize:"1.5rem"}}/>
                    &nbsp;  ویرایش وضعیت فعال کاربر
                </Button>
            ),
            onClick: async (user: any) =>
                await new Promise((resolve, reject) => {
                    setIsActiveEdit(user);
                }),
        },
      
        // {
        //     title: (
        //         <Button color="inherit">
        //         <Delete style={{fontSize:"1.5rem"}}/>
        //             &nbsp; حذف
        //         </Button>
        //     ),
        //     onClick: (user: any) => setRemove(user.id),
        // },
    ];

    return (
        <>
            <Grid container spacing={2}  sx={{mt:2}}>
                {/* <ListInfo /> */}
                <Grid item xs={12} style={{ width: 10 }}>
                    <Paper>
                        <TableProIpa
                            columns={columns}
                            bodyFunc={(column: any, row: any, index: any) => {
                                const head = column.id;
                                const value = row[column.id];
                                if (head === "action") {
                                    return (
                                        <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                                            <MenuActionTable menuId={row.id} items={itemsOfAction} icon={<Settings />} user={row} />
                                        </TableCellStyled>
                                    );
                                } else if (head === "isActive") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={value ? "success" : "error"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value ? "هست" : "نیست"}
                                            </Button>
                                        </TableCell>
                                    );
                                } else if (head === "gender") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={"inherit"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value === "Male" ? "مرد" : "زن"}
                                            </Button>
                                        </TableCell>
                                    );
                                } else if (head === "roles") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={"inherit"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value?.map((item: any) => {
                                                    switch (item) {
                                                        case "Owner":
                                                            return "مالک \n";
                                                        case "Student":
                                                            return "دانشجو \n";
                                                        case "Expert":
                                                            return "کارشناس \n";
                                                        case "Teacher":
                                                            return "معلم \n";
                                                        case "Admin":
                                                            return "ادمین \n";
                                                    }
                                                })}
                                            </Button>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.format && value ? column.format(value) : value}
                                    </TableCell>
                                );
                            }}
                            rows={rows ?? []}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {edit ? (
                <ModalIpa
                    open={Boolean(edit)}
                    title={`ویرایش اطاعات کاربر ${edit.fullName}`}
                    onClose={() => {
                        setEdit(null);
                        editFormik.handleReset(1);
                        setFilteredRoles([]);
                    }}>
                    <form noValidate onSubmit={editFormik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id={"fullName"}
                                    variant="outlined"
                                    label={"نام کامل"}
                                    fullWidth
                                    required
                                    value={editFormik.values.fullName}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.fullName && Boolean(editFormik.errors.fullName)}
                                    //@ts-ignore
                                    helperText={editFormik.touched.fullName && editFormik.errors.fullName}
                                />
                            </Grid>
                            {/* <Grid item sm={6} xs={12}>
                                <TextField
                                    id={"lastName"}
                                    variant="outlined"
                                    label={"نام خانوادگی"}
                                    fullWidth
                                    required
                                    value={editFormik.values.lastName}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.lastName && Boolean(editFormik.errors.lastName)}
                                    //@ts-ignore
                                    helperText={editFormik.touched.lastName && editFormik.errors.lastName}
                                />
                            </Grid> */}
                            {/* <Grid item sm={6} xs={12}>
                                <TextField
                                    id={"userName"}
                                    variant="outlined"
                                    label={"نام کاربری"}
                                    fullWidth
                                    required
                                    value={editFormik.values.userName}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.userName && Boolean(editFormik.errors.userName)}
                                    helperText={editFormik.touched.userName && editFormik.errors.userName}
                                />
                            </Grid> */}
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    id={"fatherName"}
                                    variant="outlined"
                                    label={"نام پدر"}
                                    fullWidth
                                    value={editFormik.values.fatherName}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.fatherName && Boolean(editFormik.errors.fatherName)}
                                    helperText={editFormik.touched.fatherName && editFormik.errors.fatherName}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    id={"nationalCode"}
                                    type="text"
                                    variant="outlined"
                                    label={"کد ملی"}
                                    fullWidth
                                    value={editFormik.values.nationalCode}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.nationalCode && Boolean(editFormik.errors.nationalCode)}
                                    helperText={editFormik.touched.nationalCode && editFormik.errors.nationalCode}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    id={"nationality"}
                                    variant="outlined"
                                    label={"ملیت"}
                                    fullWidth
                                    value={editFormik.values.nationality}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.nationality && Boolean(editFormik.errors.nationality)}
                                    helperText={editFormik.touched.nationality && editFormik.errors.nationality}
                                />
                            </Grid>
                            {/* <Grid item sm={6} xs={12}>
                                <LocalizationProvider dateAdapter={AdapterJalali}>
                                    <DatePicker
                                        //    okText={"تایید"}
                                        //    cancelText={"لغو"}
                                        label="تاریخ تولد"
                                        mask="____/__/__"
                                        value={editFormik.values.birthDate}
                                        onChange={(newValue: any) =>
                                            moment(newValue, "YYYY/MM/DD").isValid() &&
                                            editFormik.setFieldValue("birthDate", moment(newValue).locale("en").format("YYYY-MM-DD"))
                                        }
                                        renderInput={(params: any) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid> */}
                            <Grid item sm={6} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterJalali}>
                            <DatePicker
                                //    okText={"تایید"}
                                //    cancelText={"لغو"}
                                label="تاریخ تولد"
                                mask="____/__/__"
                                value={editFormik.values.birthDate}
                                onChange={(newValue: any) => moment(newValue, "YYYY/MM/DD").isValid() && editFormik.setFieldValue("birthDate", moment(newValue).locale("en").format("YYYY-MM-DD"))}
                                renderInput={(params: any) => <TextField 
                                    {...params}
                                    id={"birthDate"}
                                    fullWidth
                                    required
                                    error={editFormik.touched.birthDate && Boolean(editFormik.errors.birthDate)}
                                    //@ts-ignore
                                    helperText={editFormik.touched.birthDate && editFormik.errors.birthDate}
                                      />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id={"email"}
                            variant="outlined"
                            label={"ایمیل"}
                            required
                            type={"email"}
                            fullWidth
                            value={editFormik.values.email}
                            onChange={editFormik.handleChange}
                            error={editFormik.touched.email && Boolean(editFormik.errors.email)}
                            helperText={editFormik.touched.email && editFormik.errors.email}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"phoneNumber"}
                            type="text"
                            variant="outlined"
                            label={"شماره موبایل"}
                            fullWidth
                            required
                            value={editFormik.values.phoneNumber}
                            onChange={editFormik.handleChange}
                            error={editFormik.touched.phoneNumber && Boolean(editFormik.errors.phoneNumber)}
                            helperText={editFormik.touched.phoneNumber && editFormik.errors.phoneNumber}
                        />
                    </Grid>
                            {/* <Grid item sm={6} xs={12}>
                                <Autocomplete
                                    value={editFormik.values.roles ?? []}
                                    onChange={(event: any, newValue: any) =>
                                       editFormik.setFieldValue("roles", newValue.length === 0 ? null : newValue)
                                    }
                                    multiple
                                    id="checkboxes-tags-company"
                                    options={rolesItem}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => {
                                        //@ts-ignore
                                        return accessLevelName[`${option.name}`];
                                    }}
                                    renderOption={(props, option: any, { selected }) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {
                                                    //@ts-ignore
                                                    accessLevelName[`${option.name}`]
                                                }
                                            </li>
                                        );
                                    }}
                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="سطح دسترسی"
                                            placeholder="سطح دسترسی را انتخاب کنید"
                                            error={editFormik.touched.roles && Boolean(editFormik.errors.roles)}
                                            //@ts-ignore
                                            helperText={editFormik.touched.roles && editFormik.errors.roles}
                                            name={"roles"}
                                            id="roles"
                                            value={editFormik.values.roles}
                                        />
                                    )}
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={6}>
                                <TextField
                                    id={"email"}
                                    variant="outlined"
                                    label={"ایمیل"}
                                    required
                                    type={"email"}
                                    fullWidth
                                    value={editFormik.values.email}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.email && Boolean(editFormik.errors.email)}
                                    helperText={editFormik.touched.email && editFormik.errors.email}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">جنسیت</FormLabel>
                                    <RadioGroup
                                        aria-label="gender"
                                        name={"gender"}
                                        id="gender"
                                        style={{ flexDirection: "row" }}
                                        value={editFormik.values.gender}
                                        onChange={(event: any) => editFormik.setFieldValue("gender", event.target.value)}
                                        // @ts-ignore
                                        error={editFormik.touched.gender && Boolean(editFormik.errors.gender)}>
                                        <FormControlLabel value={"Male"} control={<Radio />} label="مرد" />
                                        <FormControlLabel value={"Female"} control={<Radio />} label="زن" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item sm={6} xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">فعال</FormLabel>
                                    <RadioGroup
                                        aria-label="isActive"
                                        name={"isActive"}
                                        id="isActive"
                                        style={{ flexDirection: "row" }}
                                        defaultValue={editFormik.values.isActive}
                                        onChange={(event: any) =>
                                            editFormik.setFieldValue("isActive", event.target.value === "true" ? true : false)
                                        }
                                        // @ts-ignore
                                        error={editFormik.touched.isLeader && Boolean(editFormik.errors.isLeader)}>
                                        <FormControlLabel value={true} control={<Radio />} label="هست" />
                                        <FormControlLabel value={false} control={<Radio />} label="نیست" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item sm={6} xs={12}> */}
                            {/* <label htmlFor="icon-button-file">
              <Input accept="image/*" id="icon-button-file" type="file" onChange={(e:any) => console.log(e.target.files[0])} />
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
              </IconButton>
            </label> */}
                            {/* <TextField
                           id={"image"}
                           variant="outlined"
                           label={"تصویر"}
                           fullWidth
                           style={{ direction: "ltr" }}
                           inputRef={refImage}
                           inputProps={{
                              accept: "image/jpg",
                           }}
                           type={"file"}
                           value={editFormik.values.image}
                           onChange={editFormik.handleChange}
                           error={editFormik.touched.image && Boolean(editFormik.errors.image)}
                           helperText={(editFormik.touched.image && editFormik.errors.image) || ` وارد کنید jpg تنها فایل   `}
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           id={"imageName"}
                           variant="outlined"
                           label={"اسم تصویر"}
                           required
                           type={"imageName"}
                           fullWidth
                           value={editFormik.values.imageName}
                           onChange={editFormik.handleChange}
                           error={editFormik.touched.imageName && Boolean(editFormik.errors.imageName)}
                           helperText={editFormik.touched.imageName && editFormik.errors.imageName}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <TextField
                           id={"imageDesc"}
                           type="text"
                           variant="outlined"
                           label={"توضیحات عکس"}
                           fullWidth
                           required
                           value={editFormik.values.imageDesc}
                           onChange={editFormik.handleChange}
                           error={editFormik.touched.imageDesc && Boolean(editFormik.errors.imageDesc)}
                           helperText={editFormik.touched.imageDesc && editFormik.errors.imageDesc}
                        />
                     </Grid> */}
                     <Grid item sm={3} xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">جنسیت</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name={"gender"}
                                id="gender"
                                style={{ flexDirection: "row" }}
                                defaultValue="Male"
                                value={editFormik.values.gender}
                                onChange={(event: any) => editFormik.setFieldValue("gender", event.target.value)}
                                // @ts-ignore
                                error={editFormik.touched.gender && Boolean(editFormik.errors.gender)}>
                                <FormControlLabel value={"Male"} control={<Radio />} label="مرد" />
                                <FormControlLabel value={"Female"} control={<Radio />} label="زن" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    ثبت کاربر
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null} 
            {roleEdit ? (
                <ModalIpa
                    open={Boolean(roleEdit)}
                    title={`ویرایش نقش کاربر ${roleEdit.fullName}`}
                    onClose={() => {
                        setRoleEdit(null);
                        roleEditFormik.handleReset(1);
                        setFilteredRoles([]);
                    }}>
                    <form noValidate onSubmit={roleEditFormik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={roleEditFormik.values.roles ?? []}
                                    onChange={(event: any, newValue: any) =>
                                       roleEditFormik.setFieldValue("roles", newValue.length === 0 ? null : newValue)
                                    }
                                    multiple
                                    id="checkboxes-tags-company"
                                    options={rolesItem}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => {
                                        //@ts-ignore
                                        return accessLevelName[`${option.name}`];
                                    }}
                                    renderOption={(props, option: any, { selected }) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {
                                                    //@ts-ignore
                                                    accessLevelName[`${option.name}`]
                                                }
                                            </li>
                                        );
                                    }}
                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="سطح دسترسی"
                                            placeholder="سطح دسترسی را انتخاب کنید"
                                            error={roleEditFormik.touched.roles && Boolean(roleEditFormik.errors.roles)}
                                            //@ts-ignore
                                            helperText={roleEditFormik.touched.roles && roleEditFormik.errors.roles}
                                            name={"roles"}
                                            id="roles"
                                            value={roleEditFormik.values.roles}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    ثبت نقش کاربر
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
            {isActiveEdit ? (
                <ModalIpa  
                open={Boolean(isActiveEdit)}
                title={`ویرایش وضعیت فعال کاربر ${isActiveEdit.fullName}`}
                onClose={() => {
                    setIsActiveEdit(null);
                    isActiveFormik.handleReset(1);
                    setFilteredRoles([]);
                }}>
                    <form onSubmit={isActiveFormik.handleSubmit}>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">فعال</FormLabel>
                                    <RadioGroup
                                        aria-label="isActive"
                                        name={"isActive"}
                                        id="isActive"
                                        style={{ flexDirection: "row" }}
                                        defaultValue={isActiveFormik.values.isActive}
                                        onChange={(event: any) =>
                                            isActiveFormik.setFieldValue("isActive", event.target.value === "true" ? true : false)
                                        }
                                        // @ts-ignore
                                        error={isActiveFormik.touched.isLeader && Boolean(isActiveFormik.errors.isLeader)}>
                                        <FormControlLabel value={true} control={<Radio />} label="هست" />
                                        <FormControlLabel value={false} control={<Radio />} label="نیست" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    ثبت وضعیت
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null} 
            {remove ? (
                <ModalIpa open={Boolean(remove)} title={`آیا از حذف کاربر مطمئن هستید؟`} onClose={() => setRemove(null)}>
                    <form onSubmit={handleRemoveUser}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ButtonsModal
                                    textSubmit={"تایید"}
                                    textClose={"انصراف"}
                                    onClose={() => {
                                        setRemove(false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
        </>
    );
}

export { UsersList };
