import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
// import {changePassword, checkSmsForgetPassword, sendSmsForgetPassword} from "../../../api/api";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import React, { useState , useRef } from "react";
import { ModalIpa } from "../../../components";
import classes from "./inputAutoFillColor.module.scss";
import { LocalPhone, Start } from "@mui/icons-material";
import { SendSMSForgetPassword, SetNewPasswordAfterToken, VerifyForgetSMSToken } from "api/user/userApi";
import Countdown  from "react-countdown";

function ForgetForm({ backToLogin }: any) {
  const [confirm, setConfirm] = useState<any>(null);
  const [changePass, setChangePass] = useState<any>(null);
  const [show, setShow] = useState<any>(false);
  const [keyResetCountdown , setKeyResetCountdown] = useState(null);
  const theme = useTheme();

  const refCounter = useRef(null);

  const validation = yup.object({
    PhoneNumber: yup
      .string()
      .required("نباید خالی باشد")
      .test("شماره موبایل را به درستی وارد کنید", "شماره موبایل را به درستی وارد کنید", function (value = "") {
        const reg = /^09[0-9]{9}/;
        return reg.test(String(value).toLocaleLowerCase());
      }),
  });

  const validationChange = yup.object({
    ConfirmPassword: yup
      .string()
      .required("نباید خالی باشد")
      .test("رمزها با هم تطابق ندارند", "رمزها با هم تطابق ندارند", function (value = "") {
        return value === this.parent.NewPassword;
      }),
    NewPassword: yup.string().required("نباید خالی باشد"),
  });

  const forgetFormik = useFormik({
    initialValues: {
      PhoneNumber: "",
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      const res = await SendSMSForgetPassword(values)
      if (!(res instanceof Error)) {
        setConfirm(true)
        toast.success('کد تایید برای شما ارسال شد')
      } else {
        toast.error('ارسال کد تایید با خطا مواجه شد')
      }
    },
  });

  const confirmFormik = useFormik({
    initialValues: {
      Token: "",
    },
    onSubmit: async (values , { resetForm}) => {
      const res = await VerifyForgetSMSToken({Token:refCounter.current.value , PhoneNumber:forgetFormik.values.PhoneNumber})
      if (!(res instanceof Error) && res.isSuccess) {
        setChangePass(res)
      } else {
        toast.error('کد تایید نامعتبر است')
        setKeyResetCountdown(new Date())
      }
    },
  });

  const changeFormik = useFormik({
    initialValues: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    validationSchema: validationChange,
    onSubmit: async (values , {resetForm}) => {
      delete values.ConfirmPassword;
      const res = await SetNewPasswordAfterToken({...values, PhoneNumber:forgetFormik.values.PhoneNumber})
      if (!(res instanceof Error) && res.isSuccess) {
        forgetFormik.handleReset(1)
        confirmFormik.handleReset(1)
        changeFormik.handleReset(1)
        setChangePass(null)
        backToLogin()
        toast.success('رمز عبور با موفقیت تغییر یافت')
      } else {
        changeFormik.handleReset(1)
        toast.error('تغییر رمز عبور با خطا مواجه شد')
      }
    },
  });

  // const reSendButton:any = () => )

  const renderCounter = ({ hours, minutes, seconds, completed , api }:any) => {
    if (completed) {
      // Render a complete state
      return (<Button onClick={() => {
        forgetFormik.handleSubmit()
        setKeyResetCountdown(new Date())
      }}
       sx={{fontSize:"13px"}} >ارسال مجدد</Button>)
    } else {
      // Render a countdown
      return (
        <Button sx={{fontSize:"15px" ,color:theme.palette.grey[400]}}>
          {minutes}:{seconds}
        </Button>
      );
    }
  };


  return (
    <>
      {!confirm && !(!!changePass) ? (
        <form noValidate onSubmit={forgetFormik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="username" sx={{ color: theme.palette.grey[400] }}>
                  شماره موبایل
                </InputLabel>
                <OutlinedInput
                  sx={{ bgc: "transparent", input: { color: theme.palette.common.white }, borderRadius: theme.shape.borderRadius + "1px" }}
                  required
                  id="PhoneNumber"
                  fullWidth
                  value={forgetFormik.values.PhoneNumber}
                  onChange={forgetFormik.handleChange}
                  error={forgetFormik.touched.PhoneNumber && Boolean(forgetFormik.errors.PhoneNumber)}
                  //@ts-ignore
                  helperText={(forgetFormik.touched.PhoneNumber && forgetFormik.errors.PhoneNumber)}
                  endAdornment={
                    <InputAdornment position="end">
                      <LocalPhone />
                    </InputAdornment>
                  }
                  label="شماره موبایل"
                  className={classes.root}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button sx={{ color: theme.palette.grey[400] }} variant={"text"} size={"small"} onClick={backToLogin}>
                بازگشت به ورود
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button  sx={{borderRadius:theme.shape.borderRadius + "1px"}} size={"large"} fullWidth color={"primary"} variant={"contained"} type={"submit"}>
                تایید
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : !(!!changePass) ? (
        <form noValidate onSubmit={confirmFormik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <TextField
                name={"code"}
                value={confirmFormik.values.code}
                onChange={confirmFormik.handleChange}
                label={"کد تایید"}
                error={confirmFormik.touched.code && Boolean(confirmFormik.errors.code)}
                required
                fullWidth
              /> */}
                 {/* <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="username" sx={{ color: theme.palette.grey[400] }}>
                کد تایید
                </InputLabel>
                <OutlinedInput
                  sx={{ bgc: "transparent", input: { color: theme.palette.common.white }, borderRadius: theme.shape.borderRadius + "1px" }}
                  required
                  id="Token"
                  fullWidth
                  // value={confirmFormik.values.Token}
                  inputRef={refCounter}
                  // onChange={confirmFormik.handleChange}
                  error={confirmFormik.touched.Token && Boolean(confirmFormik.errors.Token)}
                  //@ts-ignore
                  helperText={(confirmFormik.touched.Token && confirmFormik.errors.Token)}
                  endAdornment={
                    <InputAdornment position="end">
                      <LocalPhone />
                    </InputAdornment>
                  }
                  label="کد تایید"
                  className={classes.root}
                />
              </FormControl> */}

<TextField   sx={{ bgc: "transparent", input: { color: theme.palette.common.white }, borderRadius: theme.shape.borderRadius + "1px" }} id={"Token"} variant="outlined" label={"کد تایید"} fullWidth type="text" inputRef={refCounter}  className={classes.root}/>


            </Grid>
            <Grid item xs={8}>
              <Button sx={{ color: theme.palette.grey[400] }} variant={"text"} size={"small"} onClick={() => setConfirm(false)}>
                بازگشت
              </Button>
            </Grid>
            <Grid item xs={4} style={{textAlign:"left"}}>
               <Countdown date={Date.now() + 119400} key={keyResetCountdown}  renderer={renderCounter}/>
            </Grid>
            <Grid item xs={12}>
              <Button sx={{borderRadius:theme.shape.borderRadius + "1px"}} size={"large"}  fullWidth color={"primary"} variant={"contained"} type={"submit"}>
                تایید
              </Button>
            </Grid>
          </Grid>
        </form>
      ):null}
      {changePass ? (
          <form noValidate onSubmit={changeFormik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name={"NewPassword"}
                  value={changeFormik.values.NewPassword}
                  onChange={changeFormik.handleChange}
                  label={"رمز جدید"}
                  type={"password"}
                  error={changeFormik.touched.NewPassword && Boolean(changeFormik.errors.NewPassword)}
                  //@ts-ignore
                  helperText={(changeFormik.touched.NewPassword && changeFormik.errors.NewPassword)}
                  required
                  fullWidth
                  className={classes.root}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={"ConfirmPassword"}
                  value={changeFormik.values.ConfirmPassword}
                  onChange={changeFormik.handleChange}
                  label={"تکرار رمز جدید"}
                  type={"password"}
                  error={changeFormik.touched.ConfirmPassword && Boolean(changeFormik.errors.ConfirmPassword)}
                   //@ts-ignore
                   helperText={(changeFormik.touched.ConfirmPassword && changeFormik.errors.ConfirmPassword)}
                  required
                  fullWidth
                  className={classes.root}
                />
              </Grid>
              {/* <FormGroup>
                <FormControlLabel control={<Checkbox checked={show} onChange={({ target }) => setShow(target.checked)} />} label={"نمایش رمز"} />
              </FormGroup> */}
              <Grid item xs={12}>
                <Button  sx={{borderRadius:theme.shape.borderRadius + "1px"}} size={"large"} fullWidth color={"primary"} variant={"contained"} type={"submit"}>
                  تغییر رمز عبور
                </Button>
              </Grid>
            </Grid>
          </form>
      ) : null}
    </>
  );
}

export { ForgetForm };
