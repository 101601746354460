import {
   IconButton,
   Menu,
   MenuItem,
   useTheme,
   Box,
   Dialog,
   DialogTitle,
   Zoom,
   Typography,
   DialogContent,
   Grid,
   AppBar,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toggleDark } from "redux/globalSlice";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
   root: {
      "&.user": {
         width: 5,
         color: "#fbf32b",
         height: "5vh",
         "& .username, & .email": {
            transition: theme.transitions.create("opacity", {
               duration: theme.transitions.duration.shortest,
               easing: theme.transitions.easing.easeInOut,
            }),
         },
      },
   },
   avatar: {
      background: theme.palette.background.default,
      transition: theme.transitions.create("all", {
         duration: theme.transitions.duration.shortest,
         easing: theme.transitions.easing.easeInOut,
      }),
      bottom: 10,
      "& > img": {
         borderRadius: "50%",
      },
      width: "100px !important",
      height: "100px !important",
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "-33px",
      [theme.breakpoints.down("md")]: {
         width: "70px !important",
         height: "70px !important",
      },
      [theme.breakpoints.down("sm")]: {
         width: "55px !important",
         height: "55px !important",
      },
   },
   texContainer: {
      paddingTop: 12,
      width: "100%",
      height: "50%",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.background.paper,
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      //  "&:before , &:after":{
      //    content: "",
      //    position: "absolute",
      //    top:" 9px",
      //    left: "-25px",
      //    height: "50px",
      //    width: "50px",
      //    backgroundColor: "#555", /*Needs to match body backgrond-color*/
      //    transform: "rotate(45deg)",
      //    borderRight: "2px solid red",
      //    borderTop: "2px solid red",
      //  }
   },
   halfEllipse1: {
      position: "absolute",
      top: "24%",
      marginTop: 15,
      backgroundColor: theme.palette.background.paper,
      width: "30%",
      height: 163,
      borderRadius: "49% 49% 25% 74% / 50% 55% 35% 40% ",
      [theme.breakpoints.down("md")]: {
         top: "21%",
         height: 133,
         width: "21%",
      },
      [theme.breakpoints.down("sm")]: {
         top: "20%",
         width: "33%",
         height: 153,
         borderRadius: "49% 49% 25% 74% / 50% 55% 35% 40% ",
      },
   },
   avContainer: {
      position: "relative",
      width: "100%",
      height: "50%",
   },
}));

function ProfileHeader() {
   const [open, setOpen] = React.useState<any>(false);

   const dispatch = useDispatch();
   const dark = useSelector((state: any) => state.global.dark);
   const theme = useTheme();
   const classes = useStyles();
   const navigate = useNavigate();

   const user = useSelector((state: any) => state.global.user);

   const Transition = React.forwardRef(function Transition(
      props: TransitionProps & {
         children: React.ReactElement<any, any>;
      },
      ref: React.Ref<unknown>
   ) {
      return <Zoom style={{ transitionDelay: "200ms", transitionDuration: "700ms" }} ref={ref} {...props} />;
   });

   const handleClick = () => {
      navigate("/profile");
      console.log("first");
   };

   let image = 'data:image/jpeg;base64,' + user?.profileImage?.picture;

   return (
      <>
         <Box style={{ backgroundColor: theme.palette.primary.dark , cursor:"pointer" }}>
            <Avatar
               alt="Profile Image"
               src={image}
               sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.text.disabled,
                  width: 39,
                  height: 39,
                  margin: 1.5,
               }}
               onClick={handleClick}
            />
         </Box>

         {open ? (
            <Dialog
               open={true}
               onClose={() => setOpen(false)}
               TransitionComponent={Transition}
               fullWidth
               maxWidth="xs"
               PaperProps={{
                  style: { borderRadius: 5 },
               }}>
               <Grid container direction="column" justifyContent="space-between" alignItems="center" sx={{ height: "35vh" }}>
                  <Grid item className={clsx(classes.texContainer)} sx={{ flexDirection: "column" }}>
                     <Typography className="username text-18 whitespace-nowrap font-semibold mb-4">{`تست کاربر`}</Typography>
                     <Typography className="email text-13 opacity-50 whitespace-nowrap font-medium" color="inherit">
                        {`تست ایمیل کاربر`}
                     </Typography>
                     <Box className={classes.halfEllipse1}></Box>
                  </Grid>
                  <Grid item className={classes.avContainer}>
                     <Avatar
                        sx={{}}
                        className={clsx(classes.avatar)}
                        alt="user photo"
                        //  src={
                        //    user.data.photoURL && user.data.photoURL !== ''
                        //      ? user.data.photoURL
                        //      : 'assets/images/avatars/profile.jpg'
                        //  }
                     />
                  </Grid>
               </Grid>
            </Dialog>
         ) : null}
      </>
   );
}

export { ProfileHeader };
