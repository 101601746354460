import http from "../../utils/http.utils";

type Id = string;
enum Gender {
    Male,
    Female,
}


export function GetAllCertificate() {
    return http
        .get("/api/v2/Certificate")
        .then((res: any) => res.data.data)
        .catch((error: any) => error);
}


export function GetStudentCertificates(){
    return http
        .get("/api/v2/Certificate/GetStudentCertificates")
        .then((res:any) => res.data.data)
        .catch((error:any)=> error)
}

export function GetCertificate(certificateId:Id){
    return http
        .get(`/api/v2/Certificate/${certificateId}`)
        .then((res:any) => res.data.data)
        .catch((error:any)=> error)
}

interface createCetificateProps{
    Name:string[],
    Description:string,
    classId:number,
    Grade:number,
    MaxGrade:number
}

export function CreateCertificate(data:createCetificateProps,studentId:Id){
    return http
        .post(`/api/v2/Certificate/${studentId}`,data)
        .then((res:any) => res.data.data)
        .catch((error:any)=> error)
}

export function GetPDF(certsId:Id){
    return http
    .get(`api/v2/Certificate/GetPDF/${certsId}`)
    .then((res:any) => res)
    .catch((error:any)=> error)
}

interface putCetificateProps{
    Name:object[],
    Description:string,
    classId:number,
    Grade:number,
    MaxGrade:number,
    // Checkout:boolean
}

export function putCertificate(data:putCetificateProps,certificateId:Id){
    return http
        .put(`/api/v2/Certificate/${certificateId}`,data)
        .then((res:any) => res.data.data)
        .catch((error:any)=> error)
}

export function getCertificateInfoByTrackingCode(certificateId:Id){
    return http
        .get(`/api/v2/Certificate/CertificateIssuanceInquiry/${certificateId}`)
        .then((res:any) => res.data)
        .catch((error:any)=> error)
}
