import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// import _ from '@lodash';
import { useEffect, memo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import MenuItem from "@mui/material/MenuItem";
import { PolarArea } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, RadialLinearScale, Tooltip } from "chart.js";

ChartJS.register(RadialLinearScale, ArcElement, Legend, Tooltip);
// ChartJS.overrides.polarArea.plugins.tooltip =

// const tooltipPlugin = ChartJS.registry.getPlugin('tooltip');
// tooltipPlugin.beforeTooltipDraw = function(elements:any, eventPosition:any) {
//   console.log(elements);
//   console.log(eventPosition);
// }
ChartJS.defaults.borderColor = "rgba(153, 151, 151 , .4)";

function Widget4(props: any) {
  const theme = useTheme();

  return (
    <Paper elevation={3} sx={{ borderRadius: "10px" , height:"100%" }}>
      <PolarArea
      // height="790"
        data={{
          labels: ["ممتاز", "درجه1", "درجه2", "مردود"],
          datasets: [
            {
              label: "وضیعت قبولی",
              data: [
                props.widget?.totalExcellentGrades,
                props.widget?.totalGradeOne,
                props.widget?.totalgradeTwo,
                props.widget?.totalRejected,
              ],
              backgroundColor: [
                "rgba(33,150, 243, 0.65)",
                "rgba(76, 175, 80, 0.65)",
                "rgba(255, 152, 0 , 0.65)",
                "rgba(153, 151, 151,0.65)",
              ],
              hoverBackgroundColor: ["rgb(33,150, 243)", "rgb(76, 175, 80)", "rgb(255, 152, 0)", "rgb(153, 151, 151)"],
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  return `تعداد ${context.label} : ${context.raw}`;
                },
              },
            },
            legend: {
              labels: {
                font: {
                  family: theme.typography.fontFamily,
                  size: 14,
                  weight: "bold",
                },
              },
            },
          },
          layout: {
            padding: 50,
          },
          scales: {
            r: {
              angleLines: {
                color: "red",
              },
            },
          },
        }}></PolarArea>
    </Paper>
  );
}

export default memo(Widget4);

{
  /*
 <div className="flex items-center justify-between p-5">
        <Typography className="text-16 font-medium">{widget.title}</Typography>

        <Select
          value={currentRange}
          onChange={handleChangeRange}
          inputProps={{
            name: 'currentRange',
          }}
          classes={{ select: 'py-8' }}
          variant="filled"
        >
          {Object.entries(widget.ranges).map(([key, n]:any) => {
            return (
              <MenuItem key={key} value={key}>
                {n}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div>
        <ReactApexChart
          options={widget.mainChart.options}
          series={widget.mainChart.series[currentRange]}
          type={widget.mainChart.options.chart.type}
          height="400px"
        />
      </div>
      <div className="flex items-center p-8 border-t-1">
        <div className="flex flex-1 flex-col items-center justify-center p-16 border-r-1">
          <Typography className="text-32 font-medium mb-8 leading-none tracking-tighter">
            {widget.footerLeft.count[currentRange]}
          </Typography>
          <Typography className="text-13 font-normal" color="textSecondary">
            {widget.footerLeft.title}
          </Typography>
        </div>
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <Typography className="text-32 font-medium mb-8 leading-none tracking-tighter">
            {widget.footerRight.count[currentRange]}
          </Typography>
          <Typography className="text-13 font-normal" color="textSecondary">
            {widget.footerRight.title}
          </Typography>
        </div>
      </div>
        
    
      plugins:{
          tooltip: {
            enabled: false,
            external: context => {
              const tooltipModel = context.tooltip
              console.log(tooltipModel);
              return `${tooltipModel.dataPoints[0].label}تعداد`
            }},
    
    */
}
