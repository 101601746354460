import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TableCell,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { useParams } from "react-router-dom";
import { ColumnPro } from "../../../../components/table-pro/table-pro.component";
import { sortDates, sortNumbers, sortStrings } from "../../../utils/sort.utils";
import moment from "jalali-moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, Settings } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import { deleteCourse, getCourses, putCourse } from "api/course/courseApi";


const columns: any = [
  {
    id: "counter",
    label: "#",
    align: "center",
    minWidth: 15,
    state: 0,
    numeric: true,
    show: true,
  },
  {
    id: "name",
    label: "نام دوره",
    align: "left",
    minWidth: 60,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "duration",
    label: "مدت دوره(ساعت)",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: true,
    show: true,
    sort: sortNumbers,
  },
  {
    id: "amount",
    label: "قیمت دوره",
    align: "center",
    minWidth: 20,
    state: 0,
    numeric: true,
    show: true,
    sort: sortNumbers,
  },
  {
    id: "numberOfSessions",
    label: "تعداد جلسات",
    align: "center",
    minWidth: 20,
    state: 0,
    numeric: true,
    show: true,
    sort: sortNumbers,
  },
  // {
  //   id: "hasExam",
  //   label: "امتحان",
  //   align: "left",
  //   minWidth: 60,
  //   state: 0,
  //   numeric: false,
  //   show: true,
  //   sort: sortStrings,
  // },
  {
    id: "description",
    label: "توضیحات دوره",
    align: "left",
    minWidth: 60,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  // {
  //   id: "createdAt",
  //   label: "تاریخ ثبت",
  //   align: "center",
  //   minWidth: 30,
  //   state: 0,
  //   numeric: true,
  //   show: true,
  //   sort: sortDates,
  //   format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  // },
  {
    id: "action",
    label: "عملیات",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: true,
    show: true,
    // sort: sortDates,
  },
];

interface CellProps extends TableCellProps {
  active: boolean;
}

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
  backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function CourseList({ startDate, endDate }: any) {
  const [rows, setRows] = useState<any>([]);
  const [edit, setEdit] = useState<any>(null);
  const [remove, setRemove] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [acvTypes, setActvTypes] = useState<any>([]);
  const [contructors, setContructors] = useState<any>([]);
  const [selectContructors, setSelectContructors] = useState<any>([]);
  const [selectAcvTypes, setSelectAcvTypes] = useState<any>([]);

  useEffect(() => {
    getAllNeedData();
  }, [startDate, endDate]);

  const getAllNeedData = async () => {
    const res: any = await getCourses();
    if (!(res instanceof Error)) {
      setRows(res);
    } else {
      toast.error("دریافت داده‌ها با مشکل مواجه شد");
    }
  };

  const validation = Yup.object({
    name: Yup.string().required("نباید خالی باشد"),
    duration: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value:any) {
        return +value >= 0;
      }),
    numberOfSessions: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value:any) {
        return +value >= 0;
      }),
    amount: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value:any) {
        return +value >= 0;
      }),
      maxScore: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value:any) {
        return +value >= 0;
      }),
    description: Yup.string(),
    hasExam:Yup.boolean()
  });
  const editFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
      duration: null,
      numberOfSessions: null,
      amount: null,
      hasExam: true,
      maxScore:null
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      console.log(edit)
      const res: any = await putCourse(values , edit.id);
      if (!(res instanceof Error)) {
        console.log(res)
        getAllNeedData();
        setEdit(false);
        toast.success("ویرایش دوره با موفقیت انجام شد");
      } else {
        toast.error("ثبت اطلاعات با خطا مواجه شد");
      }
    },
  });

  useEffect(() => {
    if (edit) {
      editFormik.setValues({
        name: edit.name,
        description: edit.description,
        duration: edit.duration,
        numberOfSessions: edit.numberOfSessions,
        amount: edit.amount,
        hasExam: edit.hasExam,
        maxScore: edit.maxScore
      });
    }
  }, [edit]);

  useEffect(() => {
    getAllNeedData();
  }, []);

  const handleRemoveCourse = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await deleteCourse(remove);
    if (!(res instanceof Error)) {
       const tmpRows = [...rows];
       const index = tmpRows.findIndex((row) => row.id === remove);
       await tmpRows.splice(index, 1);
       setRows(tmpRows);
       setRemove(null);
       toast.success("دوره با موفقیت حذف شد");
    } else {
       toast.error("حذف کاربر با خطا مواجه شد");
    }
 };

  const itemsOfAction = [
    {
      title: (
        <>
          <Edit />
          &nbsp; ویرایش
        </>
      ),
      onClick: async (course: any) =>
        await new Promise((resolve, reject) => {
          setEdit(course);
        }),
    },
    {

      title: (
        <>
         <Delete />
         &nbsp; حذف
      </>
   ),
   onClick: (course: any) => setRemove(course.id),
  }
  ];

  // console.log(rows?.length);

  return (
    <>
      <Grid container spacing={2} sx={{mt:2}}>
        {/* <ListInfo /> */}
        <Grid item xs={12} style={{ width: 10 }}>
          <Paper>
            <TableProIpa
              columns={columns}
              bodyFunc={(column: any, row: any, index: any) => {
                const head = column.id;
                const value = row[column.id];
                if (head === "action") {
                  return (
                    <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                      <MenuActionTable menuId={row.id} items={itemsOfAction} icon={<Settings />} user={row} />
                    </TableCellStyled>
                  );
                }else if(head === "hasExam"){
                  return (
                    <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                      <Button variant={'text'} color={value ? 'success' : "error"} style={{
                        width: column.minWidth
                      }}>
                        {value ? 'دارد' : 'ندارد'}
                      </Button>
                    </TableCellStyled>
                )
                }
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.format && value ? column.format(value) : value}
                  </TableCell>
                );
              }}
              rows={rows ?? []}
              // rows={rows?.length ? rows : []}
            />
          </Paper>
        </Grid>
      </Grid>
      {edit ? (
        <ModalIpa
          open={Boolean(edit)}
          title={`ویرایش دوره ${edit.name}`}
          onClose={() => {
            setEdit(null);
            editFormik.handleReset(1);
          }}>
          <form noValidate onSubmit={editFormik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id={"name"}
                  variant="outlined"
                  label={"نام دوره"}
                  fullWidth
                  value={editFormik.values.name}
                  onChange={editFormik.handleChange}
                  error={editFormik.touched.name && Boolean(editFormik.errors.name)}
                  helperText={editFormik.touched.name && editFormik.errors.name}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id={"duration"}
                  type="number"
                  variant="outlined"
                  label={"مدت دوره(ساعت)"}
                  fullWidth
                  value={editFormik.values.duration}
                  onChange={editFormik.handleChange}
                  error={editFormik.touched.duration && Boolean(editFormik.errors.duration)}
                  //@ts-ignore
                  helperText={editFormik.touched.duration && editFormik.errors.duration}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id={"numberOfSessions"}
                  type="number"
                  variant="outlined"
                  label={"تعداد جلسات"}
                  fullWidth
                  value={editFormik.values.numberOfSessions}
                  onChange={editFormik.handleChange}
                  error={editFormik.touched.numberOfSessions && Boolean(editFormik.errors.numberOfSessions)}
                  //@ts-ignore
                  helperText={editFormik.touched.numberOfSessions && editFormik.errors.numberOfSessions}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id={"amount"}
                  type="number"
                  variant="outlined"
                  label={"قیمت"}
                  fullWidth
                  value={editFormik.values.amount}
                  onChange={editFormik.handleChange}
                  error={editFormik.touched.amount && Boolean(editFormik.errors.amount)}
                  //@ts-ignore
                  helperText={editFormik.touched.amount && editFormik.errors.amount}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="description"
                  label={"توضیحات"}
                  maxRows={4}
                  fullWidth
                  value={editFormik.values.description}
                  onChange={editFormik.handleChange}
                  error={editFormik.touched.description && Boolean(editFormik.errors.description)}
                  helperText={editFormik.touched.description && editFormik.errors.description}
                  placeholder={"توضیحات..."}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id={"maxScore"}
                  type="number"
                  variant="outlined"
                  label={"قیمت"}
                  fullWidth
                  value={editFormik.values.maxScore}
                  onChange={editFormik.handleChange}
                  error={editFormik.touched.maxScore && Boolean(editFormik.errors.maxScore)}
                  //@ts-ignore
                  helperText={editFormik.touched.maxScore && editFormik.errors.maxScore}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">امتحان</FormLabel>
                  <RadioGroup
                    aria-label="hasExam"
                    name={"hasExam"}
                    id="hasExam"
                    style={{ flexDirection: "row" }}
                    value={editFormik.values.hasExam}
                    onChange={(event: any) => editFormik.setFieldValue("hasExam", event.target.value === "true" ? true : false)}
                    // @ts-ignore
                    error={editFormik.touched.hasExam && Boolean(editFormik.errors.hasExam)}>
                    <FormControlLabel value={true} control={<Radio />} label="دارد" />
                    <FormControlLabel value={false} control={<Radio />} label="ندارد" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ButtonsModal
                  textSubmit={"ذخیره"}
                  textClose={"انصراف"}
                  onClose={() => {
                    setEdit(null);
                    editFormik.handleReset(1);
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </ModalIpa>
      ) : null}
       {remove ? (
            <ModalIpa open={Boolean(remove)} title={`آیا از حذف دوره مطمئن هستید؟`} onClose={() => setRemove(null)}>
               <form onSubmit={handleRemoveCourse}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <ButtonsModal
                           textSubmit={"تایید"}
                           textClose={"انصراف"}
                           onClose={() => {
                              setRemove(false);
                           }}
                        />
                     </Grid>
                  </Grid>
               </form>
            </ModalIpa>
         ) : null}
    </>
  );
}

export { CourseList };
