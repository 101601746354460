import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import moment from "jalali-moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Zoom from "@mui/material/Zoom";
import { TransitionProps } from "@mui/material/transitions";
import { connect  , useSelector} from "react-redux";
import { BASE_URL } from "configs/configs";
import { toggleConfirmDetailsTable } from "redux/globalSlice";
import { useDispatch } from "react-redux";

const TypographyStyle = styled(Typography)(() => ({
   display: "inline",
   fontWeight: "bold",
}));

const TypoTitle = styled(Typography)(() => ({
   fontStyle: "italic",
   display: "inline",
}));

interface dialogProp {
   title?: any;
   open?: any;
   setOpen?: any;
   rowValue?: any;
   columnValue?: any;
   toggleDetails: any;
   currentTab: number;
}

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & {
      children: React.ReactElement<any, any>;
   },
   ref: React.Ref<unknown>
) {
   return (
      <Zoom
         style={{ transitionDelay: "200ms", transitionDuration: "700ms" }}
         ref={ref}
         {...props}
      />
   );
});

const sampleObj = {
   cost: "هزینه",
   description: "توضیحات",
   reportDate: "تاریخ گزارش",
   location: "محل",
   id: "",
   lastUserLevel: "",
   projectId: "",
   projectName: "",
   verify: "",
   enterTime: "ساعت ورود",
   exitTime: "ساعت خروج",
   wage: "دستمزد",
   activityType: "نوع فعالیت",
   insertTime: "ساعت ورودی",
   activeDate: "تاریخ فعال سازی",
   deletedTime: "زمان حذف کردن",
   isActive: "فعال بودن",
   isDeleted: "حذف شده",
   name: "نام",
   project: "پروژه",
   updateTime: "تاریخ بروزرسانی",
   text: "متن",
   userId:"",
   enterExit:"ورود/خروج",
   date:"تاریخ",
   previewPath:"",
   pictureName:"نام تصویر",
   caption:"توضیحات تصویر",
   picturePath:"",
   examsDateTime:"تاریخ امتحان",
   // examsDateTime:"ساعت امتحان",

};

function DetailsDialog(props: dialogProp) {
   const {
      title,
      open,
      setOpen,
      rowValue: value,
      columnValue: column,
      toggleDetails,
      currentTab,
   } = props;

   const detailsTable = useSelector((state:any) => state.global.detailsTable)

   const dispatch = useDispatch();

   const compareKeys = (a: any, b: any) => {
      var aKeys = Object.keys(a).sort();
      var bKeys = Object.keys(b).sort();
      bKeys.map((it: any) => {
         aKeys.map((cle: any) => {
            if (it === cle) {
               if (
                  it === "id" ||
                  it === "lastUserLevel" ||
                  it === "projectId" ||
                  it === "pictureUrl" ||
                  it === "relatedId"||
                  it === "userId"||
                  it === "previewPath" ||
                  it === "picturePath"
               ) {
                  delete b[it];
               } else {
                  Object.defineProperty(
                     b,
                     a[cle],
                     Object.getOwnPropertyDescriptor(b, it)
                  );
                  delete b[it];
               }
            }
         });
      });
      return b;
   };

   let selectedRow = { ...value };
   console.log(selectedRow);
   let selectedColumn = [...column];
   let selectedTargetRow = { ...selectedRow };
   const targetRow = compareKeys(sampleObj, selectedTargetRow);
   const targetValue = Object.entries(targetRow);

   if (selectedRow?.startDate) {
      selectedRow.startDate = moment(selectedRow?.startDate)
         .locale("fa")
         .format("YYYY/MM/DD");
   }

   if (selectedRow?.endDate) {
      selectedRow.endDate = moment(selectedRow?.endDate)
         .locale("fa")
         .format("YYYY/MM/DD");
   }

   if (selectedRow?.startTime) {
      selectedRow.startTime = moment(selectedRow?.startTime)
         .locale("fa")
         .format("HH:mm");
   }

   if (selectedRow?.endTime) {
      selectedRow.endTime = moment(selectedRow?.endTime)
         .locale("fa")
         .format("HH:mm");
   }

   if (selectedRow?.examsDateTime) {
      selectedRow.examsDateTime = new Date(selectedRow.examsDateTime).toLocaleDateString("fa-IR")
   }

   if (selectedRow?.isActive) {
      if(selectedRow?.isActive === true){
         selectedRow.isActive = "هست"
      }else if(selectedRow?.isActive === false){
         selectedRow.isActive = "نیست"
      }
   }

   if (selectedRow?.roles) {
      let tempRoles:string[] = [];
      selectedRow?.roles?.map((role:any) =>{
         if(role === "Admin"){
            tempRoles.push(`ادمین/`)
         }
         if(role === "Owner"){
            tempRoles.push(`مالک/`)
         }
         if(role === "Expert"){
            tempRoles.push(`کارشناس/`)
         }
         if(role === "Teacher"){
            tempRoles.push(`استاد/`)
         }
         if(role === "Student"){
            tempRoles.push(`دانشجو/`)
      }
      })
     selectedRow.roles = tempRoles;
   }

   if (selectedRow?.days) { 
      selectedRow.days = `${selectedRow.days} `
   }

   if (selectedRow?.gender) { 
     if(selectedRow.gender === "Male"){
      selectedRow.gender = "مرد"
     }
     if(selectedRow.gender === "Female"){
      selectedRow.gender = "زن"
     }
   }

   selectedColumn = selectedColumn.filter((cl: any) => cl.id !== "action");

   for (const item in selectedRow) {
      if (selectedRow[item] === "") {
         selectedRow[item] = "-";
      }
   }

   for (const item in targetValue) {
      if (targetValue[item][0] === "تاریخ گزارش") {
         if (targetValue[item][1]) {
            const temp = moment(targetValue[item][1])
               .locale("fa")
               .format("YYYY/MM/DD");
            targetValue[item][1] = temp;
         }
      }
      if (targetValue[item][0] === "ساعت ورود") {
         if (targetValue[item][1]) {
            const temp1 = moment(targetValue[item][1])
               .locale("fa")
               .format("HH:mm");
            targetValue[item][1] = temp1;
         }
      }
      if (targetValue[item][0] === "ساعت خروج") {
         if (targetValue[item][1]) {
            const temp2 = moment(targetValue[item][1])
               .locale("fa")
               .format("HH:mm");
            targetValue[item][1] = temp2;
         }
      }
      if (targetValue[item][0] === "ساعت ورودی") {
         if (targetValue[item][1]) {
            const temp2 = moment(targetValue[item][1])
               .locale("fa")
               .format("HH:mm");
            targetValue[item][1] = temp2;
         }
      }
      if (targetValue[item][0] === "روزهای تشکیل کلاس") {
         console.log(targetValue[item][1])
            targetValue[item][1] = `${targetValue[item][1]} `;
       
      }
      if (targetValue[item][0] === "ورود/خروج") {
         if (targetValue[item][1] === false) {
            targetValue[item][1] = "خیر";
         } else {
            targetValue[item][1] = "بله";
         }
      }
      if (targetValue[item][0] === "حذف شده") {
         if (targetValue[item][1] === false) {
            targetValue[item][1] = "خیر";
         } else {
            targetValue[item][1] = "بله";
         }
      }
      if (targetValue[item][0] === "تاریخ بروزرسانی") {
         if (targetValue[item][1]) {
            const temp2 = moment(targetValue[item][1])
               .locale("fa")
               .format("YYYY/MM/DD");
            targetValue[item][1] = temp2;
         }
      }
      if (targetValue[item][0] === "تاریخ فعال سازی") {
         if (targetValue[item][1]) {
            const temp2 = moment(targetValue[item][1])
               .locale("fa")
               .format("YYYY/MM/DD");
            targetValue[item][1] = temp2;
         }
      }
      if (targetValue[item][0] === "زمان حذف کردن") {
         if (targetValue[item][1]) {
            const temp2 = moment(targetValue[item][1])
               .locale("fa")
               .format("YYYY/MM/DD");
            targetValue[item][1] = temp2;
         }
      }
      if (targetValue[item][0] === "تاریخ پذیرش") {
         if (targetValue[item][1]) {
            const temp2 = moment(targetValue[item][1])
               .locale("fa")
               .format("YYYY/MM/DD");
            targetValue[item][1] = temp2;
         }
      }
      if (targetValue[item][0] === "تاریخ") {
         if (targetValue[item][1]) {
            const temp2 = moment(targetValue[item][1])
               .locale("fa")
               .format("YYYY/MM/DD");
            targetValue[item][1] = temp2;
         }
      }
      if (targetValue[item][0] === "پیش نمایش تصویر") {
         if (targetValue[item][1]) {
            const temp2:any = targetValue[item][1];
            targetValue[item][1] = temp2?.replace('~', BASE_URL);
         }
      }
   }

   return (
      <Dialog
         // open={open && detailsTable}
         open={false}
         onClose={() => dispatch(toggleConfirmDetailsTable(false))}
         aria-labelledby="confirm-dialog"
         fullWidth
         TransitionComponent={Transition}>
         <DialogTitle style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ m: 0, p: 0, fontWeight: 600 }} variant="h6">
               جزئیات گزارش
            </Typography>
            <IconButton
               aria-label="close"
               onClick={() => dispatch(toggleConfirmDetailsTable(false))}
               sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  borderRadius: 4,
               }}>
               <CloseIcon />
            </IconButton>
         </DialogTitle>
         <DialogContent dividers>
            <Grid container spacing={2}>
          
                 {true
                  ? selectedColumn
                       ?.filter((item: any) => item.label !== "#")
                       ?.map((cl: any) => {
                          return (
                             <Grid item xs={12} sm={6} sx={{ px: 2 }}>
                                <TypoTitle>{cl.label} : </TypoTitle>
                                <TypographyStyle>
                                   {selectedRow?.[cl.id]}
                                </TypographyStyle>
                             </Grid>
                          );
                       })
                  : null
                  // targetValue.map((ent: any) => {
                  //      return (
                  //         <Grid item xs={12} sm={6} sx={{ px: 2 }}>
                  //            <TypoTitle>{`${ent[0]}: `}</TypoTitle>
                  //            <TypographyStyle>{ent[1]}</TypographyStyle>
                  //         </Grid>
                  //      );
                  //   })
                    } 
            </Grid>
         </DialogContent>
      </Dialog>
   );
}

const mapStateToProps = (state: any) => {
   return {
      toggleDetails: state.toggleDetails,
      currentTab: state.currentTab,
   };
};

const reduxHead = connect(mapStateToProps)(DetailsDialog);

export { reduxHead as DetailsDialog };
