import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import moment from "jalali-moment";
import globalSlice from "redux/globalSlice";


const infoAdapter = createEntityAdapter();

const initialState = infoAdapter.getInitialState({
    startDate:moment().format("YYYY-MM-DD"),
    endDate:moment().format("YYYY-MM-DD")
});


const infoSlice = createSlice({
    name:"classes",
    initialState,
    reducers:{
        setStartDate:{
            reducer(state , action){
                state.startDate = action.payload
            }
        },
        setEndDate:{
            reducer(state,action){
                state.endDate = action.payload
            }
        }
    }
});


export const {
    setStartDate,
    setEndDate,
} = infoSlice.actions

export default infoSlice.reducer