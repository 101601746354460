import React, { useEffect, useState, useRef } from "react";
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    TableCell,
    TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { useParams } from "react-router-dom";
import { ColumnPro } from "../../../../components/table-pro/table-pro.component";
import { sortDates, sortNumbers, sortStrings } from "../../../utils/sort.utils";
import moment from "jalali-moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, Settings } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { accessLevelName } from "utils/accessLevel.utils";
import {CgInsertAfterR} from "react-icons/cg"
import { getClassStudents } from "api/student/studentApi";
import { getStudentScore, insertStudentScore } from "api/score/scoreApi";
import { deleteUser } from "api/user/userApi";


const columns: any = [
    {
        id: "counter",
        label: "#",
        align: "center",
        minWidth: 15,
        state: 0,
        numeric: true,
        show: true,
    },
    {
        id: "fullName",
        label: "نام کامل",
        align: "left",
        minWidth: 60,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "fatherName",
        label: "نام پدر",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },

    {
        id: "age",
        label: "سن",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: true,
        show: true,
        sort: sortNumbers,
    },
    {
        id: "userName",
        label: "نام کاربری",
        align: "left",
        minWidth: 60,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "gender",
        label: "جنسیت",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "roles",
        label: "نقش‌ها",
        align: "center",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "isActive",
        label: "فعال",
        align: "center",
        minWidth: 5,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "nationalCode",
        label: "کد ملی",
        align: "center",
        minWidth: 60,
        state: 0,
        numeric: false,
        show: true,
        // sort: sortStrings,
    },
    {
        id: "nationality",
        label: "ملیت",
        align: "left",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },

    // {
    //   id: "numberOfSessions",
    //   label: "تعداد جلسات",
    //   align: "center",
    //   minWidth: 20,
    //   state: 0,
    //   numeric: true,
    //   show: true,
    //   sort: sortNumbers,
    // },
    // {
    //   id: "hasExam",
    //   label: "امتحان",
    //   align: "left",
    //   minWidth: 60,
    //   state: 0,
    //   numeric: false,
    //   show: true,
    //   sort: sortStrings,
    // },
    // {
    //   id: "description",
    //   label: "توضیحات دوره",
    //   align: "left",
    //   minWidth: 60,
    //   state: 0,
    //   numeric: false,
    //   show: true,
    //   sort: sortStrings,
    // },
    // {
    //   id: "createdAt",
    //   label: "تاریخ ثبت",
    //   align: "center",
    //   minWidth: 30,
    //   state: 0,
    //   numeric: true,
    //   show: true,
    //   sort: sortDates,
    //   format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
    // },
    {
        id: "action",
        label: "عملیات",
        align: "center",
        minWidth: 10,
        state: 0,
        numeric: true,
        show: true,
        // sort: sortDates,
    },
];

interface CellProps extends TableCellProps {
    active: boolean;
}

const TableCellStyled = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
    backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function AddScore({ startDate, endDate }: any) {
    const [rows, setRows] = useState<any>([]);
    const [edit, setEdit] = useState(null);
    const [remove, setRemove] = useState<any>(null);
    const [add, setAdd] = useState<any>(null);
    const [filteredRows, setFilteredRows] = useState<any>([]);
    const [acvTypes, setActvTypes] = useState<any>([]);
    const [contructors, setContructors] = useState<any>([]);
    const [selectContructors, setSelectContructors] = useState<any>([]);
    const [selectAcvTypes, setSelectAcvTypes] = useState<any>([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [rolesItem, setRolesItems] = useState([]);
    const [toggleScoreButton , setToggleScoreButton] = useState([false,false]);
    const [initialEditScore, setInitialEditScore] = useState(null);

    const refImage = useRef<HTMLInputElement>(null);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    useEffect(() => {
        getAllNeedData();
    }, [startDate, endDate]);

    const classId = useParams().classId;

    const getAllNeedData = async () => {
        const res: any = await getClassStudents(classId);
        if (!(res instanceof Error)) {
            setRows(res);
        } else {
            toast.error("دریافت داده‌ها با مشکل مواجه شد");
        }
    };

    const validation = Yup.object({
        grade: Yup.number().nullable().required("نباید خالی باشد"),
    });
    const addFormik = useFormik({
        initialValues: {
            grade: null,
            description: "",
        },
        validationSchema: validation,
        onSubmit: async (values, { resetForm }) => {
            console.log({...values, classId:classId}, add.id)
            const res: any = await insertStudentScore({...values, classId:Number(classId)}, add.id);
            if (!(res instanceof Error)) {
               console.log(res);
               getAllNeedData();
               setAdd(false);
               toast.success("درج نمره دانشجو با موفقیت انجام شد");
            } else {
               toast.error("ثبت اطلاعات با خطا مواجه شد");
            }
        },
    });

    const editFormik = useFormik({
        initialValues: {
            grade: null,
            description: "",
        },
        validationSchema: validation,
        onSubmit: async (values, { resetForm }) => {
            const res: any = await insertStudentScore({...values,classId:Number(classId)}, edit.id);
            if (!(res instanceof Error)) {
               console.log(res);
               getAllNeedData();
               setEdit(false);
               toast.success("ویرایش کاربر با موفقیت انجام شد");
            } else {
               toast.error("ثبت اطلاعات با خطا مواجه شد");
            }
        },
    });

    useEffect(() => {
        if (edit && initialEditScore) {
            console.log(initialEditScore)
            // const tempRoles: object[] = [];
            // rolesItem.map((role: any) => {
            //     edit.roles.map((item: any) => {
            //         if (item === role.name) {
            //             tempRoles.push(role);
            //         }
            //     });
            // });
            // setFilteredRoles(tempRoles);

            editFormik.setValues({
                grade: edit.grade,
                description: edit.description,
            });
        }
    }, [edit]);

    useEffect(() => {
        getAllNeedData();
    }, []);

    useEffect(() => {
        editFormik.setFieldValue("roles", filteredRoles);
    }, [filteredRoles]);

    const handleRemoveCourse = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const res = await deleteUser(remove);
        if (!(res instanceof Error)) {
            const tmpRows = [...rows];
            const index = tmpRows.findIndex((row) => row.id === remove);
            await tmpRows.splice(index, 1);
            setRows(tmpRows);
            setRemove(null);
            toast.success("کاربر با موفقیت حذف شد");
        } else {
            toast.error("حذف کاربر با خطا مواجه شد");
        }
    };

    const itemsOfAction = [
        {
            title: (
                <Button color="inherit" disabled={toggleScoreButton[0]}>
                    <CgInsertAfterR style={{fontSize:"1.5rem"}}/>
                    &nbsp; ثبت نمره این دانشجو
                </Button>
            ),
            onClick: async (user: any) =>
                await new Promise(async(resolve, reject) => {
                    if(! toggleScoreButton[0])
                    setAdd(user)
                    
                }),
            
        },
        {
            title: (
                <Button color="inherit" disabled={toggleScoreButton[1]}>
                    <Edit style={{fontSize:"1.5rem"}}/>
                    &nbsp; ویرایش نمره این دانشجو
                </Button>
            ),
            onClick: async (user: any) =>
                await new Promise((resolve, reject) => {
                    if(! toggleScoreButton[1])
                    setEdit(user);
                }),
        },
        // {
        //     title: (
        //         <>
        //             <Delete />
        //             &nbsp; حذف
        //         </>
        //     ),
        //     onClick: (user: any) => setRemove(user.id),
        // },
    ];

    const handleToggleButton = async(row:any) => {
        const res = await getStudentScore(row.id,{classId})
        if(!(res instanceof Error)){
            res >= 0 ? setToggleScoreButton([true,false]) : setToggleScoreButton([false,true])
            setInitialEditScore(res)
        }else{
            setToggleScoreButton([true,true]);
            toast.error("دریافت داده با مشکل مواجه شد");
        }
    }

    return (
        <>
            <Grid container spacing={2} sx={{mt:2}}>
                {/* <ListInfo /> */}
                <Grid item xs={12} style={{ width: 10 }}>
                    <Paper>
                        <TableProIpa
                            columns={columns}
                            bodyFunc={(column: any, row: any, index: any) => {
                                const head = column.id;
                                const value = row[column.id];
                                if (head === "action") {
                                    return (
                                        <TableCellStyled active={true} key={column.id + row.id} align={column.align} onClick={() => handleToggleButton(row)}>
                                            <MenuActionTable menuId={row.id} items={itemsOfAction} icon={<Settings />} user={row} />
                                        </TableCellStyled>
                                    );
                                } else if (head === "isActive") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={value ? "success" : "error"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value ? "هست" : "نیست"}
                                            </Button>
                                        </TableCell>
                                    );
                                } else if (head === "gender") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={"inherit"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value === "Male" ? "مرد" : "زن"}
                                            </Button>
                                        </TableCell>
                                    );
                                } else if (head === "roles") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={"inherit"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value?.map((item: any) => {
                                                    switch (item) {
                                                        case "Owner":
                                                            return "مالک \n";
                                                        case "Student":
                                                            return "دانشجو \n";
                                                        case "Expert":
                                                            return "کارشناس \n";
                                                        case "Teacher":
                                                            return "معلم \n";
                                                        case "Admin":
                                                            return "ادمین \n";
                                                    }
                                                })}
                                            </Button>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.format && value ? column.format(value) : value}
                                    </TableCell>
                                );
                            }}
                            rows={rows ?? []}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {add ? (
                <ModalIpa
                    open={Boolean(add)}
                    title={`ثبت نمره  ${add.fullName}`}
                    onClose={() => {
                        setAdd(null);
                        addFormik.handleReset(1);
                        setFilteredRoles([]);
                    }}>
                    <form noValidate onSubmit={addFormik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id={"grade"}
                                    variant="outlined"
                                    label={"نمره"}
                                    fullWidth
                                    type="number"
                                    value={addFormik.values.grade}
                                    onChange={addFormik.handleChange}
                                    error={addFormik.touched.grade && Boolean(addFormik.errors.grade)}
                                    //@ts-ignore
                                    helperText={addFormik.touched.grade && addFormik.errors.grade}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id={"description"}
                                    variant="outlined"
                                    label={"توضیحات"}
                                    fullWidth
                                    type="text"
                                    rows={3}
                                    maxRows={5}
                                    multiline
                                    value={addFormik.values.description}
                                    onChange={addFormik.handleChange}
                                    error={addFormik.touched.description && Boolean(addFormik.errors.description)}
                                    //@ts-ignore
                                    helperText={addFormik.touched.description && addFormik.errors.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    ثبت نمره
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
            {edit ? (
                <ModalIpa
                    open={Boolean(edit)}
                    title={`ویرایش نمره  ${edit?.fullName}`}
                    onClose={() => {
                        setEdit(null);
                        editFormik.handleReset(1);
                        setFilteredRoles([]);
                    }}>
                    <form noValidate onSubmit={editFormik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id={"grade"}
                                    variant="outlined"
                                    label={"نمره"}
                                    fullWidth
                                    type="number"
                                    value={editFormik.values.grade}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.grade && Boolean(editFormik.errors.grade)}
                                    //@ts-ignore
                                    helperText={editFormik.touched.grade && editFormik.errors.grade}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id={"description "}
                                    variant="outlined"
                                    label={"توضیحات"}
                                    fullWidth
                                    type="text"
                                    rows={3}
                                    maxRows={5}
                                    multiline
                                    value={editFormik.values.description}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.description && Boolean(editFormik.errors.description)}
                                    //@ts-ignore
                                    helperText={editFormik.touched.description && editFormik.errors.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    ثبت نمره
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
            {remove ? (
                <ModalIpa open={Boolean(remove)} title={`آیا از حذف کاربر مطمئن هستید؟`} onClose={() => setRemove(null)}>
                    <form onSubmit={handleRemoveCourse}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ButtonsModal
                                    textSubmit={"تایید"}
                                    textClose={"انصراف"}
                                    onClose={() => {
                                        setRemove(false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
        </>
    );
}

export { AddScore };
