import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Paper, Radio, RadioGroup, TextField, Autocomplete, Typography, alpha, Checkbox, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { accessLevelName } from "utils/accessLevel.utils";
import AdapterJalali from "@date-io/date-fns-jalali";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "jalali-moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PhotoCamera } from "@mui/icons-material";
import { addUser, getRoles, uploadUserImage } from "api/user/userApi";
import UploadIcon from "@mui/icons-material/Upload";
import { ImageModal } from "components/image-modal/imageModal.component";

const PageTitleStyle:any = styled(Typography)(({ theme }: any) => ({
    // marginTop: "-1.55%",
    // backgroundColor: alpha(theme.palette.primary.main, .9),
    // paddingTop: "1%",
    // paddingBottom: "1%",
    // width: "107%",
    // marginLeft: "-3.5% !important",
    // borderRadius: "5px",

    color: theme.palette.primary.main,
    fontWeight: "600",
}));

const Input = styled("input")({
    display: "none",
});

function AddUser() {
    const [roles, setRoles] = useState([]);
    const [showImage, setShowImage] = useState(false);
    const [limitedImageUpload, setlimitedImageUpload] = useState(false);
    const [tempPictureData, setTempPictureData] = useState(null);

    const refImage = useRef<HTMLInputElement>(null);
    const refImageDesc = useRef<HTMLInputElement>(null);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const getAllNeedData = async () => {
        const res: any = await getRoles();
        // const resDepartments = await getDependents()
        if (!(res instanceof Error)) {
            setRoles(res);
            // setDepartments(resDepartments)
        } else {
            toast.error("دریافت داده با خطا مواجه شد");
        }
    };

    useEffect(() => {
        getAllNeedData();
    }, []);

    const validation = Yup.object({
        firstName: Yup.string()
            .required("نباید خالی باشد")
            .test(
                "نام را به درستی وارد کنید",
                "نام را به درستی وارد کنید",
                //@ts-ignore
                function (name = "") {
                    const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
                    return re.test(String(name).toLowerCase());
                }
            ),
        lastName: Yup.string()
            .required("نباید خالی باشد")
            .test(
                "نام خانوادگی را به درستی وارد کنید",
                "نام خانوادگی را به درستی وارد کنید",
                //@ts-ignore
                function (name = "") {
                    const re = /^(?=.{1,30}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
                    return re.test(String(name).toLowerCase());
                }
            ),
        fatherName: Yup.string()
            .required("نباید خالی باشد")
            .test(
                "نام پدر را به درستی وارد کنید",
                "نام پدر را به درستی وارد کنید",
                //@ts-ignore
                function (name = "") {
                    const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
                    return re.test(String(name).toLowerCase());
                }
            ),
        // userName: Yup.string()
        //     .required("نباید خالی باشد")
        //     .test(
        //         "نام کاربری را به درستی وارد کنید",
        //         "نام کاربری را به درستی وارد کنید",
        //         //@ts-ignore
        //         function (userName = "") {
        //             const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9.@_]+(?<![_.])$/;
        //             return re.test(String(userName).toLowerCase());
        //         }
        //     ),
        gender: Yup.string().required("نباید خالی باشد"),
        accessLevel: Yup.array().nullable().required("نباید خالی باشد"),
        email: Yup.string()
            .required("نباید خالی باشد")
            .test("ایمیل معتبر نیست", "ایمیل معتبر نیست", function (email = "") {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }),
        nationalCode: Yup.string()
            .required("نباید خالی باشد")
            .test(
                "کد ملی را به درستی وارد کنید",
                "کد ملی را به درستی وارد کنید",
                //@ts-ignore
                function (code = "") {
                    const re = /^\d+$/;
                    return re.test(String(code).toLowerCase());
                }
            ),
            birthDate: Yup.string()
            .nullable()
            .required("نباید خالی باشد")
            .test("تاریخ معتبر نیست", "تاریخ معتبر نیست", function (value: string) {
                return moment(value).isValid();
            }),
            phoneNumber:Yup.string()
            .required('نباید خالی باشد')
            // .test("شماره موبایل باید 11 رقمی باشد", "شماره موبایل باید 11 رقمی باشد", function (value = '') {
            //   return value.toString().length === 11;
            // })
            .test("شماره موبایل را به درستی وارد کنید", "شماره موبایل را به درستی وارد کنید", function (value = '') {
                const reg = /^09[0-9]{9}/
                return reg.test(String(value).toLocaleLowerCase())
              }),
            // age:Yup.number().nullable().required("نباید خالی باشد"),
        //   image: Yup.string().required("نباید خالی باشد"),
        //   imageName: Yup.string().required("نباید خالی باشد"),
        // imageDesc: Yup.string().required("نباید خالی باشد"),
        password: Yup.string()
            .required("نباید خالی باشد")
            .test("رمز شما باید حداقل 8 رقمی باشد", "رمز شما باید حداقل 8 رقمی باشد", function (value = "") {
                return value?.length > 7;
            }),
        ConfirmPassword: Yup.string()
            .required("نباید خالی باشد")
            .test("رمز ها مطابقت ندارند", "رمز ها مطابقت ندارند", function (value = "") {
                return value === this.parent.password;
            }),
    });
    const addFormik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            // userName: "",
            fatherName: "",
            nationalCode: "",
            gender: "Male",
            // isActive: true,
            accessLevel: null,
            nationality: "",
            birthDate: "",
            // age: null,
            // image: "",
            // imageName: "",
            // imageDesc: "",
            email: "",
            phoneNumber:"",
            password: "",
            ConfirmPassword: "",
        },
        validationSchema: validation,
        onSubmit: async (values, { resetForm }) => {
            // if(addFormik.values.image){
            const FullName = `${values.firstName} ${values.lastName}`;
            const nameAccessLevel: object[] = values?.accessLevel?.map((item: any) => {
                return item.name;
            });
            const sendValue = {...values};
            delete sendValue.ConfirmPassword;
            delete sendValue.firstName;
            delete sendValue.lastName;
            delete sendValue.accessLevel;
            const res: any = await addUser({
                ...sendValue,
                fullName: FullName,
                roles: nameAccessLevel,
                userName:sendValue.nationalCode
                // imageObj: { ImageFile: values.image, ImageName: values.imageName, Description: values.imageDesc },
            });
            console.log(res);
            if (!(res instanceof Error) && res.isSuccess) {
                handleFinalUpload(res.data.id);
                resetForm();
                addFormik.setFieldValue("birthDate",null)
                setlimitedImageUpload(false);
                toast.success("کاربر با موفقیت ثبت شد");
            } else if (res.statusCode === "BadRequest") {
                toast.error(res.message);
                // addFormik.handleReset(1);
                setlimitedImageUpload(true);
                addFormik.setFieldValue("userName","");
                addFormik.setFieldValue("nationalCode","");
                addFormik.setFieldValue("email","");
            } else if (res instanceof Error) {
                toast.error("درج کاربر با خطا مواجه شد");
                addFormik.handleReset(1);
                setlimitedImageUpload(false);
            }
            // if (addFormik.isValid
            //     && addFormik.values.roles.length
            //     // && image.current.files[0]
            //     // && (image.current.files[0].type.includes('jpeg') || image.current.files[0].type.includes('jpg'))
            // ) {
            //   // if (addFormik.values.password !== addFormik.values.confirmPassword) {
            //   //   toast.error('رمز ورود و تکرار آن مطابقت ندارند' )
            //   //   return
            //   // }
            //   let resUpload = null
            //   // @ts-ignore
            //   if (values.image) {
            //     const formData = new FormData();
            //     formData.set('image', image.current.files[0])
            //     resUpload = await uploadPicture(formData)
            //   }
            //   if (!(resUpload instanceof Error)) {
            //     const {
            //       departmentId,
            //       roles,
            //       personnelNumber,
            //       firstName,
            //       lastName,
            //       fatherName,
            //       nationalCode,
            //       mobile,
            //       isLeader,
            //       gender,
            //       address
            //     } = addFormik.values
            //     const resRegister = await registerAccount({
            //       password: nationalCode,
            //       departmentId,
            //       personnelNumber,
            //       roles: values.roles,
            //       firstName,
            //       lastName,
            //       fatherName,
            //       nationalCode,
            //       confirmPassword: nationalCode,
            //       mobile,
            //       isLeader,
            //       gender,
            //       address,
            //       picture: resUpload
            //     })
            //     if (!(resRegister instanceof Error)) {
            //       resetForm()
            //       toast.success('کاربر با موفقیت درج شد')
            //     } else {
            //       toast.error('درج کاربر با خطا مواجه شد')
            //     }
            //   } else {
            //     toast.error('آپلود عکس باخطا مواجه شد')
            //   }
            // } else {
            //   console.log(addFormik.errors)
            //   if (image.current.files[0] && !(image.current.files[0].type.includes('jpeg') || image.current.files[0].type.includes('jpg'))) {
            //     toast.error('فایل مورد قبول را وارد کنید')
            //   } else
            //     toast.error('همه فیلد ها را درست پر کنید')
            // }
            // }
        },
    });

    // if(addFormik.values.image){
    //   console.log(refImage.current.files[0]);
    // }
    // console.log(addFormik.values.accessLevel);

    const handleUploadImage =(e: any) => {
        e.preventDefault();
        if (!!(refImage?.current?.files[0]) && !!e) {
            setTempPictureData({
                imageName: `${addFormik?.values?.nationalCode}${addFormik?.values?.nationalCode}${addFormik?.values?.email}`,
                imageFile: refImage.current.files[0],
                imageDescription: refImageDesc?.current?.value,
            }) 
            setShowImage(false);
            setlimitedImageUpload(true);
        }
    };

    const handleFinalUpload = async (userId:any) =>{
        if(!!tempPictureData && !!userId){
            const res: any = await uploadUserImage(tempPictureData, userId );
            if (!(res instanceof Error)) {
                setShowImage(false);
                setlimitedImageUpload(true);
                setTempPictureData(null)
                toast.success("آپلود عکس با موفقیت انجام شد");
            } else {
                toast.error("آپلود عکس با خطا مواجه شد");
            }
        }
    }

    return (
        <Paper
            sx={{
                margin: "auto",
                textAlign: "center",
                maxWidth: 1000,
                backgroundColor: `background.paper`,
                boxShadow: (theme) => theme.shadows[5],
                padding: (theme) => theme.spacing(2, 4, 3),
                borderRadius: (theme) => theme.shape.borderRadius + "1px",
            }}>
            <form noValidate onSubmit={addFormik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <PageTitleStyle variant="h4">درج کاربر</PageTitleStyle>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"firstName"}
                            variant="outlined"
                            label={"نام"}
                            fullWidth
                            required
                            value={addFormik.values.firstName}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.firstName && Boolean(addFormik.errors.firstName)}
                            helperText={addFormik.touched.firstName && addFormik.errors.firstName}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"lastName"}
                            variant="outlined"
                            label={"نام خانوادگی"}
                            fullWidth
                            required
                            value={addFormik.values.lastName}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.lastName && Boolean(addFormik.errors.lastName)}
                            helperText={addFormik.touched.lastName && addFormik.errors.lastName}
                        />
                    </Grid>
                    {/* <Grid item sm={6} xs={12}>
                        <TextField
                            id={"userName"}
                            variant="outlined"
                            label={"نام کاربری"}
                            fullWidth
                            required
                            value={addFormik.values.userName}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.userName && Boolean(addFormik.errors.userName)}
                            helperText={addFormik.touched.userName && addFormik.errors.userName}
                        />
                    </Grid> */}
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"fatherName"}
                            variant="outlined"
                            label={"نام پدر"}
                            fullWidth
                            required
                            value={addFormik.values.fatherName}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.fatherName && Boolean(addFormik.errors.fatherName)}
                            helperText={addFormik.touched.fatherName && addFormik.errors.fatherName}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"nationalCode"}
                            type="text"
                            variant="outlined"
                            label={"کد ملی"}
                            fullWidth
                            required
                            value={addFormik.values.nationalCode}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.nationalCode && Boolean(addFormik.errors.nationalCode)}
                            helperText={addFormik.touched.nationalCode && addFormik.errors.nationalCode}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"phoneNumber"}
                            type="text"
                            variant="outlined"
                            label={"شماره موبایل"}
                            fullWidth
                            required
                            value={addFormik.values.phoneNumber}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.phoneNumber && Boolean(addFormik.errors.phoneNumber)}
                            helperText={addFormik.touched.phoneNumber && addFormik.errors.phoneNumber}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"nationality"}
                            variant="outlined"
                            label={"ملیت"}
                            fullWidth
                            value={addFormik.values.nationality}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.nationality && Boolean(addFormik.errors.nationality)}
                            helperText={addFormik.touched.nationality && addFormik.errors.nationality}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterJalali}>
                            <DatePicker
                                //    okText={"تایید"}
                                //    cancelText={"لغو"}
                                label="تاریخ تولد"
                                mask="____/__/__"
                                value={addFormik.values.birthDate}
                                onChange={(newValue: any) => moment(newValue, "YYYY/MM/DD").isValid() && addFormik.setFieldValue("birthDate", moment(newValue).locale("en").format("YYYY-MM-DD"))}
                                renderInput={(params: any) => <TextField 
                                    {...params}
                                    id={"birthDate"}
                                    fullWidth
                                    required
                                    error={addFormik.touched.birthDate && Boolean(addFormik.errors.birthDate)}
                                    //@ts-ignore
                                    helperText={addFormik.touched.birthDate && addFormik.errors.birthDate}
                                      />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <TextField
                            id={"age"}
                            variant="outlined"
                            label={"سن"}
                            fullWidth
                            type="number"
                            value={addFormik.values.age}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.age && Boolean(addFormik.errors.age)}
                            //@ts-ignore
                            helperText={addFormik.touched.age && addFormik.errors.age}
                        />
                    </Grid> */}
                    <Grid item sm={6} xs={12}>
                        <Autocomplete
                            value={addFormik.values.accessLevel ?? []}
                            onChange={(event: any, newValue: any) => addFormik.setFieldValue("accessLevel", newValue.length === 0 ? null : newValue)}
                            multiple
                            id="checkboxes-tags-company"
                            options={roles}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => {
                                //@ts-ignore
                                return accessLevelName[`${option.name}`];
                            }}
                            renderOption={(props, option: any, { selected }) => {
                                return (
                                    <li {...props} key={option.id}>
                                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                        {
                                            //@ts-ignore
                                            accessLevelName[`${option.name}`]
                                        }
                                    </li>
                                );
                            }}
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="سطح دسترسی"
                                    placeholder="سطح دسترسی را انتخاب کنید"
                                    error={addFormik.touched.accessLevel && Boolean(addFormik.errors.accessLevel)}
                                    //@ts-ignore
                                    helperText={addFormik.touched.accessLevel && addFormik.errors.accessLevel}
                                    name={"accessLevel"}
                                    id="accessLevel"
                                    value={addFormik.values.accessLevel}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id={"email"}
                            variant="outlined"
                            label={"ایمیل"}
                            required
                            type={"email"}
                            fullWidth
                            value={addFormik.values.email}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.email && Boolean(addFormik.errors.email)}
                            helperText={addFormik.touched.email && addFormik.errors.email}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Button
                            variant="contained"
                            endIcon={<UploadIcon />}
                            sx={{
                                backgroundColor: (theme: any) => alpha(theme.palette.primary.main, 0.8),
                                width: "100%",
                                mt: 2,
                            }}
                            onClick={() => setShowImage(true)}
                            disabled={!(!!addFormik.values.nationalCode && !!addFormik.values.nationalCode && !!addFormik.values.email) || limitedImageUpload}>
                            <Typography>آپلود تصویر</Typography>
                        </Button>
                        {tempPictureData ? <Typography variant="caption">عکس ذخیره شده است</Typography>  : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">جنسیت</FormLabel>
                            <RadioGroup
                                aria-label="gender"
                                name={"gender"}
                                id="gender"
                                style={{ flexDirection: "row" }}
                                defaultValue="Male"
                                value={addFormik.values.gender}
                                onChange={(event: any) => addFormik.setFieldValue("gender", event.target.value)}
                                // @ts-ignore
                                error={addFormik.touched.gender && Boolean(addFormik.errors.gender)}>
                                <FormControlLabel value={"Male"} control={<Radio />} label="مرد" />
                                <FormControlLabel value={"Female"} control={<Radio />} label="زن" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {/* <Grid item sm={3} xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">فعال</FormLabel>
                            <RadioGroup
                                aria-label="isActive"
                                name={"isActive"}
                                id="isActive"
                                style={{ flexDirection: "row" }}
                                defaultValue={addFormik.values.isActive}
                                onChange={(event: any) => addFormik.setFieldValue("isActive", event.target.value === "true" ? true : false)}
                                // @ts-ignore
                                error={addFormik.touched.isLeader && Boolean(addFormik.errors.isLeader)}>
                                <FormControlLabel value={true} control={<Radio />} label="هست" />
                                <FormControlLabel value={false} control={<Radio />} label="نیست" />
                            </RadioGroup>
                        </FormControl>
                    </Grid> */}

                    {/* <Grid item sm={6} xs={12}> */}
                    {/* <label htmlFor="icon-button-file">
              <Input accept="image/*" id="icon-button-file" type="file" onChange={(e:any) => console.log(e.target.files[0])} />
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
              </IconButton>
            </label> */}
                    {/* <TextField
              id={"image"}
              variant="outlined"
              label={"تصویر"}
              fullWidth
              style={{ direction: "ltr" }}
              inputRef={refImage}
              inputProps={{
                accept: "image/jpg",
              }}
              type={"file"}
              value={addFormik.values.image}
              onChange={addFormik.handleChange}
              error={addFormik.touched.image && Boolean(addFormik.errors.image)}
              helperText={(addFormik.touched.image && addFormik.errors.image) || ` وارد کنید jpg تنها فایل   `}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id={"imageName"}
              variant="outlined"
              label={"اسم تصویر"}
              required
              type={"imageName"}
              fullWidth
              value={addFormik.values.imageName}
              onChange={addFormik.handleChange}
              error={addFormik.touched.imageName && Boolean(addFormik.errors.imageName)}
              helperText={addFormik.touched.imageName && addFormik.errors.imageName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id={"imageDesc"}
              type="text"
              variant="outlined"
              label={"توضیحات عکس"}
              fullWidth
              required
              value={addFormik.values.imageDesc}
              onChange={addFormik.handleChange}
              error={addFormik.touched.imageDesc && Boolean(addFormik.errors.imageDesc)}
              helperText={addFormik.touched.imageDesc && addFormik.errors.imageDesc}
            />
          </Grid> */}
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"password"}
                            variant="outlined"
                            label={"رمز"}
                            required
                            type={"password"}
                            fullWidth
                            value={addFormik.values.password}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.password && Boolean(addFormik.errors.password)}
                            helperText={addFormik.touched.password && addFormik.errors.password}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id={"ConfirmPassword"}
                            variant="outlined"
                            label={"تکرار رمز"}
                            required
                            type={"password"}
                            fullWidth
                            value={addFormik.values.ConfirmPassword}
                            onChange={addFormik.handleChange}
                            error={addFormik.touched.ConfirmPassword && Boolean(addFormik.errors.ConfirmPassword)}
                            helperText={addFormik.touched.ConfirmPassword && addFormik.errors.ConfirmPassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth color="primary" variant="contained" type={"submit"}>
                            ثبت کاربر
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {showImage ? (
                <ImageModal
                    open={showImage}
                    onClose={() => {
                        setShowImage(false);
                    }}
                    title="آپلود عکس پروفایل"
                    // allowAccess={!!addFormik.values.userName && !!addFormik.values.nationalCode && !!addFormik.values.email}
                >
                    <form action="" onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleUploadImage(e)}>
                        <Grid container alignItems={"center"} justifyContent={"center"} spacing={4}>
                            <Grid item xs={12}>
                                <Button color="primary" variant="contained" aria-label="upload picture" component="label" endIcon={<PhotoCamera />}>
                                    <input hidden accept="image/*" type="file" ref={refImage} />
                                    تصویر
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id={"description "} variant="outlined" label={"توضیحات"} fullWidth type="text" rows={3} maxRows={5} multiline inputRef={refImageDesc} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    آپلود تصویر
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ImageModal>
            ) : null}
        </Paper>
    );
}

export { AddUser };
