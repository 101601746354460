import { MainLayout as Layout } from "../layout/main.layout";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Rtl, Loader } from "../components/index";
import {
   AddClass,
   AddCourse,
   AddUser,
   CourseList,
   AdminDashboard,
   ClassList,
   LoginAndRegisterPage,
   Report,
   UsersList,
   ScoreClassList,
   AddScore,
   UserProfile,
   StudentDashboard,
   TeacherDashboard,
   OwnerDashboard,
   ExpertDashboard,
   StudentClassesList,
   SurveyMainPage,
   StudentClassList,
   AddCertificate,
   CertificateAdminList,
   CertificateStudentList,
   AdminLicenseList,
} from "pages";
import { useSelector } from "react-redux";
import { RequireAuth } from "hoc/ReqiredAuth";
import { RolesDashboard } from "hoc/RolesDashboard";
import { useDispatch } from "react-redux";
import { actionExit } from "redux/globalSlice";
import { toast } from "react-toastify";
import { TokenVerify } from "hoc/tokenVerify";
import CertificateTracking from "../pages/certificate/tracking/certificateTracking";

function AppRoute() {
   const login = !!useSelector((state: any) => state.global.user);
   const expireTime = useSelector((state: any) => state.global.user?.expires_in);

   const dispatch = useDispatch();

   if(expireTime){
      //@ts-ignore
      const expireTimeParse = parseInt(Date.parse(expireTime) / 1000)
      //@ts-ignore
      const dateNowPare = parseInt(new Date() / 1000)

      if(expireTimeParse <= dateNowPare){
         dispatch(actionExit(""))
         toast.warn("اعتبار حساب شما منقضی شده است.لطفا دوباره وارد شوید", { autoClose: 4500 })
      }
   }
  

   return (
      <BrowserRouter>
         <Rtl>
            <Loader />
            <Routes>
               <Route path="/login" element={<LoginAndRegisterPage />} />
            </Routes>

            <Routes>
               <Route element={<TokenVerify/>}>
               <Route element={login ? <Layout /> : <Navigate to="/login" replace />}>
                  <Route element={<RequireAuth allowedRoles={["Owner", "Admin"]} />}>
                     {/* <Route path="/" element={<AdminDashboard />} /> */}
                     <Route path="/report" element={<Report />} />
                     <Route path="/addcourse" element={<AddCourse />} />
                     <Route path="/courseslist" element={<CourseList />} />
                     <Route path="/addclass" element={<AddClass />} />
                     <Route path="/classlist" element={<ClassList />} />
                     <Route path="/adduser" element={<AddUser />} />
                     <Route path="/userslist" element={<UsersList />} />
                  </Route>
                  <Route element={<RequireAuth allowedRoles={["Teacher"]} />}>
                     <Route path="/scoreclassList" element={<ScoreClassList />} />
                     {/* <Route path="/scoreclassList/:classId" element={<AddScore />} /> */}
                  </Route>
                  <Route element={<RequireAuth allowedRoles={["Owner","Admin","Teacher","Student"]} />}>
                     <Route path="/profile" element={<UserProfile />} />
                  </Route>
                  
                  <Route element={<RequireAuth allowedRoles={["Owner","Admin","Teacher","Expert","Student"]} />}>
                     <Route path="/" element={<RolesDashboard/>} />
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Owner","Admin","Expert"]} />}>
                     <Route path="/addlicenselist" element={<AdminLicenseList/>} />
                  </Route>                

                  <Route element={<RequireAuth allowedRoles={["Owner"]} />}>
                     <Route path="/ownerdashboard" element={<OwnerDashboard/>} />
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
                     <Route path="/admindashboard" element={<AdminDashboard/>} />
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Expert"]} />}>
                     <Route path="/expertdashboard" element={<ExpertDashboard/>} />
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Teacher"]} />}>
                     <Route path="/teacherdashbaord" element={<TeacherDashboard/>} />
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Student"]} />}>
                     <Route path="/studentdashboard" element={<StudentDashboard/>} />
                     <Route path="/studentClassesList" element={<StudentClassesList/>} />
                     <Route path="/studentClassesList/:classId" element={<StudentClassList/>} />
                     {/* <Route path="/surveyMainPage" element={<SurveyMainPage/>} /> */}
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Owner","Admin","Teacher","Expert"]} />}>
                     <Route path="/addDegree" element={<AddCertificate/>} />
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Owner","Admin","Expert"]} />}>
                     <Route path="/allDegreeList" element={<CertificateAdminList/>} />
                     <Route path="/degreeTracking" element={<CertificateTracking/>} />

                  </Route>

                  <Route element={<RequireAuth allowedRoles={["Student"]} />}>
                     <Route path="/studentDegreeList" element={<CertificateStudentList/>} />
                  </Route>

               </Route>
               </Route>
            </Routes>
         </Rtl>
      </BrowserRouter>
   );
}

export { AppRoute };
