import ListItem from "@mui/material/ListItem";
import { Box, Collapse, Tooltip, Typography, useTheme } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import React, { useState } from "react";
// import {withRouter} from "react-router-dom";
import { alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ListItemProps } from "@mui/material/ListItem";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsCheck2 } from 'react-icons/bs';

interface ListProps extends ListItemProps {
   type: "parent" | "child";
   active: boolean;
}

const ListItemStyled = styled(ListItem, {
   shouldForwardProp: (prop) => prop !== "type" && prop !== "active",
})<ListProps>(({ theme, type, active }) => ({
   borderRadius: theme.shape.borderRadius,
   paddingTop: 4,
   paddingBottom: 4,
   marginBottom: 5,
   color: theme.palette.background.paper,
   "& .collapse-icon": {
      visibility: "hidden",
   },
   "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      color: theme.palette.primary.main,
      "& .collapse-icon": {
         visibility: "unset",
      },
   },
   ...(active && {
      color: theme.palette.primary.main,
      backgroundColor: type === "child" && alpha(theme.palette.primary.main, 0.05),
   }),
}));

function ItemMenu(props: any) {
   const { title, icon, children, history, category, currentPage, menuOpen, accessLevel, isAdmin, single } = props;
   const [open, setOpen] = useState(false);
   const navigate = useNavigate();
   const user = useSelector((state: any) => state.global.user);

   const theme = useTheme();

   const changePage = (route: string) => {
      navigate(route);
   };

   const roles = useSelector((state: any) => state.global.user.roles);

   const roleLength = user.roles?.length;

  //  const multiDashboard = () =>{
  //   if (roleLength > 1) {
  //     if(roles.includes("Owner")){
  //       return <OwnerDashboard/>
  //     }else if(roles.includes("Admin")){
  //       return <AdminDashboard/>
  //     }else if (roles.includes("Expert")){
  //       return <ExpertDashboard/>
  //     }else if (roles.includes("Teacher")){
  //       return <TeacherDashboard/>
  //     }else if(roles.includes("Student")){
  //       return <StudentDashboard/>
  //     }
  //    }
  //  }

   if (roleLength === 1) {
      return (
         <>
            {user?.roles?.find((role: any) => accessLevel?.includes(role)) ? (
               <>
                  <Tooltip title={title} disableInteractive arrow>
                     <Box mx={1}>
                        <ListItemStyled
                           type={"parent"}
                           active={currentPage.includes(category)}
                           onClick={() => {
                              setOpen(!open);
                              if (single) {
                                 changePage(String(single));
                              }
                           }}>
                           <ListItemIcon className="icon-menu-layout" style={{ color: `${theme.palette.common.white}` }}>
                              {" "}
                              {icon}
                           </ListItemIcon>
                           {menuOpen && (
                              <>
                                 <ListItemText
                                    primary={<Typography style={{ color: `${theme.palette.common.white}` }}>{title}</Typography>}
                                 />
                                 {/* {open ? <ExpandLess fontSize="small"/> : <ExpandMore className={'collapse-icon'} fontSize="small"/>} */}
                              </>
                           )}
                        </ListItemStyled>
                     </Box>
                  </Tooltip>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                     <Box mr={1} ml={3}>
                        <List component="div" disablePadding>
                           {children
                              .filter((item: any) => user?.roles?.find((role: any) => item.accessLevel?.includes(role)))
                              .map((item: any) => {
                                 return (
                                    <Tooltip key={item.title} arrow disableInteractive title={item.title}>
                                       <ListItemStyled
                                          type={"child"}
                                          active={currentPage.includes(item.route)}
                                          onClick={() => changePage(String(item.route))}
                                          style={{position:"relative", cursor:"pointer"}}
                                          >
                                          <BsCheck2 style={{position:"absolute",marginRight:"-18px",color: currentPage.includes(item.route) ? `${theme.palette.primary.main}`:`${theme.palette.common.white}`, transform:"rotate(-46deg)" , fontSize:"60px" , height:"30px" , right:"0px" , bottom:"10px"}}/>
                                          <ListItemIcon style={{ color: currentPage.includes(item.route) ? `${theme.palette.primary.main}`:`${theme.palette.common.white}` , marginLeft:"-10px", marginRight:"7px" }}>{item.icon}</ListItemIcon>
                                          <ListItemText style={{ color:  currentPage.includes(item.route) ? `${theme.palette.primary.main}`:`${theme.palette.common.white}` }} primary={item.title} />
                                       </ListItemStyled>
                                    </Tooltip>
                                 );
                              })}
                        </List>
                     </Box>
                  </Collapse>
               </>
            ) : null}
         </>
      );
   } 
  else if (roleLength > 1) {
      return (
         <>
            {/* {user?.roles?.find((role: any) => accessLevel?.includes(role)) ? (
               <>
                  <Tooltip title={title} disableInteractive arrow>
                     <Box mx={1}>
                        <ListItemStyled
                           type={"parent"}
                           active={currentPage.includes(category)}
                           onClick={() => {
                              setOpen(!open);
                              if (single) {
                                //  changePage(String(single));
                              }
                           }}>
                           <ListItemIcon className="icon-menu-layout" style={{ color: `${theme.palette.common.white}` }}>
                              {" "}
                              {icon}
                           </ListItemIcon>
                           {menuOpen && (
                              <>
                                 <ListItemText
                                    primary={<Typography style={{ color: `${theme.palette.common.white}` }}>{title}</Typography>}
                                 />
                              </>
                           )}
                        </ListItemStyled>
                     </Box>
                  </Tooltip>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                     <Box mr={1} ml={3}>
                        <List component="div" disablePadding>
                           {children
                              .filter((item: any) => user?.roles?.find((role: any) => item.accessLevel?.includes(role)))
                              .map((item: any) => {
                                 return (
                                    <Tooltip key={item.title} arrow disableInteractive title={item.title}>
                                       <ListItemStyled
                                          type={"child"}
                                          active={currentPage.includes(item.route)}
                                          onClick={() => changePage(String(item.route))}>
                                          <ListItemIcon style={{ color: `${theme.palette.common.white}` }}>{item.icon}</ListItemIcon>
                                          <ListItemText style={{ color: `${theme.palette.common.white}` }} primary={item.title} />
                                       </ListItemStyled>
                                    </Tooltip>
                                 );
                              })}
                        </List>
                     </Box>
                  </Collapse>
               </>
            ) : null} */}
            
            {user?.roles?.find((role: any) => accessLevel?.includes(role)) ? (
               <>
                  <Tooltip title={title} disableInteractive arrow>
                     <Box mx={1}>
                        <ListItemStyled
                           type={"parent"}
                           active={currentPage.includes(category)}
                           onClick={() => {
                              setOpen(!open);
                              if (single) {
                                //  changePage(String(single));
                              }
                           }}>
                           <ListItemIcon className="icon-menu-layout" style={{ color: `${theme.palette.common.white}` }}>
                              {" "}
                              {icon}
                           </ListItemIcon>
                           {menuOpen && (
                              <>
                                 <ListItemText
                                    primary={<Typography style={{ color: `${theme.palette.common.white}` }}>{title}</Typography>}
                                 />
                                 {/* {open ? <ExpandLess fontSize="small"/> : <ExpandMore className={'collapse-icon'} fontSize="small"/>} */}
                              </>
                           )}
                        </ListItemStyled>
                     </Box>
                  </Tooltip>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                     <Box mr={1} ml={3}>
                        <List component="div" disablePadding>
                           {children
                              .filter((item: any) => user?.roles?.find((role: any) => item.accessLevel?.includes(role)))
                              .map((item: any) => {
                                 return (
                                    <Tooltip key={item.title} arrow disableInteractive title={item.title}>
                                       <ListItemStyled
                                          type={"child"}
                                          active={currentPage.includes(item.route)}
                                          onClick={() => changePage(String(item.route))}
                                          style={{position:"relative", cursor:"pointer"}}
                                          >
                                         <BsCheck2 style={{position:"absolute",marginRight:"-18px",color: currentPage.includes(item.route) ? `${theme.palette.primary.main}`:`${theme.palette.common.white}`, transform:"rotate(-46deg)" , fontSize:"60px" , height:"30px" , right:"0px" , bottom:"10px"}}/>  
                                          <ListItemIcon style={{color: currentPage.includes(item.route) ? `${theme.palette.primary.main}`:`${theme.palette.common.white}` , marginLeft:"-10px", marginRight:"7px" }}>{item.icon}</ListItemIcon>
                                          <ListItemText style={{ color: currentPage.includes(item.route) ? `${theme.palette.primary.main}`:`${theme.palette.common.white}` }} primary={item.title} />
                                       </ListItemStyled>
                                    </Tooltip>
                                 );
                              })}
                        </List>
                     </Box>
                  </Collapse>
               </>
            ) : null}
         </>
      );
   }
}

export { ItemMenu };
