import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Autocomplete,
  Typography,
  alpha,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { accessLevelName } from "utils/accessLevel.utils";
import { postCourse } from "api/course/courseApi";
import { getAllStudents } from "api/student/studentApi";
import { degreeNameItem } from "../../../utils/degreeNameItem";
import { GetUserClasses } from "api/classroom/classroomApi";
import { CreateCertificate } from "api/certificate/certificateApi";

const PageTitleStyle = styled(Typography)(({ theme }: any) => ({
  // marginTop: "-1.55%",
  // backgroundColor: alpha(theme.palette.primary.main, .9),
  // paddingTop: "1%",
  // paddingBottom: "1%",
  // width: "107%",
  // marginLeft: "-3.5% !important",
  // borderRadius: "5px",
  color: theme.palette.primary.main,
  fontWeight: "600",
}));

function AddCertificate() {
  const [studentsNameOpt, setStudentsNameOpt] = useState<any>(null);
  const [studentClassesOpt, setStudentClassesOpt] = useState<any>(null);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const getAllNeedData = async () => {
    const resStd: any = await getAllStudents();
    if (!(resStd instanceof Error)) {
      setStudentsNameOpt(resStd);
    } else {
      toast.error("دریافت داده با خطا مواجه شد");
    }
  };

  useEffect(() => {
    getAllNeedData();
  }, []);

  const getStudentClasses = async (stdId: number) => {
    const res: any = await GetUserClasses(stdId);
    if (!(res instanceof Error)) {
      setStudentClassesOpt(res);
    } else {
      toast.error("دریافت داده با خطا مواجه شد");
    }
  };

  const validation = Yup.object({
    studentName: Yup.object().nullable().required("نباید خالی باشد"),
    className: Yup.object().nullable().required("نباید خالی باشد"),
    degreeName: Yup.array().nullable().required("نباید خالی باشد"),
    certsScore: Yup.number().nullable().required("نباید خالی باشد").test('نمره باید از حداکثر نمره کوچک تر باشد', 'نمره باید از حداکثر نمره کوچک تر باشد', function (value) {
      return +value <= +this.parent.maxScore
    })
    .test('نمره باید بزرگ تر از صفر باشد', 'نمره باید بزرگ تر از صفر باشد', function (value) {
      return +value >= 0
    }),
    maxScore: Yup.number().nullable().required("نباید خالی باشد"),
  });
  const addDegreeFormik = useFormik({
    initialValues: {
      studentName: null,
      degreeName: null,
      className: null,
      certsScore: null,
      maxScore: 200,
      description:""
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      console.log( {
          classId:values.className.id,
          Grade:values.certsScore,
          MaxGrade:values.maxScore,
          Description:values.description,
          Name:values.degreeName
        }   
        );
        const res: any = await CreateCertificate({
          classId:values.className.id,
          Grade:values.certsScore,
          MaxGrade:values.maxScore,
          Description:values.description,
          Name:values.degreeName
        },values.studentName.id);
        if (!(res instanceof Error)) {
          console.log(res);
          resetForm();
          addDegreeFormik.setFieldValue("certsScore","")
          toast.success("ساخت مدرک با موفقیت انجام شد");
        } else {
          toast.error("ثبت اطلاعات با خطا مواجه شد");
        }
    },
  });

  useEffect(() => {
    if (addDegreeFormik.values.studentName) {
      getStudentClasses(addDegreeFormik.values.studentName.id);
    }else{
      setStudentClassesOpt(null)
      addDegreeFormik.setFieldValue("className",null)
    }
  }, [addDegreeFormik.values.studentName]);

  console.log(studentClassesOpt);

  return (
    <Paper
      sx={{
        marginX: "auto",
        textAlign: "center",
        maxWidth: 1000,
        backgroundColor: `background.paper`,
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(2, 4, 3),
        borderRadius: (theme) => theme.shape.borderRadius + "1px",
        marginTop: { xs: 1, lg: 7 },
      }}
    >
      <form noValidate onSubmit={addDegreeFormik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageTitleStyle variant="h4">ساخت مدرک</PageTitleStyle>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={addDegreeFormik.values?.studentName}
              onChange={(event: any, newValue: any) => {
                addDegreeFormik.setFieldValue("studentName", newValue);
              }}
              options={studentsNameOpt ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.fullName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="دانشجو"
                  required
                  placeholder="دانشجو مورد نظر انتخاب کنید"
                  error={
                    addDegreeFormik.touched.studentName &&
                    Boolean(addDegreeFormik.errors.studentName)
                  }
                  //@ts-ignore
                  helperText={
                    addDegreeFormik.touched.studentName &&
                    addDegreeFormik.errors.studentName
                  }
                  name={"studentName"}
                  id="studentName"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={addDegreeFormik.values?.className}
              onChange={(event: any, newValue: any) => {
                addDegreeFormik.setFieldValue("className", newValue);
              }}
              options={studentClassesOpt ?? []} 
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="کلاس‌های دانشجو انتخاب شده"
                  required
                  placeholder="کلاس دانشجو مورد نظر انتخاب کنید"
                  error={
                    addDegreeFormik.touched.className &&
                    Boolean(addDegreeFormik.errors.className)
                  }
                  //@ts-ignore
                  helperText={
                    addDegreeFormik.touched.className &&
                    addDegreeFormik.errors.className
                  }
                  name={"className"}
                  id="className"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={addDegreeFormik.values.degreeName ?? []}
              onChange={(event: any, newValue: any) => {
                console.log(newValue);
                addDegreeFormik.setFieldValue(
                  "degreeName",
                  newValue.length === 0 ? null : newValue
                );
              }}
              limitTags={3}
              multiple
              options={degreeNameItem}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.id === value.id}
              //@ts-ignore
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="جزءهای قرآن"
                  required
                  placeholder="جزء مورد مورد نظر را انتخاب کنید"
                  error={
                    addDegreeFormik.touched.degreeName &&
                    Boolean(addDegreeFormik.errors.degreeName)
                  }
                  //@ts-ignore
                  helperText={
                    addDegreeFormik.touched.degreeName &&
                    addDegreeFormik.errors.degreeName
                  }
                  name={"degreeName"}
                  id="degreeName"
                  value={addDegreeFormik.values.degreeName}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="certsScore"
              name={"certsScore"}
              type="number"
              variant="outlined"
              label={"نمره دانشجو"}
              fullWidth
              required
              value={addDegreeFormik.values.certsScore}
              onChange={addDegreeFormik.handleChange}
              error={
                addDegreeFormik.touched.certsScore &&
                Boolean(addDegreeFormik.errors.certsScore)
              }
              //@ts-ignore
              helperText={
                addDegreeFormik.touched.certsScore &&
                addDegreeFormik.errors.certsScore
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id={"maxScore"}
              type="number"
              variant="outlined"
              label={"حداکثر نمره"}
              fullWidth
              required
              value={addDegreeFormik.values.maxScore}
              onChange={addDegreeFormik.handleChange}
              error={
                addDegreeFormik.touched.maxScore &&
                Boolean(addDegreeFormik.errors.maxScore)
              }
              //@ts-ignore
              helperText={
                addDegreeFormik.touched.maxScore &&
                addDegreeFormik.errors.maxScore
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id={"description"}
              variant="outlined"
              label={"توضیحات"}
              fullWidth
              type="text"
              rows={3}
              maxRows={5}
              multiline
              value={addDegreeFormik.values.description}
              onChange={addDegreeFormik.handleChange}
              error={
                addDegreeFormik.touched.description &&
                Boolean(addDegreeFormik.errors.description)
              }
              //@ts-ignore
              helperText={
                addDegreeFormik.touched.description && addDegreeFormik.errors.description
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              type={"submit"}
            >
              ثبت مدرک
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export { AddCertificate };
