import http from "../../utils/http.utils";

type Id = string;


export function postLicense(data:any , studentId:Id) {
    return http
        .post(`/api/v2/Users/assigneLicense/${studentId}`, data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function getLicense(studentId:Id) {
    return http
        .get(`/api/v2/Users/GetUserLicense/${studentId}`)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function putLicense(data:any, studentId:Id) {
    return http
        .post(`/api/v2/Users/UpdateUserLicense/${studentId}`, data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function deleteLicense(userId:Id) {
    return http
        .delete(`/api/v2/Users/DeleteUserLicense/${userId}`)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}