import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Card, CardActions, CardContent, Grid, Paper, styled , useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import SpeedDial, { SpeedDialProps } from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { toast } from "react-toastify";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import moment from "jalali-moment";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

function Widget5({ dashboardSchedule }: any) {
  
  const steps: string[] = [];
  // dashboardSchedule?.map((item: any) => {
  //   const date = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`
  //   steps.push(`${item.day}(${moment(date, "YYYY-M-D").locale("fa").format("YYYY/MM/DD")})`);
  // });
  // dashboardSchedule?.map((item: any) => {
  //   steps.push(`${item.day}`);
  // });
  dashboardSchedule?.map((item: any) => {
    const date = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`
    steps.push(`${item.day}(${moment(date, "YYYY-M-D").locale("fa").format("YYYY/MM/DD")})`);
  });

  //SpeedDialLogic

  const handleCopyLinkClass = (classLink:string) => {
    navigator.clipboard.writeText(classLink);
    toast.info("لینک کلاس کپی شد");
  };

  let actions:any[] = []

  // const actions = [
  //   { icon: <FileCopyIcon onClick={handleCopyLinkClass} />, name: "کپی" },
  //   // { icon: <SaveIcon />, name: "Save" },
  //   // { icon: <PrintIcon />, name: "Print" },
  //   {
  //     icon: (
  //       <WhatsappShareButton
  //         url="someurl"
  //         title={"test"}
  //         // separator="#programing joke"
  //       >
  //         <ShareIcon />
  //       </WhatsappShareButton>
  //     ),
  //     name: "اشتراک گذاری",
  //   },
  // ];


  //StepperLogic

  const [activeStep, setActiveStep] = useState(0);
  const [currentStepLabel, setCurrentStepLabel] = useState("");
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const theme = useTheme();

  const bgcGrey = grey[500];

  let showCard: any[] = [];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  dashboardSchedule?.map((item: any) => {
    if (item.day === "شنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "شنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={6} md={4} sx={{ p: 2}}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block"  , fontStyle:"italic"}} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8  , fontStyle:"italic"}} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "یکشنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "یکشنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={6} md={4} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "دوشنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "دوشنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={6} md={4} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "سه‌شنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "سه‌شنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={6} md={4} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "چهارشنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "چهارشنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={6} md={4} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "پنچ‌شنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "پنج‌شنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={6} md={4} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "جمعه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "جمعه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={6} md={4} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
  });

  console.log(showCard);

  return (
    <Paper elevation={3} sx={{ borderRadius: "10px", p: 2 }}>
      <Box sx={{ width: "100%" }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid container>{showCard}</Grid>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  قبلی
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  بعدی
                </Button>
                {/* {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography variant="caption" sx={{ display: "inline-block" }}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button onClick={handleComplete}>{completedSteps() === totalSteps() - 1 ? "Finish" : "Complete Step"}</Button>
                  ))} */}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </Paper>
  );
}

export { Widget5 };
