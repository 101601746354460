import http from "../../utils/http.utils";

type Id = string;
enum Gender {
  Male,
  Female,
}

export interface LoginParams {
  grant_type: string;
  username: string;
  password: string;
}

export function login(data: LoginParams) {
  const bodyQuery = new URLSearchParams();
  bodyQuery.set("userName", data.username);
  bodyQuery.set("grant_type", data.grant_type);
  bodyQuery.set("password", data.password);
  return http
    .post("/api/v2/Users/Token", bodyQuery)
    .then((res: any) => res.data.data)
    .catch((error: any) => {
      console.log(error);
      return error;
    });
}

interface RegisterProps {
  userName: string;
  email: string;
  password: string;
  fatherName: any;
  nationalCode: any;
  nationality: any;
  fullName: string;
  age: number;
  gender: string;
  birthDate: any;
  image?: any;
}

export function register(data: RegisterProps) {
  return http
    .post("api/v2/Users", data)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}

export function getRoles() {
  return http
    .get("/api/v2/Role")
    .then((res: any) => res.data.data)
    .catch((error: any) => error);
}

// export interface InsertUserProps {
//   "userName": string,
//   "email": string,
//   "password": string,
//   "fatherName"?: string,
//   "nationalCode"?: string,
//   "nationality"?: string,
//   "fullName": string,
//   "age"?: number,
//   "gender"?: string,
//   "isActive": boolean,
//   "birthDate"?:string
//   "roles": object[],
//   "image": any,
// }

// export function InsertUser(data: any) {
//   var bodyFormData = new FormData();
//   console.log(data.accessLevelName)
//   bodyFormData.append("UserName",data.userName)
//   bodyFormData.append("Email",data.email)
//   bodyFormData.append("Password",data.password)
//   bodyFormData.append("FatherName",data.fatherName)
//   bodyFormData.append("NationalCode",data.nationalCode)
//   bodyFormData.append("Nationality",data.nationality)
//   bodyFormData.append("FullName",data.fullName)
//   bodyFormData.append("Age",data.age)
//   bodyFormData.append("Gender",data.gender)
//   bodyFormData.append("IsActive",data.isActive)
//   bodyFormData.append("BirthDate",data.birthDate)
//   bodyFormData.append("Roles",data.accessLevelName)
//   bodyFormData.append("Image",data.imageObj)
//   console.log(bodyFormData);
//   return http
//     .post("api/v2/Users", bodyFormData)
//     .then((res) => res.data.data)
//     .catch((error) => error);
// }

interface addUserProps {
  userName: string;
  email: string;
  password: string;
  fatherName: any;
  nationalCode: any;
  nationality: any;
  fullName: string;
  // age: number;
  gender: string;
  // isActive: boolean;
  birthDate: any;
  roles: any;
  image?: any;
}

export function addUser(data: addUserProps) {
  return http
    .post("/api/v2/Users", data)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}

export function getAllUsers() {
  return http
    .get("/api/v2/Users")
    .then((res: any) => res.data.data)
    .catch((error: any) => error);
}

export function getUser(userId: Id) {
  return http
    .get(`/api/v2/Users/${userId}`)
    .then((res: any) => res.data.data)
    .catch((error: any) => error);
}

export function putUser(data: any, userId: Id) {
  return http
    .put(`/api/v2/Users/${userId}`, data)
    .then((res: any) => res.data.data)
    .catch((error: any) => error);
}

export function deleteUser(userId: Id) {
  return http
    .delete(`/api/v2/Users/${userId}`)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}

export function putRoles(data: any, userId: Id) {
  return http
    .put(`/api/v2/role/${userId}`, data)
    .then((res: any) => res.data.data)
    .catch((error: any) => error);
}

export function changeUserActivation(data: any, userId: Id) {
  return http
    .put(`/api/v2/Users/ChangeActivation/${userId}`, { params: data })
    .then((res: any) => res.data.data)
    .catch((error: any) => error);
}

export function uploadUserImage(data: any, userId: Id) {
  var bodyFormData = new FormData();
  bodyFormData.append("ImageName", data.imageName);
  bodyFormData.append("ImageFile", data.imageFile);
  bodyFormData.append("Description", data.imageDescription);
  console.log(bodyFormData);
  return http
    .post(`/api/v2/Users/UploadProfile/${userId}`, bodyFormData)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}

interface changePasswordProps {
  CurrentPassword: string,
  NewPassword: string,
  UserName:string
}

export function changePassword(data: changePasswordProps) {
  return http
    .post("/api/v2/Users/changePassword", data)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}

interface ForgotPasswordProps{
  PhoneNumber:string
}

export function SendSMSForgetPassword(data: ForgotPasswordProps) {
  return http
    .post("/api/v2/Users/ForgetPassword", data)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}
interface SetNewPasswordAfterTokenProps{
  PhoneNumber:string,
  NewPassword:string
}

export function SetNewPasswordAfterToken(data: SetNewPasswordAfterTokenProps) {
  return http
    .post("/api/v2/Users/SetNewPassword", data)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}
interface VerifyForgetSMSTokenProps{
  PhoneNumber:string,
  Token:string
}

export function VerifyForgetSMSToken(data: VerifyForgetSMSTokenProps) {
  return http
    .post("/api/v2/Users/VerifyChangePassword", data)
    .then((res: any) => res.data)
    .catch((error: any) => error);
}

// export function showAvatar(data: any) {
//     return http
//         .get(`/api/v2/Users/ShowAvatar`, {params:data})
//         .then((res: any) => res.data.data)
//         .catch((error: any) => error);
// }

// export function showAvatar() {
//     return http
//     .get('/api/v2/Users/ShowAvatar?imageName=StudentProfile123456789StudentStudentProfile@eliein.com',
//     {
//         responseType: "blob"
//       }
//       )
//     .then((res:any) => {
//         console.log(res)
//     //   const arr = new Uint8Array(res.data)
//       const url = window.URL.createObjectURL(
//         new Blob([res.data]
//         ,{type: "application/pdf"}))
//       var link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'rewsume.pdf');
//       document.body.appendChild(link);
//       link.click();
//     })
// }
