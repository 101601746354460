import { ClassNames } from "@emotion/react";
import { Box, Grid, Paper } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import { useSelector } from "react-redux";
import clsx from "clsx";

const useStyle = makeStyles((theme: any) => ({
    cardContainer: {},
    appBarStyle: {
        borderTopLeftRadius: theme.shape.borderRadius + 5,
        borderTopRightRadius: theme.shape.borderRadius + 5,
    },
}));

const CaptionTypoStyle = styled(Typography)((theme:any)=>({
    wordBreak:"break-word"
}))

function AboutTab() {
    const classes = useStyle();

    const user = useSelector((state: any) => state.global.user);

    const container = {
        show: {
            transition: {
                staggerChildren: 0.05,
            },
        },
    };

    return (
        <motion.div variants={container} initial="hidden" animate="show">
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Paper
                        elevation={3}
                        className={clsx(classes.cardContainer, "mb-7")}
                        sx={{ borderRadius: "5px" }}
                        component={motion.div}
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { delay: .3 } }}>
                        <AppBar position="static" elevation={0} className={classes.appBarStyle}>
                            <Toolbar className="px-8">
                                <Typography variant="subtitle1" className="px-7">
                                    اطلاعات کلی
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <CardContent>
                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 1.2 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    نام و نام‌خانوادگی
                                </Typography>
                                <CaptionTypoStyle>{user?.fullName}</CaptionTypoStyle>
                            </Box>

                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 1.4 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    نام کاربری
                                </Typography>
                                <CaptionTypoStyle>{user?.userName}</CaptionTypoStyle>
                            </Box>

                            {/* <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 1.8 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    جنسیت
                                </Typography>
                                <Typography>{general.gender}</Typography>
                            </Box> */}

                            {/* <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    تاریخ تولد
                                </Typography>
                                <CaptionTypoStyle>{general.birthday}</CaptionTypoStyle>
                            </Box> */}

                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 1.6 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    نام پدر
                                </Typography>
                                <CaptionTypoStyle>{user?.fatherName}</CaptionTypoStyle>
                            </Box>

                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 1.8 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    کد ملی
                                </Typography>
                                <CaptionTypoStyle>{user?.nationalCode}</CaptionTypoStyle>
                            </Box>

                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 2.0 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    ملیت
                                </Typography>
                                <CaptionTypoStyle>{user?.nationality}</CaptionTypoStyle>
                            </Box>

                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 2.2 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    ایمیل
                                </Typography>
                                <CaptionTypoStyle >{user?.email}</CaptionTypoStyle>
                            </Box>

                        </CardContent>
                    </Paper>

                    {/* <Paper
                        elevation={3}
                        className={classes.cardContainer}
                        sx={{ borderRadius: "5px" }}
                        component={motion.div}
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { delay: 1.5 } }}>
                        <AppBar position="static" elevation={0} className={classes.appBarStyle}>
                            <Toolbar className="px-8">
                                <Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-medium">
                                    آدرس و مشخصات
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <CardContent>
                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    آدرس
                                </Typography>
                                <CaptionTypoStyle></CaptionTypoStyle>
                            </Box>
                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 2.2 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    تلفن
                                </Typography>
                                <CaptionTypoStyle></CaptionTypoStyle>
                            </Box>

                            <Box
                                className="mb-12"
                                component={motion.div}
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1, transition: { delay: 2.4 } }}>
                                <Typography variant="subtitle2" className="mb-4">
                                    شماره موبایل
                                </Typography>
                                <CaptionTypoStyle></CaptionTypoStyle>
                            </Box>

                         
                        </CardContent>
                    </Paper> */}
                </Grid>

                <Grid item xs={12} md={4}>
                    {/* <Card component={motion.div} variants={item} className="w-full mb-32 rounded-16 shadow">
                        <AppBar position="static" elevation={0}>
                            <Toolbar className="px-8">
                                <Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-medium">
                                    Friends
                                </Typography>
                                <Button color="inherit" size="small">
                                    See 454 more
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <CardContent className="flex flex-wrap p-16">
                            {friends.map((friend: any) => (
                                <img key={friend.id} className="w-64 m-4 rounded-16 block" src={friend.avatar} alt={friend.name} />
                            ))}
                        </CardContent>
                    </Card> */}

                    {/* <Paper
                        elevation={3}
                        className={clsx(classes.cardContainer, "mb-7")}
                        sx={{ borderRadius: "5px" }}
                        component={motion.div}
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { delay: 1.5 } }}>
                        <AppBar position="static" elevation={0} className={classes.appBarStyle}>
                            <Toolbar className="px-8">
                                <Typography variant="subtitle1" className="px-7">
                                    آخرین ورود
                                </Typography> */}
                                {/* <Button color="inherit" size="small">
                                    See 6 more
                                </Button> */}
                            {/* </Toolbar>
                        </AppBar>
                        <CardContent className="p-0">
                            <List className="p-0">
                                {groups.map((group: any) => (
                                    <ListItem
                                        key={group.id}
                                        className="px-8"
                                        component={motion.div}
                                        initial={{ y: 50, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}> */}
                                        {/* <Avatar className="mx-8" alt={group.name}>
                                            {group.name[0]}
                                        </Avatar> */}
                                        {/* <ListItemText
                                            primary={
                                                <Box
                                                    className="flex"
                                                    component={motion.div}
                                                    initial={{ y: 50, opacity: 0 }}
                                                    animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}>
                                                    <CaptionTypoStyle className="font-medium" color="secondary" paragraph={false}>
                                                        {group.name}
                                                    </CaptionTypoStyle>

                                                    <CaptionTypoStyle className="mx-4 font-normal" paragraph={false}>
                                                        {group.category}
                                                    </CaptionTypoStyle>
                                                </Box>
                                            }
                                            secondary={group.members}
                                        /> */}
                                        {/* <ListItemSecondaryAction>
                                            <IconButton size="large">
                                                <Icon>more_vert</Icon>
                                            </IconButton>
                                        </ListItemSecondaryAction> */}
                                    {/* </ListItem>
                                // ))}
                            </List>
                        </CardContent>
                    </Paper> */}
                </Grid>
            </Grid>
        </motion.div>
    );
}

export { AboutTab };
