import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Card, CardActions, CardContent, Grid, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { grey } from "@mui/material/colors";
import { WhatsappShareButton } from "react-share";
import moment from "jalali-moment";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import { toast } from "react-toastify";
import ShareIcon from "@mui/icons-material/Share";

function Widget6({dashboardSchedule}:any) {
  const steps: string[] = [];
  dashboardSchedule.map((item: any) => {
    const date = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`
    steps.push(`${item.day}(${moment(date, "YYYY-M-D").locale("fa").format("YYYY/MM/DD")})`);
  });

  const theme = useTheme();
  const bgcGrey = grey[500];
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  let showCard: any[] = [];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCopyLinkClass = (classLink:string) => {
    navigator.clipboard.writeText(classLink);
    toast.info("لینک کلاس کپی شد");
  };

  dashboardSchedule?.map((item: any) => {
    if (item.day === "شنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "شنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14  , fontStyle:"italic"}} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic"}} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8  , fontStyle:"italic"}} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "یکشنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "یکشنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block"  , fontStyle:"italic"}} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8  , fontStyle:"italic"}} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "دوشنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "دوشنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "سه‌شنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "سه‌شنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "چهارشنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "چهارشنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "پنچ‌شنبه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "پنج‌شنبه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
    if (item.day === "جمعه") {
      if (steps[activeStep].slice(0,(steps[activeStep].indexOf("("))).trim() === "جمعه") {
        showCard = item?.classes?.map((clas: any) => {
          const classDate = `${item?.sessionDate?.year}/${item?.sessionDate?.month}/${item?.sessionDate?.day}`;
          const currentDate = moment(classDate, "YYYY-M-D").locale("fa").format("YYYY/MM/DD");

          const starTime = `${clas.startTime.hour}:${clas.startTime.minute}`;
          const endTime = `${clas.endTime.hour}:${clas.endTime.minute}`;

          const actions = [
            { icon: <FileCopyIcon onClick={() => handleCopyLinkClass(clas?.link)} />, name: "کپی" },
            // { icon: <SaveIcon />, name: "Save" },
            // { icon: <PrintIcon />, name: "Print" },
            {
              icon: (
                <WhatsappShareButton
                title={`لینک کلاس ${clas?.name}:`}
                  url={clas?.link}
                  separator={"\n"}
                >
                  <ShareIcon />
                </WhatsappShareButton>
              ),
              name: "اشتراک گذاری",
            },
          ];

          return (
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: 100,
                  backgroundColor: bgcGrey,
                  borderRadius: "3px",
                  height: "100% !important"
                }}
              >
                <CardContent>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    نام کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {clas?.name}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    تاریخ کلاس:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                    {currentDate}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary">
                    ساعت شروع:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {starTime}
                  </Typography>
                  <Typography sx={{ fontSize: 14 , display:"inline-block" , fontStyle:"italic" }} color="text.secondary" gutterBottom>
                    ساعت پایان:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: 18 , display:"inline-block" , px:1}}>
                    {endTime}
                  </Typography>
                  <Typography sx={{ mb: 1, mt: 0.8 , fontStyle:"italic" }} color="text.secondary">
                    لینک کلاس:
                  </Typography>
                  <Typography variant="body2">
                    <a href={clas?.link} rel="noreferrer" target="_blank" id="class-link-tag" style={{textDecorationLine:"underline" , textUnderlineOffset:"3px"}}>
                      {clas?.link}
                    </a>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box sx={{ height: 50, transform: "translateZ(0px)", flexGrow: 1 }}>
                    <SpeedDial
                      ariaLabel="SpeedDial basic example"
                      sx={{
                        position: "absolute",
                        bottom: 12,
                        right: 12,
                        "& .MuiFab-primary": { width: 44, height: 44 },
                      }}
                      icon={<SpeedDialIcon />}
                    >
                      {actions.map((action) => (
                        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
                      ))}
                    </SpeedDial>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        });
      }
    }
  });

  return (
    <Box sx={{ flexGrow: 1, boxShadow: 3, borderRadius: "15px" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
          pl: 2,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}>
        <Typography variant="subtitle2" sx={{fontSize:"16px"}}>{steps[activeStep]}</Typography>
      </Paper>
      {/* <Box sx={{ height: 255, maxWidth: 400, width: "100%", p: 2 }}>{steps[activeStep].description}</Box> */}
      <Box sx={{width: "100%", p: 2 }}>
        <Grid container>
          {showCard}
        </Grid>
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            بعدی
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            قبلی
          </Button>
        }
      />
    </Box>
  );
}

export { Widget6 };
