import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import Widget1 from "../widgets/widget1";
import { makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyle = makeStyles({
   title: {
      paddingRight: 10,
      fontWeight: "900 !important",
      opacity: "0.8"
   },
   caption: {
      paddingBottom: 15,
      paddingRight: 15,
      opacity: "0.7"
   }
});

function OwnerDashboard() {
   const classes = useStyle();
   const [thisYearData, setThisYearData] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
   const [yearAgoData, setYearAgoData] = useState([0,0,0,0,0,0,0,0,0,0,0,0])

   const tempData:any = {
      "title":"تعداد کلاس برگزار شده",
      "id": "widget1",
      "series": {
        // "2019": [
        //       {
        //           "name": "Sales",
        //           "data": [
        //               1.9,
        //               3,
        //               3.4,
        //               2.2,
        //               2.9,
        //               3.9,
        //               2.5,
        //               3.8,
        //               4.1,
        //               3.8,
        //               3.2,
        //               2.9
        //           ],
        //           "fill": "start"
        //       }
        //   ],
         
          "1401": [
              {
                  "name": "Sales",
                  "data":thisYearData,
                  "fill": "start"
              }
          ],
          "1400": [
            {
                "name": "Sales",
                "data": yearAgoData,
                "fill": "start"
            }
        ],
      },
      "options": {
          "chart": {
              "type": "area",
              "height": "100%",
              "background": "transparent",
              "toolbar": {
                  "show": false
              },
              "zoom": {
                  "enabled": false
              }
          },
          "theme": {
              "mode": "dark"
          },
          "dataLabels": {
              "enabled": false
          },
          "xaxis": {
              "categories": [
                  "فروردین",
                  "اردیبهشت",
                  "خرداد",
                  "تیر",
                  "مرداد",
                  "شهریور",
                  "مهر",
                  "ابان",
                  "اذر",
                  "دی",
                  "بهمن",
                  "اسفند"
              ],
              "tooltip": {
                  "enabled": false
              },
              "axisBorder": {
                  "show": false
              }
          },
          "yaxis": {
              "axisBorder": {
                  "show": false
              }
          },
          "markers": {
              "size": 3,
              "strokeWidth": 1.5,
              "strokeOpacity": 1,
              "strokeDashArray": 0,
              "fillOpacity": 1,
              "shape": "circle",
              "radius": 2,
              "hover": {
                  "size": 5
              }
          },
          "fill": {
              "type": "solid",
              "opacity": 0.7,
              "gradient": {
                  "shadeIntensity": 0.4,
                  "opacityFrom": 1,
                  "opacityTo": 0.5,
                  "stops": [
                      30,
                      100,
                      100
                  ]
              }
          },
          "grid": {
              "show": true,
              "strokeDashArray": 3,
              "position": "back",
              "xaxis": {
                  "lines": {
                      "show": true
                  }
              },
              "yaxis": {
                  "lines": {
                      "show": true
                  }
              },
              "padding": {
                  "top": 0,
                  "right": 0,
                  "bottom": 0,
                  "left": 0
              }
          },
          "stroke": {
              "show": true,
              "curve": "smooth",
              "lineCap": "butt",
              "width": 1.5,
              "dashArray": 0
          }
      }
}

   return (
      <>
         <Grid container  component={motion.div} initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.6 } }}>
            <Grid item xs={12}>
               <Typography component={motion.h6} className={classes.title}>
                  تعداد کلاس برگزار شده
               </Typography>
               <Typography component={motion.h6} className={classes.caption}>
                  در این قسمت میتوانید تعداد کلاس برگزار شده در قالب نمودار مشاهده کنید
               </Typography>
            </Grid>
            <Grid item xs={12}>
               <Widget1 ReceivedData={tempData} />
            </Grid>
         </Grid>
      </>
   );
}

export { OwnerDashboard };
