import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TableCell,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { useParams } from "react-router-dom";
import { ColumnPro } from "../../../../components/table-pro/table-pro.component";
import { sortDates, sortNumbers, sortStrings } from "../../../utils/sort.utils";
import moment from "jalali-moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, Settings } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { accessLevelName } from "utils/accessLevel.utils";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { changeUserActivation, deleteUser, getAllUsers, getRoles, putRoles, putUser } from "api/user/userApi";
import { getAllStudents } from "api/student/studentApi";
import AddBoxIcon from "@mui/icons-material/AddBox";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import { deleteLicense, getLicense, postLicense, putLicense } from "api/license/licenseApi";
import DeleteIcon from "@mui/icons-material/Delete";

const columns: any = [
  {
    id: "counter",
    label: "#",
    align: "center",
    minWidth: 15,
    state: 0,
    numeric: true,
    show: true,
  },
  {
    id: "fullName",
    label: "نام کامل",
    align: "left",
    minWidth: 60,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "fatherName",
    label: "نام پدر",
    align: "left",
    minWidth: 10,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },

  {
    id: "age",
    label: "سن",
    align: "left",
    minWidth: 10,
    state: 0,
    numeric: true,
    show: true,
    sort: sortNumbers,
  },
  {
    id: "userName",
    label: "نام کاربری",
    align: "left",
    minWidth: 60,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "gender",
    label: "جنسیت",
    align: "left",
    minWidth: 10,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "roles",
    label: "نقش‌ها",
    align: "left",
    minWidth: 10,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "isActive",
    label: "فعال",
    align: "left",
    minWidth: 5,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "nationalCode",
    label: "کد ملی",
    align: "center",
    minWidth: 60,
    state: 0,
    numeric: false,
    show: true,
    // sort: sortStrings,
  },
  {
    id: "nationality",
    label: "ملیت",
    align: "left",
    minWidth: 10,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },

  // {
  //   id: "numberOfSessions",
  //   label: "تعداد جلسات",
  //   align: "center",
  //   minWidth: 20,
  //   state: 0,
  //   numeric: true,
  //   show: true,
  //   sort: sortNumbers,
  // },
  // {
  //   id: "hasExam",
  //   label: "امتحان",
  //   align: "left",
  //   minWidth: 60,
  //   state: 0,
  //   numeric: false,
  //   show: true,
  //   sort: sortStrings,
  // },
  // {
  //   id: "description",
  //   label: "توضیحات دوره",
  //   align: "left",
  //   minWidth: 60,
  //   state: 0,
  //   numeric: false,
  //   show: true,
  //   sort: sortStrings,
  // },
  // {
  //   id: "createdAt",
  //   label: "تاریخ ثبت",
  //   align: "center",
  //   minWidth: 30,
  //   state: 0,
  //   numeric: true,
  //   show: true,
  //   sort: sortDates,
  //   format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  // },
  {
    id: "action",
    label: "عملیات",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: true,
    show: true,
    // sort: sortDates,
  },
];

interface CellProps extends TableCellProps {
  active: boolean;
}

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
  backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function AdminLicenseList({ startDate, endDate }: any) {
  const [rows, setRows] = useState<any>([]);
  const [edit, setEdit] = useState(null);
  const [add, setAdd] = useState(null);
  const [roleEdit, setRoleEdit] = useState(null);
  const [isActiveEdit, setIsActiveEdit] = useState(null);
  const [remove, setRemove] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [rolesItem, setRolesItems] = useState([]);
  const [editLicensesList, setEditLicensesList] = useState([]);
  const [licenseInputList, setLicenseInputList] = useState([{ value: "", description: "" }]);
  const [validateAddFrom, setValidateAddFrom] = useState(false);
  const [validateEditFrom, setValidateEditFrom] = useState(false);
  const [allowSubmitAddFrom, setAllowSubmitAddFrom] = useState(false);
  const [allowSubmitEditFrom, setAllowSubmitEditFrom] = useState(false);

  const refImage = useRef<HTMLInputElement>(null);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    getAllNeedData();
  }, [startDate, endDate]);

  const getAllNeedData = async () => {
    const resStd: any = await getAllStudents();
    if (!(resStd instanceof Error)) {
      setRows(resStd);
    } else {
      toast.error("دریافت داده‌ها با مشکل مواجه شد");
    }
  };

  const validation = Yup.object({
    license: Yup.string().nullable().required("نباید خالی باشد"),
    description: Yup.string().nullable().required("نباید خالی باشد"),
  });

  const addFormik = useFormik({
    initialValues: {
      license: "",
      description: "",
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      if (add) {
        // const res: any = await postLicense({ License: values.license, UserId: add.id });
        // if (!(res instanceof Error)) {
        //   resetForm();
        //   setAdd(null);
        //   toast.success("ثبت لایسنس کاربر با موفقیت انجام شد");
        // } else {
        //   toast.error("ثبت اطلاعات با خطا مواجه شد");
        // }
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      license: "",
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      if (edit) {
        // const res: any = await putLicense({ License: values.license, UserId: edit.id });
        // if (!(res instanceof Error)) {
        //   setEdit(null);
        //   toast.success("ویرایش لایسنس کاربر با موفقیت انجام شد");
        // } else {
        //   toast.error("ثبت اطلاعات با خطا مواجه شد");
        // }
      }
    },
  });

  // useEffect(() => {
  //   if (edit) {
  //     editFormik.setValues({
  //       license: editLicensesList,
  //     });
  //   }
  // }, [edit]);

  useEffect(() => {
    getAllNeedData();
  }, []);

  const handleRemoveLicense = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await deleteLicense(remove);
    if (!(res instanceof Error)) {
      setRemove(null);
      toast.success("لایسنس با موفقیت حذف شد");
    } else {
      toast.error("حذف لایسنس با خطا مواجه شد");
    }
  };

  const itemsOfAction = [
    {
      title: (
        <Button color="inherit" disabled={editLicensesList?.length !== 0 ? true : false}>
          <AddBoxIcon style={{ fontSize: "1.4rem" }} />
          &nbsp; درج لایسنس دانشجو
        </Button>
      ),
      onClick: async (user: any) =>
        await new Promise((resolve, reject) => {
          if (!(editLicensesList?.length !== 0)) {
            setAdd(user);
          }
        }),
    },
    {
      title: (
        <Button color="inherit" disabled={editLicensesList?.length === 0 ? true : false}>
          <Edit style={{ fontSize: "1.5rem" }} />
          &nbsp; ویرایش لایسنس دانشجو
        </Button>
      ),
      onClick: async (user: any) =>
        await new Promise((resolve, reject) => {
          if (!(editLicensesList?.length === 0)) {
            setEdit(user);
          }
        }),
    },
    // {
    //   title: (
    //     <Button color="inherit" disabled={editLicensesList?.length === 0 ? true : false}>
    //       <KeyOffIcon style={{ fontSize: "1.5rem" }} />
    //       &nbsp; حذف لایسنس دانشجو
    //     </Button>
    //   ),
    //   onClick: async (user: any) =>
    //     await new Promise((resolve, reject) => {
    //       if (!(editLicensesList?.length === 0)) {
    //         setRemove(user.id);
    //       }
    //     }),
    // },
  ];

  useEffect(() => {
    if (!!add === false) {
      setLicenseInputList([{ value: "", description: "" }]);
      setValidateAddFrom(false);
    }
  }, [add]);

  useEffect(() => {
    if (!!edit === false) {
      setEditLicensesList([{ value: "", description: "" }]);
      setValidateEditFrom(false);
    }
  }, [edit]);

  //Get License List for update them

  const handleLicenseAction = async (row: any) => {
    const res: any = await getLicense(row.id);
    if (!(res instanceof Error)) {
      setEditLicensesList(res);
    } else {
      setEditLicensesList([]);
      toast.error("دریافت اطلاعات با خطا مواجه شد");
    }
  };

  //handle Add or Remove the license & description field dynamically for Add

  const handleLicenseInputListChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...licenseInputList];
    list[index][name] = value;
    setLicenseInputList(list);
  };

  const handleLicenseInputListRemove = (index: any) => {
    const list = [...licenseInputList];
    list.splice(index, 1);
    setLicenseInputList(list);
  };

  const handleLicenseInputListAdd = () => {
    setLicenseInputList([...licenseInputList, { value: "", description: "" }]);
  };

  //End

  //handle Add or Remove the license & description field dynamically for Edit

  const handleEditLicenseInputListChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...editLicensesList];
    list[index][name] = value;
    setEditLicensesList(list);
  };

  const handleEditLicenseInputListRemove = (index: any) => {
    const list = [...editLicensesList];
    list.splice(index, 1);
    setEditLicensesList(list);
  };

  const handleEditLicenseInputListAdd = () => {
    setEditLicensesList([...editLicensesList, { value: "", description: "" }]);
  };

  //End

  //Start Add Submit Logic

  const handleAddSubmit = async (e: any) => {
    e.preventDefault();
    setValidateAddFrom(true);

    const temp = licenseInputList.find((item: any) => {
      if (!(!!item.value && !!item.description)) {
        return true;
      }
    });
    if (!temp) {
      setAllowSubmitAddFrom(true);
    }
  };

  const handleFinalAddSubmit = async () => {
    const res: any = await postLicense(licenseInputList, add.id);
    if (!(res instanceof Error)) {
      toast.success("درج لایسنس با موفقیت انجام شد");
      setAdd(false);
      setAllowSubmitAddFrom(false);
    } else {
      setAllowSubmitAddFrom(false);
      toast.error("ثبت اطلاعات با خطا مواجه شد");
    }
  };

  useEffect(() => {
    if (allowSubmitAddFrom) {
      handleFinalAddSubmit();
    }
  }, [allowSubmitAddFrom]);

  //End Add Submit Logic

  // Start Edit Submit Logic

  const handleEditSubmit = async (e: any) => {
    e.preventDefault();
    setValidateEditFrom(true);

    const temp = editLicensesList.find((item: any) => {
      if (!(!!item.value && !!item.description)) {
        return true;
      }
    });
    if (!temp) {
      setAllowSubmitEditFrom(true);
    }
  };

  const handleFinalEditSubmit = async () => {
    const res: any = await putLicense(editLicensesList, edit.id);
    if (!(res instanceof Error)) {
      toast.success("ویرایش لایسنس با موفقیت انجام شد");
      setEdit(false);
      setAllowSubmitEditFrom(false);
    } else {
      setAllowSubmitEditFrom(false);
      toast.error("ثبت اطلاعات با خطا مواجه شد");
    }
  };

  useEffect(() => {
    if (allowSubmitEditFrom) {
      handleFinalEditSubmit();
    }
  }, [allowSubmitEditFrom]);

  // End Edit Submit Logic


  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* <ListInfo /> */}
        <Grid item xs={12} style={{ width: 10 }}>
          <Paper>
            <TableProIpa
              columns={columns}
              bodyFunc={(column: any, row: any, index: any) => {
                const head = column.id;
                const value = row[column.id];
                if (head === "action") {
                  return (
                    <TableCellStyled active={true} key={column.id + row.id} align={column.align} onClick={() => handleLicenseAction(row)}>
                      <MenuActionTable menuId={row.id} items={itemsOfAction} icon={<Settings />} user={row} />
                    </TableCellStyled>
                  );
                } else if (head === "isActive") {
                  return (
                    <TableCell key={column.id + row.id} align={column.align}>
                      <Button
                        variant={"text"}
                        color={value ? "success" : "error"}
                        style={{
                          width: column.minWidth,
                        }}>
                        {value ? "هست" : "نیست"}
                      </Button>
                    </TableCell>
                  );
                } else if (head === "gender") {
                  return (
                    <TableCell key={column.id + row.id} align={column.align}>
                      <Button
                        variant={"text"}
                        color={"inherit"}
                        style={{
                          width: column.minWidth,
                        }}>
                        {value === "Male" ? "مرد" : "زن"}
                      </Button>
                    </TableCell>
                  );
                } else if (head === "roles") {
                  return (
                    <TableCell key={column.id + row.id} align={column.align}>
                      <Button
                        variant={"text"}
                        color={"inherit"}
                        style={{
                          width: column.minWidth,
                        }}>
                        {value?.map((item: any) => {
                          switch (item) {
                            case "Owner":
                              return "مالک \n";
                            case "Student":
                              return "دانشجو \n";
                            case "Expert":
                              return "کارشناس \n";
                            case "Teacher":
                              return "معلم \n";
                            case "Admin":
                              return "ادمین \n";
                          }
                        })}
                      </Button>
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.format && value ? column.format(value) : value}
                  </TableCell>
                );
              }}
              rows={rows ?? []}
            />
          </Paper>
        </Grid>
      </Grid>
      {add ? (
        <ModalIpa
          open={Boolean(add)}
          title={`درج لایسنس ${add?.fullName}`}
          onClose={() => {
            setAdd(null);
            addFormik.handleReset(1);
          }}>
          <form noValidate onSubmit={handleAddSubmit}>
            <Grid container spacing={1}>
              {licenseInputList.map((x, i) => {
                return (
                  <>
                    <Grid
                      item
                      md={licenseInputList.length !== 1 ? 5.5 : 6}
                      sm={licenseInputList.length !== 1 ? 5.2 : 6}
                      xs={licenseInputList.length !== 1 ? 4.2 : 6}>
                      <TextField
                        name="value"
                        variant="outlined"
                        label={"لایسنس"}
                        fullWidth
                        type="text"
                        rows={2}
                        maxRows={5}
                        multiline
                        required
                        value={x.value}
                        onChange={(e: any) => handleLicenseInputListChange(e, i)}
                        error={Boolean(!x.value) && validateAddFrom}
                        helperText={!x.value && validateAddFrom && "نباید خالی باشد"}
                      />
                    </Grid>
                    {licenseInputList.length !== 1 && (
                      <Grid item xs={1} sx={{ maxWidth: "52px !important" }}>
                        <IconButton onClick={() => handleLicenseInputListRemove(i)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                    <Grid
                      item
                      md={licenseInputList.length !== 1 ? 5.5 : 6}
                      sm={licenseInputList.length !== 1 ? 5.2 : 6}
                      xs={licenseInputList.length !== 1 ? 4.2 : 6}>
                      <TextField
                        name="description"
                        variant="outlined"
                        label={"توضیحات"}
                        fullWidth
                        type="text"
                        rows={2}
                        maxRows={5}
                        multiline
                        required
                        value={x.description}
                        onChange={(e: any) => handleLicenseInputListChange(e, i)}
                        error={Boolean(!x.description) && validateAddFrom}
                        helperText={!x.description && validateAddFrom && "نباید خالی باشد"}
                      />
                    </Grid>
                    {licenseInputList.length - 1 === i && (
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Button variant="outlined" onClick={handleLicenseInputListAdd}>
                          اضافه کردن
                        </Button>
                      </Grid>
                    )}
                  </>
                );
              })}

              <Grid item xs={12}>
                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                  ثبت لایسنس
                </Button>
              </Grid>
            </Grid>
          </form>
        </ModalIpa>
      ) : null}
      {edit ? (
        <ModalIpa
          open={Boolean(edit)}
          title={`ویرایش لایسنس ${edit?.fullName}`}
          onClose={() => {
            setEdit(null);
            editFormik.handleReset(1);
          }}>
          <form noValidate onSubmit={handleEditSubmit}>
            <Grid container spacing={1}>
              {editLicensesList?.map((x: any, i: any) => {
                return (
                  <>
                    <Grid
                      item
                      md={editLicensesList.length !== 1 ? 5.5 : 6}
                      sm={editLicensesList.length !== 1 ? 5.2 : 6}
                      xs={editLicensesList.length !== 1 ? 4.2 : 6}>
                      <TextField
                        name="value"
                        variant="outlined"
                        label={"لایسنس"}
                        fullWidth
                        type="text"
                        rows={2}
                        maxRows={5}
                        multiline
                        required
                        value={x.value}
                        onChange={(e: any) => handleEditLicenseInputListChange(e, i)}
                        error={Boolean(!x.value) && validateEditFrom}
                        helperText={!x.value && validateEditFrom && "نباید خالی باشد"}
                      />
                    </Grid>
                    {editLicensesList.length !== 1 && (
                      <Grid item xs={1} sx={{ maxWidth: "52px !important" }}>
                        <IconButton onClick={() => handleEditLicenseInputListRemove(i)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                    <Grid
                      item
                      md={editLicensesList.length !== 1 ? 5.5 : 6}
                      sm={editLicensesList.length !== 1 ? 5.2 : 6}
                      xs={editLicensesList.length !== 1 ? 4.2 : 6}>
                      <TextField
                        name="description"
                        variant="outlined"
                        label={"توضیحات"}
                        fullWidth
                        type="text"
                        rows={2}
                        maxRows={5}
                        multiline
                        required
                        value={x.description}
                        onChange={(e: any) => handleEditLicenseInputListChange(e, i)}
                        error={Boolean(!x.description) && validateEditFrom}
                        helperText={!x.description && validateEditFrom && "نباید خالی باشد"}
                      />
                    </Grid>
                    {editLicensesList.length - 1 === i && (
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <Button variant="outlined" onClick={handleEditLicenseInputListAdd}>
                          اضافه کردن
                        </Button>
                      </Grid>
                    )}
                  </>
                );
              })}

              <Grid item xs={12}>
                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                  ویرایش لایسنس
                </Button>
              </Grid>
            </Grid>
          </form>
        </ModalIpa>
      ) : null}
      {remove ? (
        <ModalIpa open={Boolean(remove)} title={`آیا از حذف لایسنس مطمئن هستید؟`} onClose={() => setRemove(null)}>
          <form onSubmit={handleRemoveLicense}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ButtonsModal
                  textSubmit={"تایید"}
                  textClose={"انصراف"}
                  onClose={() => {
                    setRemove(false);
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </ModalIpa>
      ) : null}
    </>
  );
}

export { AdminLicenseList };
