import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import {faIR} from "@mui/material/locale";
import { useSelector } from 'react-redux';




function Theme ({children}:any){

  const dark = useSelector((state:any)=> state.global.dark)
  const fontSize = useSelector((state:any)=> state.global.fontSize)
  const borderRadius = useSelector((state:any)=> state.global.borderRadius)
  const fontFamily = useSelector((state:any)=> state.global.fontFamily)
  
    const theme = createTheme({
        shape: {
          borderRadius
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1330,
            xl: 1600,
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
                body: {
                  '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
                    borderRadius,
                    backgroundColor: dark ? `rgb(107, 107, 107)` : '#fafafa',
                    minHeight: 24,
                    border: `3px solid ${dark ? 'rgb(43, 43, 43)' : '#afafaf'}`,
                  },
                  '&::-webkit-scrollbar, & ::-webkit-scrollbar': {
                    backgroundColor: dark ? `rgb(43, 43, 43)` : 'rgba(0, 0, 0, 0.25)'
                  }
                }
            }
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                borderRadius
              }
            }
          }
        },
        direction: 'rtl',
        typography: {
          fontSize,
          fontFamily: [fontFamily].join(',')
        },
        palette: {
          primary: {
            main: dark ? '#007ACC' : '#0FA3B1',
            light: dark ? '#BEB8EB' : '#92dfef',
            dark: dark ? '#091540' : '#252F3E',
            // dark: dark ? '#590D10' : '#1B2330',
            // main: dark ? '#99171B' : '#0b577a',
            // light: dark ? '#E62229' : '##6B818C',
            // dark: dark ? '#590D10' : '#022F44',
            // contrastText: dark ? 'rgba(0,0,0,.87)' : undefined
          },
          secondary: {
            main: dark ? '#007ACC' : '#92dfef',
            // main: dark ? '#007ACC' : '#dab48a',
            // main: dark ? '#007ACC' : '#0b767a',
            light: dark ? '#007ACC' : '#c5fdff',
            dark: dark ? '#007ACC' : '#252F3E',
            contrastText: dark ? 'rgba(0,0,0,.87)' : undefined
          },
          // text: {
          //   primary: dark ? '#fff' : 'rgba(0,0,0,.87)',
          //   secondary: dark ? 'rgba(255,255,255,.7)' : 'rgba(0,0,0,.6)',
          //   disabled: dark ? 'rgba(255,255,255,.5)' : 'rgba(0,0,0,.38)',
          //   // icon: dark ? 'rgba(255,255,255,.5)' : '',
          // },
          background: {
            default: dark ? 'rgb(55, 55, 55)' : 'rgba(55, 119, 188,0.050980392156862744)'
          },
          mode: dark ? 'dark' : 'light'
        },
      }, faIR)

    return (
        <ThemeProvider theme={theme} >
            <CssBaseline/>
            {children}
        </ThemeProvider>
    );
};

export {Theme}

//here was old redux