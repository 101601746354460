export const daysItem = [
   {
      id: 1,
      name: "شنبه",
   },
   {
      id: 2,
      name: "یکشنبه",
   },
   {
      id: 3,
      name: "دوشنبه",
   },
   {
      id: 4,
      name: "سه‌شنبه",
   },
   {
      id: 5,
      name: "چهار‌شنبه",
   },
   {
      id: 6,
      name: "پنج‌شنبه",
   },
   {
      id: 7,
      name: "جمعه",
   },
];
