import http from "../../utils/http.utils";

type Id = string;
enum Gender {
    Male,
    Female,
}


export interface CreateClassInformationProps {
    courseId: number;
    name: string;
    classLink: string;
    description: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    days: string;
    // complated: boolean;
    examsDateTime: string[];
}

export function CreateClassInformation(data: CreateClassInformationProps) {
    return http
        .post("/api/v2/ClassRooms/CreateClassInformation", data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function CreatePaymentPlans(classroomId: Id) {
    return http
        .post(`/api/v2/ClassRooms/CreatePaymentPlans/${classroomId}`)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function CreatePaymentPlan(classroomId: Id) {
    return http
        .post(`/api/v2/ClassRooms/CreatePaymentPlan/${classroomId}`)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export interface CompleteClassRoomProps {
    studentsId: number[];
    teacherId: number;
}

export function CompleteClassRoom(data: CompleteClassRoomProps, classroomId: Id) {
    return http
        .post(`/api/v2/ClassRooms/ComplateClassRoom/${classroomId}`, data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function getAllClassrooms() {
    return http
        .get("/api/v2/ClassRooms")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export interface putClassInformation {
        courseId: number,
        name: string,
        classLink: string,
        description: string,
        startDate:string,
        endDate: string,
        startTime: string,
        endTime: string,
        days: object[]
        complated: boolean,
        examsDateTime: string[]
}

export function EditClassInformation(data:putClassInformation,classRoomId:Id) {
    return http
        .put(`/api/v2/ClassRooms/EditClassInformation/${classRoomId}`, data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export interface putClassMembers {
    TeacherId:number,
    StudentsId:number[]
}


export function EditClassroomMembers(data:putClassMembers,classRoomId:Id) {
    return http
        .put(`/api/v2/ClassRooms/EditClassroomMembers/${classRoomId}`, data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}


export function GetUserClasses(userId:number) {
    return http
        .get(`/api/v2/ClassRooms/GetUserClasses/${userId}`)
        .then((res: any) => res.data.data)
        .catch((error: any) => error);
}