import { Typography, Paper, IconButton, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
    container: {
        // background: " linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(33,150,243,1) 0%, rgba(255,255,255,0) 100%)",
        // background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, ${props.widget.color} 0%, rgba(255,255,255,0) 100%)`,
    },
}));

function Widget2(props: any) {
  const classes = useStyles();

    return (
        <Paper elevation={3} style={{ borderRadius: "10px",
        background: `linear-gradient(180deg, rgba(255,255,255,100) 0%, ${props.widget.bgColor} 0%, rgba(255,255,255,0) 100%)`,
        height: "100%"
        }} className={classes.container}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: "4px", pt: "8px" }}>
                <Typography sx={{ px: 2 }}>{props.widget.title}</Typography>
                {/* <IconButton>
                    <MoreVertIcon />
                </IconButton> */}
            </Box>
            <Box sx={{ textAlign: "center", py: 6 }}>
                <Typography className="tracking-tighter" variant={"h2"} sx={{ fontWeight: "bold", color: `${props.widget.color}` }}>
                    {props.widget.data.count}
                </Typography>
                <Typography sx={{ color: `${props.widget.color}` }}>{props.widget.data.name}</Typography>
            </Box>
            <Box sx={{ px: 3, pb: 2 }}>
                <Typography sx={{ display: "inline-block" }}>{props.widget.data?.extra?.name}</Typography>
                <Typography sx={{ display: "inline-block", px: 1 , fontWeight:"bold" }}>{props.widget.data?.extra?.count}</Typography>
            </Box>
        </Paper>
    );
}

export { Widget2 };
