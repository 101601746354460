import {useRef} from "react"
import { Grid, Typography, Button, Box, Tabs, Tab, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import tempBg from "asset/images/morain-lake.jpg";
import { motion } from "framer-motion";
import Avatar from "@mui/material/Avatar";
import Avatar1 from "../../asset/images/Avatar1.jpg";
import { alpha, styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useState } from "react";
import { AboutTab } from "./tabs/aboutTab";
import {PhotosVideosTab} from "./tabs/photosVideosTab"
import { EditDetails } from "./tabs/editDetails";
import {saveAs} from "file-saver"
import { GrPowerCycle } from 'react-icons/gr';
import { ImageModal } from "components/image-modal/imageModal.component";
import { uploadUserImage } from "api/user/userApi";
import { toast } from "react-toastify";
import { PhotoCamera } from "@mui/icons-material";

const useStyle = makeStyles((theme: any) => ({
    topBg: {
        width: "119%",
        maxWidth: "120%",
        height: "400px",
        marginRight: "-24px",
        marginTop: "-24px",
        objectFit: "fill",
    },
    tabs: {
        "& .MuiTabs-indicator": {
            //   display: "none",
            //   backgroundColor: "orange",
            color: "red !important",
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            width: "42px",
            height: "50px",
            borderRadius: 99,
        },
    },
    nameContainer: {},
}));

function UserProfile() {
    const classes = useStyle();
    const [selectedTab, setSelectedTab] = useState(0);
    const [showImage, setShowImage] = useState(false);
    const refImage = useRef<HTMLInputElement>(null);
    const refImageDesc = useRef<HTMLInputElement>(null);

    function handleTabChange(event: any, value: any) {
        setSelectedTab(value);
    }
    const user = useSelector((state: any) => state.global.user);

    const handleUploadImage =async (e: any) => {
        e.preventDefault();
        console.log(refImage);
        console.log(refImageDesc);
        if (refImage?.current?.files[0]) {
            const res: any = await uploadUserImage({
                imageName: "test",
                imageFile: refImage.current.files[0],
                imageDescription: refImageDesc?.current?.value,
            }, user?.userId);
            if (!(res instanceof Error)) {
                setShowImage(false);
                toast.success("آپلود عکس با موفقیت انجام شد");
            } else {
                toast.error("آپلود عکس با خطا مواجه شد");
            }
            setShowImage(false);
        }
    };
    


    // const url = window.URL.createObjectURL(
    //     new Blob([user?.profileImage?.picture]))
    //   var link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'test.png');
    //   document.body.appendChild(link);
    //   link.click();

    // var blob = new Blob([user.profileImage.picture], {type: "application/octet-stream"});
    // var fileName = "myFileName.png";
    // saveAs(blob, fileName);

    // let blob = new Blob(new Uint8Array());
    // let file = reader.readAsArrayBuffer(blob);
    
    let image = 'data:image/jpeg;base64,' + user?.profileImage?.picture;

    return (
        <>
            <header>
                <Grid container>
                    <Grid item xs={12}>
                        <img className={classes.topBg} src={tempBg} alt="profileBgc" loading="lazy" />
                    </Grid>
                    <Grid item xs={8.5} sm={7} md={7} lg={3} sx={{position:"relative"}}>
                        <Avatar sx={{ width: "150px", height: "150px", marginLeft: "auto", marginTop: "-72px", border: "5.8px solid white" }} src={image} />
                        <button style={{position:"absolute" , left:"8px" , bottom:"20px" , fontSize:"25px"}} onClick={() => setShowImage(true)}>
                        <GrPowerCycle style={{fontSize:"25px"}}/>
                        </button>
                    </Grid>
                    <Grid item xs={9} sm={7} md={7} lg={3} className={classes.nameContainer}>
                        <Typography
                            variant="h4"
                            color="inherit"
                            sx={{
                                textAlign: {
                                    xs: "end",
                                    lg: "start !important",
                                }
                            }}>
                            {user?.fullName}
                        </Typography>
                    </Grid>
                </Grid>
            </header>
            <main>
                <Grid container>
                    <Grid item xs={12}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="scrollable"
                            scrollButtons={false}
                            sx={{
                                px: {
                                    xl: "17%",
                                    lg: "13%",
                                    xs: 0,
                                },
                                pt: 3,
                            }}
                            className={classes.tabs}>
                            <Tab label="اطلاعات شخصی" />
                            <Tab label="تغییر مشخصات" />
                            {/* <Tab label="Photos & Videos" /> */}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="p-16 sm:p-24">
                            {selectedTab === 0 && <AboutTab />}
                            {selectedTab === 1 && <EditDetails />}
                            {/* {selectedTab === 2 && <PhotosVideosTab />} */}
                        </div>
                    </Grid>
                </Grid>
            </main>
            {showImage ? (
                <ImageModal
                    open={showImage}
                    onClose={() => {
                        setShowImage(false);
                    }}
                    title="آپلود عکس پروفایل"
                    // allowAccess={!!addFormik.values.userName && !!addFormik.values.nationalCode && !!addFormik.values.email}
                >
                    <form action="" onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleUploadImage(e)}>
                        <Grid container alignItems={"center"} justifyContent={"center"} spacing={4}>
                            <Grid item xs={12}>
                                <Button color="primary" variant="contained" aria-label="upload picture" component="label" endIcon={<PhotoCamera />}>
                                    <input hidden accept="image/*" type="file" ref={refImage} />
                                    تصویر
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id={"description "} variant="outlined" label={"توضیحات"} fullWidth type="text" rows={3} maxRows={5} multiline inputRef={refImageDesc} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    آپلود تصویر
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ImageModal>
            ) : null}
        </>
    );
}

export { UserProfile };
