import {useState} from "react";
import {Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput , useTheme} from "@mui/material";
import {LocalPhone, Visibility, VisibilityOff} from "@mui/icons-material";
import {useFormik} from "formik";
import * as yup from "yup";
import {toast} from "react-toastify";
// import {ForgetForm} from "./forget.component";
import {connect, useDispatch} from "react-redux";
import { actionLogin } from "redux/globalSlice";
import { useNavigate } from "react-router-dom";
import classes from "./inputAutoFillColor.module.scss"
import { login } from "api/user/userApi";
import { ForgetForm } from "./forget.component";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function LoginForm() {
  const [showPassword, setShowPassword] = useState(false)
  const [forget, setForget] = useState(false)
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate()


  const validation = yup.object({
    password: yup
        .string()
        .required('نباید خالی باشد'),
    username: yup
        .string()
        .required('نباید خالی باشد')
        // .test("شماره موبایل باید 11 رقمی باشد", "شماره موبایل باید 11 رقمی باشد", function (value = '') {
        //   return value.toString().length === 11;
        // }),
  })
  const loginFormik = useFormik({
    initialValues: ({
      username: '',
      password: ''
    }),
    validationSchema: validation,
    onSubmit: async ({username, password}) => {
      const res = await login({username, password, grant_type: 'Password'})
      if (!(res instanceof Error)) {
        dispatch(actionLogin(res))
        navigate("/", {replace:true})
      } else {
        toast.error('نام کاربری و رمز عبور صحیح نمی باشد')
      }
    }
  })

  return (
      <>
        {forget ?
            <ForgetForm backToLogin={() => setForget(false)}/>
            : 
            <form noValidate onSubmit={loginFormik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="username"sx={{color:theme.palette.grey[400]}}>نام کاربری</InputLabel>
                    <OutlinedInput
                    sx={{bgc:"transparent" , input:{color:theme.palette.common.white}, borderRadius:theme.shape.borderRadius + "1px"}}
                        required
                        id="username"
                        fullWidth
                        value={loginFormik.values.username}
                        onChange={loginFormik.handleChange}
                        error={loginFormik.touched.username && Boolean(loginFormik.errors.username)}
                        // helperText={(loginFormik.touched.password && loginFormik.errors.password)}
                        endAdornment={
                          <InputAdornment position="end">
                            {/* <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                disabled
                            > */}
                              <AccountCircleIcon />
                            {/* </IconButton> */}
                          </InputAdornment>
                        }
                        label="نام کاربری"
                        className={classes.root}
                        
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="password" sx={{color:theme.palette.grey[400]}}>رمز ورود</InputLabel>
                    <OutlinedInput
                    sx={{bgc:"transparent !important" , input:{color:theme.palette.common.white},
                   borderRadius:theme.shape.borderRadius + "1px"
                  }}
                        fullWidth
                        required
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={loginFormik.values.password}
                        onChange={loginFormik.handleChange}
                        error={loginFormik.touched.password && Boolean(loginFormik.errors.password)}
                        // helperText={(loginFormik.touched.password && loginFormik.errors.password)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((v) => !v)}
                                edge="end"
                            >
                              {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="رمز ورود"
                        className={classes.root}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button sx={{color:theme.palette.grey[400]}} variant={'text'} size={'small'} onClick={() => setForget(true)}>
                    فراموشی رمز عبور
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button size={'large'} fullWidth color={'primary'} variant={'contained'} type={'submit'} 
                   sx={{borderRadius:theme.shape.borderRadius + "1px"}}>
                    ورود
                  </Button>
                </Grid>
              </Grid>
            </form>}
      </>
  )

}


export {LoginForm};
