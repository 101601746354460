import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { actionExit } from "redux/globalSlice";
import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

const TokenVerify = ({ children }: any) => {
    const expireTime = useSelector((state: any) => state.global?.user?.expires_in);
    const location = useLocation();

    const dispatch = useDispatch();

    const dateNowPare = Date.now();

    //    if(expireTime){

    //     if(expireTimeParse <= dateNowPare){
    //        dispatch(actionExit(""))
    //        toast.warn("اعتبار حساب شما منقضی شده است.لطفا دوباره وارد شوید", { autoClose: 4500 })
    //     }
    //  }

    // return expireTime <= dateNowPare ? dispatch(actionExit("")) : (
    //     <Outlet/>
    // )
    // return (
    //     <Outlet/>
    // )

    // console.log(Date.now())
    // console.log(Date.now())

    if (expireTime) {
        if (expireTime < (Math.floor(dateNowPare)/1000) ) {
            toast.warn("اعتبار حساب شما منقضی شده است.لطفا دوباره وارد شوید", { autoClose: 4500 });
            dispatch(actionExit(""));
            return null;
        } else {
            return <Outlet />;
        }
    } else {
        return <Outlet />;
    }
};

export { TokenVerify };
