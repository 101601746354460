import { IconButton, Menu, MenuItem } from "@mui/material";
import {
   Brightness4,
   Brightness7,
   ExitToApp,
   MoreVert,
} from "@mui/icons-material";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { actionExit, toggleDark } from "redux/globalSlice";
// import { SET_LOGIN, TOGGLE_DARK } from "../../../redux/types/type";

// interface MenuProps {
//    dark?: boolean;
//    toggleDark?: Function;
//    exit?: Function;
// }

function MenuHeader() {
   // const { dark, toggleDark, exit } = props;
   const dark = useSelector((state:any) => state.global.dark);
   const dispatch = useDispatch();
   const [open, setOpen] = React.useState<null | HTMLElement>(null);

   return (
      <>
         <IconButton
            aria-label="show more"
            aria-controls={"menu-setting-header"}
            aria-haspopup="true"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
               setOpen(e.currentTarget)
            }
            color="inherit"
            size="large"
            sx={{pt:"2px !important"}}>
            <MoreVert />
         </IconButton>

         <Menu
            id={"menu-setting-header"}
            onClose={() => {
               setOpen(null);
            }}
            anchorEl={open}
            // getContentAnchorEl={null}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(open)}>

<MenuItem onClick={() => dispatch(toggleDark(""))}>
        <IconButton color="inherit" size="large">
          {dark ? <Brightness4/> : <Brightness7/>}
        </IconButton>
        <p style={{paddingLeft: 15}}>{dark ? 'تاریک' : 'روشن'}</p>
      </MenuItem>
      <MenuItem onClick={() => dispatch(actionExit(""))}>
        <IconButton color="inherit" size="large">
          <ExitToApp/>
        </IconButton>
        <p style={{paddingLeft: 15}}>خروج</p>
      </MenuItem>
            </Menu>
      </>
   );
}

export {MenuHeader};
