import {Box, Divider, IconButton, List, Typography, useTheme} from "@mui/material";
import React, { useEffect  , useState} from "react";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import './menu.layout.scss'
import {items} from "../../../utils/itemsMenu.utils";
import {ItemMenu} from "./components/item.menu.layout";
import {useLocation } from 'react-router-dom'
// import logo from 'asset/images/logo.png'
import { styled,CSSObject,Theme} from '@mui/material/styles';
import MuiDrawer from "@mui/material/Drawer";
import {connect} from "react-redux";
import logo from "../../../asset/images/Logo-Eliyeen.png"

const drawerWidth = 240;

const openedMixin =  (theme : Theme) :CSSObject =>({
  width: drawerWidth,
  transition: theme.transitions.create("width",{
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  
})

const closedMixin = (theme:Theme): CSSObject =>({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(0)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
      },
});

const Drawer = styled(MuiDrawer , {shouldForwardProp: (prop) => prop !== "open" })(
    ({theme, open}) =>({
        width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme), 
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
);

const StyledToolbar = styled('div')(({theme})=>({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }))
  
  interface MenuProps {
    open?: boolean,
    onClose?: Function,
    isAdmin?:boolean
  }
 
 function MenuLayout(props:MenuProps){
     const theme = useTheme();

     const {open ,onClose ,isAdmin} =props;
     const location = useLocation().pathname;
      const [primary , setPrimary] = useState("#1B2330");

     const handleDrawerClose = () =>{
         onClose()
     }

     useEffect(() => {
      setPrimary(theme.palette.primary.dark)
     }, [useTheme().palette.primary.dark])
     

     return (
         <Drawer
         variant="permanent"
         open={open}
         sx={{
          '& .MuiDrawer-paper':{
            //@ts-ignore
            backgroundColor:primary,
          },
         }}
         >
             <StyledToolbar >
             <Box sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft:"auto"
        }}>
           <img src={logo} alt="logo" style={{width: 50, boxShadow:'none'}}/>
          <Typography sx={{fontFamily:"Nastaliq" , fontSize: 24, paddingLeft: 1,paddingRight:0 ,paddingTop:2, color:(theme)=> theme.palette.grey[400]}}>
         موسسه قرآنی علیین 
        </Typography> 
         {/* <img src={Menulogo} alt="logo" style={{}}/> */}

        </Box>
        <IconButton onClick={handleDrawerClose} size="large" sx={{color:(theme)=>theme.palette.common.white}}>
          {theme.direction === 'rtl' ? <ChevronRight/> : <ChevronLeft/>}
        </IconButton>
             </StyledToolbar>
        <Divider variant="middle" sx={{backgroundColor:theme.palette.common.white}}/>
        <List>
        {items.map((item:any) => <ItemMenu
            single={item?.route}
            isAdmin={isAdmin}
            accessLevel={item.accessLevel|| []}
            menuOpen={open}
            currentPage={location}
            color={item.color}
            key={item.title}
            children={item.children}
            category={item.category}
            title={item.title}
            icon={item.icon}/>)}
      </List>

         </Drawer>
     )
 } 

//  const withRouterItem  = (withRouter(ItemMenu))

//  export {withRouterItem as MenuLayout}
 export {MenuLayout}