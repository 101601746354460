import clsx from 'clsx';
import { forwardRef, memo, useState } from 'react';
// import { useSwipeable } from 'react-swipeable';
import { makeStyles } from '@mui/styles';
import { Slide, useTheme } from '@mui/material';
import { Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { MenuHeader } from '../header/menu.header';
import { red } from '@mui/material/colors';
import { SettingIpa } from 'components';

// const Transition = forwardRef(function Transition(props, ref) {
//   const theme = useTheme();
//   return <Slide direction={theme.direction === 'ltr' ? 'left' : 'right'} ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme:any) => ({
  buttonWrapper: {
    width:35,
    position: 'fixed',
    height: 80,
    left: 0,
    top: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    opacity: 0.8,
    padding: 0,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    zIndex: 999,
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
  },
  button: {
    minWidth: 40,
    width: 40,
    height: 40,
    margin: 0,
  },
  settingsButton: {
      padding: 0,
      animation: '$rotating 3s linear infinite',
  },
  schemesButton: {},
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  buttonIcon: {
    fontSize: 20,
  },
  dialogPaper: {
    position: 'fixed',
    width: 380,
    maxWidth: '90vw',
    backgroundColor: theme.palette.background.paper,
    top: 0,
    height: '100%',
    minHeight: '100%',
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    borderRadius: 0,
  },
}));

function MiniSetting() {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handlerOptions = {
    onSwipedLeft: () => {
      return open && theme.direction === 'rtl' && handleClose();
    },
    onSwipedRight: () => {
      return open && theme.direction === 'ltr' && handleClose();
    },
  };

//   const settingsHandlers = useSwipeable(handlerOptions);
//   const shemesHandlers = useSwipeable(handlerOptions);

  const handleOpen = (panelId:any) => {
    setOpen(panelId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.buttonWrapper} id="fuse-settings-schemes">
        {/* <Button
          className={clsx(classes.button, classes.settingsButton)}
          onClick={() => handleOpen('settings')}
          variant="text"
          color="inherit"
        > */}
          <SettingIpa />
        {/* </Button> */}
        <MenuHeader/>
       
      </div>

      {/* <Dialog
        TransitionComponent={Transition}
        aria-labelledby="settings-panel"
        aria-describedby="settings"
        open={open === 'settings'}
        keepMounted
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: clsx(classes.dialogPaper, 'shadow-lg'),
        }}
        {...settingsHandlers}
      >
        <FuseScrollbars className="p-16 sm:p-32">
          <IconButton className="fixed top-0 ltr:right-0 rtl:left-0 z-10" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>

          <Typography className="mb-32 font-semibold" variant="h6">
            Theme Settings
          </Typography>

          <FuseSettings />
        </FuseScrollbars>
      </Dialog>

      <Dialog
        TransitionComponent={Transition}
        aria-labelledby="schemes-panel"
        aria-describedby="schemes"
        open={open === 'schemes'}
        keepMounted
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: clsx(classes.dialogPaper, 'shadow-lg'),
        }}
        {...shemesHandlers}
      >
        <FuseScrollbars className="p-16 sm:p-32">
          <IconButton className="fixed top-0 ltr:right-0 rtl:left-0 z-10" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>

          <Typography className="mb-32" variant="h6">
            Theme Color Schemes
          </Typography>

          <Typography className="mb-24 text-12 italic text-justify" color="textSecondary">
            * Selected color scheme will be applied to all theme layout elements (navbar, toolbar,
            etc.). You can also select a different color scheme for each layout element at theme
            settings.
          </Typography>

          <FuseThemeSchemes />
        </FuseScrollbars>
      </Dialog> */}
    </>
  );
}

export {MiniSetting};
