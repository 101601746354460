import { AdminDashboard, ExpertDashboard, OwnerDashboard, StudentDashboard, TeacherDashboard } from "pages";
import { useSelector } from "react-redux";

function RolesDashboard() {
   const user = useSelector((state: any) => state.global.user);
   const roles = useSelector((state: any) => state.global.user.roles);

   const roleLength = user.roles?.length;

   console.log(roleLength);

   const singleDashboard = () => {
      if (roleLength === 1) {
         switch (roles[0]) {
            case "Owner":
               return <OwnerDashboard />;
            case "Admin":
               return <AdminDashboard />;
            case "Expert":
               return <ExpertDashboard />;
            case "Student":
               return <StudentDashboard />;
            case "Teacher":
               return <TeacherDashboard />;
            default:
               break;
         }
      }
   };

   const multiDashboard = () => {
      if (roleLength > 1) {
         if (roles.includes("Owner")) {
            return <OwnerDashboard />;
         } else if (roles.includes("Admin")) {
            return <AdminDashboard />;
         } else if (roles.includes("Expert")) {
            return <ExpertDashboard />;
         } else if (roles.includes("Teacher")) {
            return <TeacherDashboard />;
         } else if (roles.includes("Student")) {
            return <StudentDashboard />;
         }
      }
   };

   return roleLength === 1 ? singleDashboard() : multiDashboard();
}

export { RolesDashboard };
