export interface ColumnExcel {
  title: string,
  width: number
}

export interface DataExcel {
  value: string | number
}

export const makeDataOfExcel = (columns: ColumnExcel[], data: DataExcel[][]) => {
  const border = {
    top: {style: "thin", color: '#000000'},
    bottom: {style: "thin", color: '#000000'},
    left: {style: "thin", color: '#000000'},
    right: {style: "thin", color: '#000000'}
  }
  const multiDataSet = [
    {
      ySteps: 2,
      columns: columns.map(column => (
          {
            title: column.title,
            width: {wpx: column.width},
            style: {
              border,
              font: {bold: true, color: 'yellow', sz: '10'},
              fill: {patternType: "solid", fgColor: {rgb: "FFFFFF00"}},
              alignment: {vertical: 'center'}
            }
          }
      )),
      data: data.map(item =>
          item.map(childItem => ({
                value: childItem,
                style: {border}
              })
          )
      )
    }
  ];

  return (multiDataSet)

}
