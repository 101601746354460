import { Backdrop } from "@mui/material"
import { useTheme } from "@mui/system"
import { BounceLoader } from "react-spinners"
import { useSelector } from 'react-redux';


function Loader (){

    const loader = useSelector((state:any) => state.global.loader)

    const theme = useTheme()  

    return(
        <Backdrop open={loader} className={"loader"}>
            <BounceLoader  size={150}  speedMultiplier={.95}  color={theme.palette.primary.main} />
        </Backdrop>
    )
}

export {Loader}