import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getAllStudents } from "api/student/studentApi";
import { toast } from "react-toastify";
import { ModalIpa } from "components";
import { Autocomplete, Button, Checkbox, Grid, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { putCertificate } from "api/certificate/certificateApi";
import { GetUserClasses } from "api/classroom/classroomApi";
import { degreeNameItem } from "../../../../utils/degreeNameItem";

function EditCertificate({ edit, setEdit, studentsNameOpt, initialClassNameOpt, refresh }: any) {
  const [studentClassesOpt, setStudentClassesOpt] = useState<any>(initialClassNameOpt);
  const [tempStdName, setTempStdName] = useState(null);
  const [tempClassName, setTempClassName] = useState(null);
  const [tempCertName, setTempCertName] = useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  console.log(studentsNameOpt);

  const getStudentClasses = async (stdId: number) => {
    const res: any = await GetUserClasses(stdId);
    if (!(res instanceof Error)) {
      setStudentClassesOpt(res);
    } else {
      toast.error("دریافت داده با خطا مواجه شد");
    }
  };

  const validation = Yup.object({
    studentName: Yup.object().nullable().required("نباید خالی باشد"),
    className: Yup.object().nullable().required("نباید خالی باشد"),
    degreeName: Yup.array().nullable().required("نباید خالی باشد"),
    certsScore: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("نمره باید از حداکثر نمره کوچک تر باشد", "نمره باید از حداکثر نمره کوچک تر باشد", function (value) {
        return +value <= +this.parent.maxScore;
      })
      .test("نمره باید بزرگ تر از صفر باشد", "نمره باید بزرگ تر از صفر باشد", function (value) {
        return +value >= 0;
      }),
    maxScore: Yup.number().nullable().required("نباید خالی باشد"),
  });

  const editDegreeFormik = useFormik({
    initialValues: {
      studentName: null,
      degreeName: null,
      className: null,
      certsScore: null,
      maxScore: 200,
      description: "",
      //   Checkout: false,
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const res: any = await putCertificate(
        {
          classId: values.className.id,
          Grade: values.certsScore,
          MaxGrade: values.maxScore,
          Description: values.description,
          Name: values.degreeName,
          //       Checkout: values.Checkout,
        },
        edit.id
      );
      if (!(res instanceof Error)) {
        toast.success("ساخت مدرک با موفقیت انجام شد");
        setEdit(null);
        resetForm();
        refresh();
      } else {
        toast.error("ثبت اطلاعات با خطا مواجه شد");
      }
    },
  });

  useEffect(() => {
    if (edit) {
      console.log(edit);
      studentsNameOpt?.map((std: any) => {
        if (std?.id === edit?.userId) {
          setTempStdName(std);
          editDegreeFormik.setFieldValue("studentName", std);
        }
      });

      studentClassesOpt?.map((item: any) => {
        console.log(studentClassesOpt);
        console.log(edit);
        if (item?.id === edit?.classId) {
          setTempClassName(item);
          editDegreeFormik.setFieldValue("className", item);
        }
      });

      degreeNameItem?.map((item: any) => {
        edit?.name?.map((certName: any) => {
          if (item.name === certName) {
            tempCertName.push(item);
          }
        });
      });

      editDegreeFormik.setValues({
        studentName: tempStdName,
        className: tempCertName,
        degreeName: edit.Grade,
        certsScore: edit.grade,
        description: edit.description,
        maxScore: edit.maxGrade,
      });
    }
  }, [edit]);

  useEffect(() => {
    editDegreeFormik.setFieldValue("studentName", tempStdName);
  }, [tempStdName]);

  useEffect(() => {
    editDegreeFormik.setFieldValue("className", tempClassName);
  }, [tempClassName]);

  useEffect(() => {
    editDegreeFormik.setFieldValue("degreeName", tempCertName);
  }, [tempCertName]);

  useEffect(() => {
    if (editDegreeFormik.values.studentName) {
      getStudentClasses(editDegreeFormik.values.studentName.id);
    } else {
      setStudentClassesOpt(null);
      editDegreeFormik.setFieldValue("className", null);
    }
  }, [editDegreeFormik.values.studentName]);

  console.log(tempClassName);

  return (
    <ModalIpa
      open={true}
      title={`ویرایش مدرک`}
      onClose={() => {
        setEdit(null);
        editDegreeFormik.handleReset(1);
      }}>
      <form noValidate onSubmit={editDegreeFormik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={editDegreeFormik.values?.studentName}
              onChange={(event: any, newValue: any) => {
                editDegreeFormik.setFieldValue("studentName", newValue);
              }}
              options={studentsNameOpt ?? []}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.fullName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="دانشجو"
                  required
                  placeholder="دانشجو مورد نظر انتخاب کنید"
                  error={editDegreeFormik.touched.studentName && Boolean(editDegreeFormik.errors.studentName)}
                  //@ts-ignore
                  helperText={editDegreeFormik.touched.studentName && editDegreeFormik.errors.studentName}
                  name={"studentName"}
                  id="studentName"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={editDegreeFormik.values?.className}
              onChange={(event: any, newValue: any) => {
                editDegreeFormik.setFieldValue("className", newValue);
              }}
              options={studentClassesOpt ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="کلاس‌های دانشجو انتخاب شده"
                  required
                  placeholder="کلاس دانشجو مورد نظر انتخاب کنید"
                  error={editDegreeFormik.touched.className && Boolean(editDegreeFormik.errors.className)}
                  //@ts-ignore
                  helperText={editDegreeFormik.touched.className && editDegreeFormik.errors.className}
                  name={"className"}
                  id="className"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={editDegreeFormik.values.degreeName ?? []}
              onChange={(event: any, newValue: any) => {
                console.log(newValue);
                editDegreeFormik.setFieldValue("degreeName", newValue.length === 0 ? null : newValue);
              }}
              limitTags={3}
              multiple
              options={degreeNameItem}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.id === value.id}
              //@ts-ignore
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.id}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="جزءهای قرآن"
                  required
                  placeholder="جزء مورد مورد نظر را انتخاب کنید"
                  error={editDegreeFormik.touched.degreeName && Boolean(editDegreeFormik.errors.degreeName)}
                  //@ts-ignore
                  helperText={editDegreeFormik.touched.degreeName && editDegreeFormik.errors.degreeName}
                  name={"degreeName"}
                  id="degreeName"
                  value={editDegreeFormik.values.degreeName}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id="certsScore"
              name={"certsScore"}
              type="number"
              variant="outlined"
              label={"نمره دانشجو"}
              fullWidth
              required
              value={editDegreeFormik.values.certsScore}
              onChange={editDegreeFormik.handleChange}
              error={editDegreeFormik.touched.certsScore && Boolean(editDegreeFormik.errors.certsScore)}
              //@ts-ignore
              helperText={editDegreeFormik.touched.certsScore && editDegreeFormik.errors.certsScore}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id={"maxScore"}
              type="number"
              variant="outlined"
              label={"حداکثر نمره"}
              fullWidth
              required
              value={editDegreeFormik.values.maxScore}
              onChange={editDegreeFormik.handleChange}
              error={editDegreeFormik.touched.maxScore && Boolean(editDegreeFormik.errors.maxScore)}
              //@ts-ignore
              helperText={editDegreeFormik.touched.maxScore && editDegreeFormik.errors.maxScore}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id={"description"}
              variant="outlined"
              label={"توضیحات"}
              fullWidth
              type="text"
              rows={3}
              maxRows={5}
              multiline
              value={editDegreeFormik.values.description}
              onChange={editDegreeFormik.handleChange}
              error={editDegreeFormik.touched.description && Boolean(editDegreeFormik.errors.description)}
              //@ts-ignore
              helperText={editDegreeFormik.touched.description && editDegreeFormik.errors.description}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth color="primary" variant="contained" type={"submit"}>
              ثبت مدرک
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalIpa>
  );
}

export { EditCertificate };
