import http from "../../utils/http.utils";

type Id = string;
enum Gender {
    Male,
    Female,
}

export interface PostOrPutCourseProps {
    name: string;
    description: string;
    duration: number;
    numberOfSessions: number;
    // hasExam: boolean;
    amount: number;
    maxScore: number;
}

export function postCourse(data: PostOrPutCourseProps) {
    return http
        .post("/api/v2/Course", data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function getCourses() {
    return http
        .get("/api/v2/Course")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function putCourse(data: PostOrPutCourseProps, courseId: Id) {
    return http
        .put(`/api/v2/Course/${courseId}`, data)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function deleteCourse(courseId: Id) {
    return http
        .delete(`/api/v2/Course/${courseId}`)
        .then((res:any) => res.data)
        .catch((error:any) => error);
}






