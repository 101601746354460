import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TableCell,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { Link, useNavigate } from "react-router-dom";
import { ColumnPro } from "../../../../components/table-pro/table-pro.component";
import { sortDates, sortNumbers, sortStrings } from "../../utils/sort.utils";
import moment from "jalali-moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, Settings } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllClassrooms } from "api/classroom/classroomApi";
import { GetLogedInTeacherClasses } from "api/teacher/teacherApi";
import { useSelector } from "react-redux";
import { toggleDetailsTable } from 'redux/globalSlice';
import { toggleConfirmDetailsTable } from 'redux/globalSlice';
import { useDispatch } from "react-redux";


const columns: any = [
  {
      id: "counter",
      label: "#",
      align: "center",
      minWidth: 15,
      state: 0,
      numeric: true,
      show: true,
  },
  {
      id: "name",
      label: "نام کلاس",
      align: "center",
      minWidth: 10,
      state: 0,
      numeric: false,
      show: true,
      sort: sortStrings,
  },
  {
      id: "teacherName",
      label: "نام استاد",
      align: "center",
      minWidth: 10,
      state: 0,
      numeric: false,
      show: true,
      sort: sortStrings,
  },
  {
      id: "startTime",
      label: "ساعت شروع",
      align: "left",
      minWidth: 30,
      state: 0,
      numeric: true,
      show: true,
      sort: sortStrings,
      format: (value: string) => new Date(value).toLocaleTimeString("fa-IR"),
  },
  {
      id: "endTime",
      label: "ساعت پایان",
      align: "left",
      minWidth: 30,
      state: 0,
      numeric: true,
      show: true,
      sort: sortStrings,
      format: (value: string) => new Date(value).toLocaleTimeString("fa-IR"),
  },
  {
      id: "startDate",
      label: "تاریخ شروع",
      align: "center",
      minWidth: 30,
      state: 0,
      numeric: true,
      show: true,
      sort: sortDates,
      format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  },
  {
      id: "endDate",
      label: "تاریخ پایان",
      align: "center",
      minWidth: 30,
      state: 0,
      numeric: true,
      show: true,
      sort: sortDates,
      format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  },
//   {
//       id: "paymentPlans",
//       label: "نام پلن مالی",
//       align: "left",
//       minWidth: 130,
//       state: 0,
//       numeric: false,
//       show: true,
//       sort: sortStrings,
//   },
  {
      id: "days",
      label: "روزهای تشکیل کلاس",
      align: "left",
      minWidth: 130,
      state: 0,
      numeric: false,
      show: true,
      sort: sortStrings,
  },
//   {
//       id: "examsDateTime",
//       label: "تاریخ امتحان",
//       align: "left",
//       minWidth: 30,
//       state: 0,
//       numeric: true,
//       show: true,
//       sort: sortStrings,
//       format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
//   },
//   {
//       id: "examsDateTime",
//       label: "ساعت امتحان",
//       align: "left",
//       minWidth: 30,
//       state: 0,
//       numeric: true,
//       show: true,
//       sort: sortStrings,
//       format: (value: string) => new Date(value).toLocaleTimeString("fa-IR"),
//   },
  {
      id: "complated",
      label: "تمام شده",
      align: "left",
      minWidth: 60,
      state: 0,
      numeric: false,
      show: true,
      sort: sortStrings,
  },
  {
      id: "classLink",
      label: "لینک کلاس",
      align: "left",
      minWidth: 100,
      state: 0,
      numeric: false,
      show: true,
      sort: sortStrings,
  },
  {
      id: "description",
      label: "توضیحات",
      align: "left",
      minWidth: 100,
      state: 0,
      numeric: false,
      show: true,
      sort: sortStrings,
  },
  // {
  //    id: "createdAt",
  //    label: "تاریخ ثبت",
  //    align: "center",
  //    minWidth: 30,
  //    state: 0,
  //    numeric: true,
  //    show: true,
  //    sort: sortDates,
  //    format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  // },
//   {
//       id: "action",
//       label: "عملیات",
//       align: "center",
//       minWidth: 10,
//       state: 0,
//       numeric: true,
//       show: true,
//       // sort: sortDates,
//   },
];

interface CellProps extends TableCellProps {
  active: boolean;
}

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
  backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function ScoreClassList({ startDate, endDate }: any) {
  const [rows, setRows] = useState<any>([]);
  const [edit, setEdit] = useState<any>(null);
  const [remove, setRemove] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState<any>([]);
  
  const user = useSelector((state:any) => state.global.user)
  
  const navigate = useNavigate();

  const dispatch = useDispatch();

    const higherAccess = ["Owner" , "Admin" , "Expert"]

  const getAllNeedData = async () => {
    const accessAllClassrooms = user?.roles?.find((role: any) => higherAccess?.includes(role))
    if(!!accessAllClassrooms){
        const res: any = await getAllClassrooms();
        if (!(res instanceof Error)) {
            setRows(res);
        } else {
            toast.error("دریافت داده‌ها با مشکل مواجه شد");
        }
    }else{
        const res: any = await GetLogedInTeacherClasses();
        if (!(res instanceof Error)) {
            setRows(res);
        } else {
            toast.error("دریافت داده‌ها با مشکل مواجه شد");
        }
    }
  };

  console.log(rows)

  useEffect(() => {
    getAllNeedData();
  }, []);

  useEffect(() => {
    getAllNeedData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (rows) {
        rows?.forEach((row: any) => {
            row?.paymentPlans?.forEach((item: any, index: any) => {
                row["paymentPlans"][index] = Object.entries(item);
            });
        });
    }
}, [rows]);

  const itemsOfAction:any = [
    // {
    //   title: (
    //     <>
    //       <Edit />
    //       &nbsp; ثبت نمره این کلاس
    //     </>
    //   ),
    //   onClick: async (classRoom: any) =>
    //     await new Promise((resolve, reject) => {
    //         navigate(`${classRoom.id}`)
    //     }),
    // },
//     {

//       title: (
//         <>
//          <Delete />
//          &nbsp; حذف
//       </>
//    ),
//    onClick: (course: any) => setRemove(course.id),
//   }
  ];

  return (
    <>
      <Grid container spacing={2} sx={{mt:2}}>
        {/* <ListInfo /> */}
        <Grid item xs={12} style={{ width: 10 }}>
                    <Paper>
                        <TableProIpa
                            columns={columns}
                            bodyFunc={(column: any, row: any, index: any) => {
                                const head = column.id;
                                const value = row[column.id];
                                if (head === "action") {
                                    return (
                                        <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                                            <MenuActionTable menuId={row.id} items={itemsOfAction} icon={<Settings />} user={row} />
                                        </TableCellStyled>
                                    );
                                } else if (head === "complated") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={value ? "success" : "warning"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value ? "بله" : "خیر"}
                                            </Button>
                                        </TableCell>
                                    );
                                } else if (head === "days") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}>
                                            <Button
                                                variant={"text"}
                                                color={"info"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value?.map((item: any, index: any) => {
                                                    if (index + 1 !== value?.length) {
                                                        return `${item}/`;
                                                    } else {
                                                        return `${item}`;
                                                    }
                                                })}
                                            </Button>
                                        </TableCell>
                                    );
                                }
                                //  else if (head === "paymentPlans") {
                                //     return (
                                //         <TableCell key={column.id + row.id} align={column.align}>
                                //             <Button
                                //                 variant={"text"}
                                //                 color={"inherit"}
                                //                 style={{
                                //                     width: column.minWidth,
                                //                 }}>
                                //                 {value?.map((arrayPlanItem: any) => {
                                //                     return arrayPlanItem?.map((planItem: any) => {
                                //                         if (planItem[0] === "paymentplanName") {
                                //                             return `${planItem[1]}\n`;
                                //                         }
                                //                     });
                                //                 })}
                                //             </Button>
                                //         </TableCell>
                                //     );
                                // }
                                 else if (head === "classLink") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}
                                        onClick={() => dispatch(toggleDetailsTable(false))}
                                        onMouseOut={() => {
                                            dispatch(toggleConfirmDetailsTable(false))
                                            dispatch(toggleDetailsTable(true))
                                        }}
                                        >
                                            <a href={value} rel="noreferrer" target='_blank' id="class-link-tag">{value}</a>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.format && value ? column.format(value) : value}
                                    </TableCell>
                                );
                            }}
                            rows={rows ?? []}
                        />
                    </Paper>
                </Grid>
      </Grid>
    </>
  );
}

export { ScoreClassList };
