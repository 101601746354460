import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Autocomplete,
  Typography,
  alpha,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { accessLevelName } from "utils/accessLevel.utils";
import { postCourse } from "api/course/courseApi";


const PageTitleStyle = styled(Typography)(({ theme }: any) => ({
  // marginTop: "-1.55%",
  // backgroundColor: alpha(theme.palette.primary.main, .9),
  // paddingTop: "1%",
  // paddingBottom: "1%",
  // width: "107%",
  // marginLeft: "-3.5% !important",
  // borderRadius: "5px",
  color: theme.palette.primary.main,
  fontWeight: "600",
}));

function AddCourse() {
  const getAllNeedData = async () => {
    //  const resRoles: any = await getRoles();
    //  // const resDepartments = await getDependents()
    //  if (!(resRoles instanceof Error)) {
    //    console.log(resRoles);
    //    setRoles(resRoles);
    //    // setDepartments(resDepartments)
    //  } else {
    //    toast.error("دریافت داده با خطا مواجه شد");
    //  }
  };

  useEffect(() => {
    getAllNeedData();
  }, []);

  const validation = Yup.object({
   name: Yup.string().required("نباید خالی باشد"),
   duration: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value) {
        return +value >= 0;
      }),
    numberOfSessions: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value) {
        return +value >= 0;
      }),
    amount: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value) {
        return +value >= 0;
      }),
      maxScore: Yup.number()
      .nullable()
      .required("نباید خالی باشد")
      .test("باید عدد بزرگتر از صفر باشد", "باید عدد بزرگتر از صفر باشد", function (value) {
        return +value >= 0;
      }),
    description: Yup.string(),
  });
  const addFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
      duration: null,
      numberOfSessions: null,
      amount: null,
      // hasExam: true,
      maxScore:null
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      const res: any = await postCourse(values);
      if (!(res instanceof Error)) {
        console.log(res);
        resetForm();
        toast.success("درج دوره با موفقیت انجام شد");
      } else {
        toast.error("ثبت اطلاعات با خطا مواجه شد");
      }
    },
  });

  return (
    <Paper
      sx={{
        margin: "auto",
        textAlign: "center",
        maxWidth: 1000,
        backgroundColor: `background.paper`,
        boxShadow: (theme) => theme.shadows[5],
        padding: (theme) => theme.spacing(2, 4, 3),
        borderRadius: (theme) => theme.shape.borderRadius + "1px",
      }}>
      <form noValidate onSubmit={addFormik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageTitleStyle variant="h4">درج دوره</PageTitleStyle>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id={"name"}
              variant="outlined"
              label={"نام دوره"}
              fullWidth
              required
              value={addFormik.values.name}
              onChange={addFormik.handleChange}
              error={addFormik.touched.name && Boolean(addFormik.errors.name)}
              helperText={addFormik.touched.name && addFormik.errors.name}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id={"duration"}
              type="number"
              variant="outlined"
              label={"مدت دوره(ساعت)"}
              fullWidth
              required
              value={addFormik.values.duration}
              onChange={addFormik.handleChange}
              error={addFormik.touched.duration && Boolean(addFormik.errors.duration)}
              //@ts-ignore
              helperText={addFormik.touched.duration && addFormik.errors.duration}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id={"numberOfSessions"}
              type="number"
              variant="outlined"
              label={"تعداد جلسات"}
              fullWidth
              required
              value={addFormik.values.numberOfSessions}
              onChange={addFormik.handleChange}
              error={addFormik.touched.numberOfSessions && Boolean(addFormik.errors.numberOfSessions)}
              //@ts-ignore
              helperText={addFormik.touched.numberOfSessions && addFormik.errors.numberOfSessions}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id={"amount"}
              type="number"
              variant="outlined"
              label={"قیمت"}
              fullWidth
              required
              value={addFormik.values.amount}
              onChange={addFormik.handleChange}
              error={addFormik.touched.amount && Boolean(addFormik.errors.amount)}
              //@ts-ignore
              helperText={addFormik.touched.amount && addFormik.errors.amount}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="description"
              label={"توضیحات"}
              maxRows={4}
              fullWidth
              value={addFormik.values.description}
              onChange={addFormik.handleChange}
              error={addFormik.touched.description && Boolean(addFormik.errors.description)}
              helperText={addFormik.touched.description && addFormik.errors.description}
              placeholder={"توضیحات..."}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              id={"maxScore"}
              type="number"
              variant="outlined"
              label={"حداکثر نمره"}
              fullWidth
              required
              value={addFormik.values.maxScore}
              onChange={addFormik.handleChange}
              error={addFormik.touched.maxScore && Boolean(addFormik.errors.maxScore)}
              //@ts-ignore
              helperText={addFormik.touched.maxScore && addFormik.errors.maxScore}
            />
          </Grid>
          {/* <Grid item sm={6} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">امتحان</FormLabel>
              <RadioGroup
                aria-label="hasExam"
                name={"hasExam"}
                id="hasExam"
                style={{ flexDirection: "row" }}
                defaultValue={"1"}
                onChange={(event: any) => addFormik.setFieldValue("hasExam", event.target.value === "1")}
                // @ts-ignore
                error={addFormik.touched.hasExam && Boolean(addFormik.errors.hasExam)}>
                <FormControlLabel value={"1"} control={<Radio />} label="دارد" />
                <FormControlLabel value={"0"} control={<Radio />} label="ندارد" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <Button fullWidth color="primary" variant="contained" type={"submit"}>
              ثبت دوره
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export { AddCourse };
