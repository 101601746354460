import { Typography, Paper, IconButton, Box, Button, styled } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { TableProIpa } from "components";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import { sortStrings } from "utils/sort.utils";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface CellProps extends TableCellProps {
  active: boolean;
}

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
  backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

const columns: any = [
  {
    id: "counter",
    label: "#",
    align: "center",
    minWidth: 15,
    state: 0,
    numeric: true,
    show: true,
  },
  {
    id: "action",
    label: "عملیات",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: true,
    show: true,
    // sort: sortDates,
  },
  {
    id: "description",
    label: "توضیحات",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "value",
    label: "لایسنس",
    align: "center",
    minWidth: 50,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
 ];

function Widget3(props: any) {
  const [rows, setRows] = useState(props.widget);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesSpecial1 = useMediaQuery("(min-width:1023px)");
  const matchesSpecial2 = useMediaQuery("(max-width:1210px)");

  const user = useSelector((state: any) => state.global.user);

  const handleCopyLicense = (row:any) => {
    navigator.clipboard.writeText(row?.value);
    toast.info("لایسنس کپی شد");
  };

  return (
    <Paper elevation={3} sx={{ borderRadius: "10px", height: "100.8%" }}>
      <TableProIpa
        columns={columns}
        bodyFunc={(column: any, row: any, index: any) => {
          const head = column.id;
          const value = row[column.id];
          if (head === "action") {
            return (
              <TableCellStyled active={true} key={column.id + row.id} align={column.align} onClick={() => handleCopyLicense(row)}>
                <Button variant="outlined" startIcon={<ContentCopyIcon />}>
                  کپی لایسنس
                </Button>
              </TableCellStyled>
            );
          } else if (head === "value") {
            return (
              <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                {value}
              </TableCellStyled>
            );
          } else {
            return (
              <TableCell key={column.id} align={column.align}>
                {column.format && value ? column.format(value) : value}
              </TableCell>
            );
          }
        }}
        rows={props.widget ?? [""]}
        style={{
          row: {
            height: "10px !important",
          },
        }}
        // defaultRowPerPage={matchesMd ? 4 : matchesSpecial1 && matchesSpecial2 ? 1 : 3}
        defaultRowPerPage={3}
        showLessBtn={false}
      />
    </Paper>
  );
}

export { Widget3 };
