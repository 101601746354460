import React, { useState, useEffect } from "react";
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TableCell,
    TableCellProps,
    TextField,

    styled,
    useTheme, Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { sortStrings } from "utils/sort.utils";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import {getCertificateInfoByTrackingCode, GetStudentCertificates} from "../../../api/certificate/certificateApi";
import {TbCertificate, TbCertificateOff} from "react-icons/tb";
import {toggleConfirmDetailsTable, toggleDetailsTable} from "../../../redux/globalSlice";
import http from "../../../utils/http.utils";

interface CellProps extends TableCellProps {
    active: boolean;
}
const TableCellStyled = styled(TableCell, {
    shouldForwardProp: (prop: any) => prop !== "active",
})<CellProps>(({ theme, active }: any) => ({
    backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function CertificateTracking() {
    const [degreeInfo, setDegreeInfo] = useState(null);

    const theme = useTheme();
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    //======= start searchFormik code =======/
    const searchFormikValidation = Yup.object({
        trackingCode: Yup.string().nullable().required("نباید خالی باشد"),
    });

    const searchFormik = useFormik({
        initialValues: {
            trackingCode: "",
        },
        validationSchema: searchFormikValidation,
        onSubmit: async (values, { resetForm }) => {
            const res: any = await getCertificateInfoByTrackingCode(values.trackingCode);
            if (!(res instanceof Error)) {
                setDegreeInfo(res.data);
            } else {
                toast.error("دریافت داده‌ها با مشکل مواجه شد");
            }
        },
    });

    const handleSubmitAddFormikWithButton = () => {
        searchFormik.handleSubmit();
    };
    //======= end searchFormik code =======/

    const handleDownloadCertificate = async () =>{
        if(degreeInfo){
            await http.get(`api/v2/Certificate/GetPDF/${degreeInfo.id}`, { responseType: "arraybuffer" }).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
                let link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "certificate.pdf");
                document.body.appendChild(link);
                link.click();
            }).catch((err:any) => {
                console.log(err)
            })
        }
    }

    console.log(degreeInfo)

    return (
        <>
            <Grid container spacing={6}>
                <Grid item xs={12} >
                    <Paper elevation={5} sx={{px:5 , pb:2 ,pt:3, borderRadius:10 , maxWidth:"600px" , mx:"auto"}} >
                        <form noValidate onSubmit={searchFormik.handleSubmit}>
                            <Grid container justifyContent={"center"} alignItems={"center"} spacing={3}>
                                <Grid item xs={12} sx={{textAlign:"center"}}>
                                    <Typography variant="h5" sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
                                        رهگیری مدرک
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined" sx={{ height: "70px" }}>
                                        <TextField
                                            autoFocus
                                            required
                                            id="trackingCode"
                                            placeholder={"لطفا کد رهگیری را وارد کنید"}
                                            value={searchFormik.values.trackingCode}
                                            onChange={searchFormik.handleChange}
                                            error={searchFormik.touched.trackingCode && Boolean(searchFormik.errors.trackingCode)}
                                            helperText={
                                                searchFormik.touched.trackingCode && searchFormik.errors.trackingCode ? String(searchFormik.errors.trackingCode) : null
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="submit subscription code"
                                                            onClick={handleSubmitAddFormikWithButton}
                                                            edge="end"
                                                        >
                                                            <SearchIcon sx={{ color: theme.palette.grey[400], width: "30px", height: "37px" }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={"کد رهگیری مدرک"}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
                {degreeInfo && <Grid item xs={12}>
                    <Paper elevation={5} sx={{px:5 , pb:2 ,pt:3, borderRadius:10 , mx:"auto" , maxWidth:"600px"}} >
                        <Grid container spacing={2} sx={{textAlign:"center"}}>
                            <Grid item xs={12} sx={{textAlign:"center" , mb:3}}>
                                <p className={"text-xl , font-semibold"}>{degreeInfo.userFullName}</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p className={"text-md , text-slate-500 , inline-block , ml-1"}>کد ملی: </p>
                                <p className={"text-lg ,font-medium, inline-block"}>{degreeInfo.userNationalCode}</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p className={"text-md , text-slate-500 , inline-block , ml-1"}>نام پدر: </p>
                                <p className={"text-lg ,font-medium, inline-block"}>{degreeInfo.userFatherName}</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p className={"text-md , text-slate-500 , inline-block , ml-1"}>حداکثر نمره: </p>
                                <p className={"text-lg ,font-medium, inline-block"}>{degreeInfo.maxGrade}</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p className={"text-md , text-slate-500 , inline-block , ml-1"}>نمره: </p>
                                <p className={"text-lg ,font-medium, inline-block"}>{degreeInfo.grade}</p>
                            </Grid>
                            {typeof degreeInfo.graduted === "boolean" ?
                                <Grid item xs={12} md={6}>
                                    <p className={"text-md , text-slate-500 , inline-block , ml-1"}>وضعیت قبولی: </p>
                                    {degreeInfo.graduted ?
                                        <p className={"text-lg , text-emerald-600 , font-semibold , inline-block"}>قبول</p> :
                                        <p className={"text-lg , text-rose-600 , font-semibold , inline-block"}>رد</p>
                                    }
                                </Grid> : null
                            }

                            <Grid item xs={12} md={6}>
                                <p className={"text-md , text-slate-500 , inline-block , ml-1"}>تاریخ صدور: </p>
                                <p className={"text-lg , inline-block"}>{degreeInfo.createdDate}</p>
                            </Grid>
                            {degreeInfo.description?.length !==0 ?  <Grid item xs={12} sx={{textAlign:"left"}}>
                                <p className={"text-md , text-slate-500 , inline-block , ml-1"}>توضیحات: </p>
                                <p className={"text-lg , inline-block"}>{degreeInfo.description}</p>
                            </Grid> : null}
                            {typeof degreeInfo.graduted === "boolean" && degreeInfo.graduted ?
                            <Grid item xs={12} sx={{mt:2}}>
                                <Button variant={"outlined"} onClick={handleDownloadCertificate}>
                                    دانلود مدرک
                                </Button>
                            </Grid>
                                : null }
                        </Grid>
                    </Paper>
                </Grid>}

            </Grid>
        </>
    );
}

export default CertificateTracking;
