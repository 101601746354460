import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
// import _ from '@lodash';
import { useEffect, memo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import MenuItem from "@mui/material/MenuItem";
import { Bar, PolarArea } from "react-chartjs-2";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, RadialLinearScale, Tooltip } from "chart.js";
import { Typography, useMediaQuery } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);
// ChartJS.overrides.polarArea.plugins.tooltip =

// const tooltipPlugin = ChartJS.registry.getPlugin('tooltip');
// tooltipPlugin.beforeTooltipDraw = function(elements:any, eventPosition:any) {
//   console.log(elements);
//   console.log(eventPosition);
// }
ChartJS.defaults.borderColor = "rgba(153, 151, 151 , .4)";

function Widget7(props: any) {
  const theme = useTheme();
  const matcheslg = useMediaQuery(theme.breakpoints.up('md'));
  const [statusAspect, setStatusAspect] = useState<boolean>(!matcheslg);
  

  useEffect(() => {
    if (matcheslg) {
      setStatusAspect(false)
    }else{
      setStatusAspect(true)
    }
  }, [matcheslg])

  useEffect(() => {
    if (matcheslg) {
      setStatusAspect(false)
    }else{
      setStatusAspect(true)
    }
  }, [])

  console.log(props.widget?.yearlySchedule);
  
  

  return (
    <Paper elevation={3} sx={{ borderRadius: "10px", height: "100%" }}>
      <Bar
        data={{
          labels: ["فروردین", "اردیبهشت", "خرداد", "تیر", "مرداد", "شهریور", "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"],
          datasets: [
            {
              label: "مجموع ساعت کلاس ها در هر ماه",
              data: [
                (props.widget?.yearlySchedule?.farvardin)/60,
                (props.widget?.yearlySchedule?.ordibehesht)/60,
                (props.widget?.yearlySchedule?.khordad)/60,
                (props.widget?.yearlySchedule?.tir)/60,
                (props.widget?.yearlySchedule?.mordad)/60,
                (props.widget?.yearlySchedule?.shahrivar)/60,
                (props.widget?.yearlySchedule?.mehr)/60,
                (props.widget?.yearlySchedule?.aban)/60,
                (props.widget?.yearlySchedule?.azar)/60,
                (props.widget?.yearlySchedule?.dey)/60,
                (props.widget?.yearlySchedule?.bahman)/60,
                (props.widget?.yearlySchedule?.esfand)/60,
                ],
              backgroundColor: [
                "rgba(33,150, 243, 0.65)",
              ],
              hoverBackgroundColor: [
                "rgb(33,150, 243)",
              ],
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: statusAspect,
          // maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  return `${context.label} : ${context.raw} ساعت`;
                },
              },
            },
            legend: {
              labels: {
                font: {
                  family: theme.typography.fontFamily,
                  size: 14,
                  weight: "bold",
                },
              },
            },
          },
          
        }}></Bar>
    </Paper>
  );
}

export default memo(Widget7);

{
  /*
 <div className="flex items-center justify-between p-5">
        <Typography className="text-16 font-medium">{widget.title}</Typography>

        <Select
          value={currentRange}
          onChange={handleChangeRange}
          inputProps={{
            name: 'currentRange',
          }}
          classes={{ select: 'py-8' }}
          variant="filled"
        >
          {Object.entries(widget.ranges).map(([key, n]:any) => {
            return (
              <MenuItem key={key} value={key}>
                {n}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div>
        <ReactApexChart
          options={widget.mainChart.options}
          series={widget.mainChart.series[currentRange]}
          type={widget.mainChart.options.chart.type}
          height="400px"
        />
      </div>
      <div className="flex items-center p-8 border-t-1">
        <div className="flex flex-1 flex-col items-center justify-center p-16 border-r-1">
          <Typography className="text-32 font-medium mb-8 leading-none tracking-tighter">
            {widget.footerLeft.count[currentRange]}
          </Typography>
          <Typography className="text-13 font-normal" color="textSecondary">
            {widget.footerLeft.title}
          </Typography>
        </div>
        <div className="flex flex-1 flex-col items-center justify-center p-16">
          <Typography className="text-32 font-medium mb-8 leading-none tracking-tighter">
            {widget.footerRight.count[currentRange]}
          </Typography>
          <Typography className="text-13 font-normal" color="textSecondary">
            {widget.footerRight.title}
          </Typography>
        </div>
      </div>
        
    
      plugins:{
          tooltip: {
            enabled: false,
            external: context => {
              const tooltipModel = context.tooltip
              console.log(tooltipModel);
              return `${tooltipModel.dataPoints[0].label}تعداد`
            }},
    
    */
}
