import { alpha, Box, Grid, Paper, Tab, Tabs, Typography, useTheme } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import React from "react";
import { LoginForm } from "./components/login.component";
import { RegisterForm } from "./components/register.component";
// import MainLogoHq from "asset/images/HqMainLogo.png";
// import TextLogo from "asset/images/drp_txt.png";
// import { Copyright } from "../../components";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import classes from "./loginRegister.module.scss";

interface TabPanelProps {
   children?: React.ReactNode;
   dir?: string;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
   };
}

function LoginAndRegisterPage() {
   const theme = useTheme();
   const [value, setValue] = React.useState(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const handleChangeIndex = (index: number) => {
      setValue(index);
   };


   return (
      <Grid container  className={classes.main}> 
         <Grid item xs={12} sm={8} md={7} className={classes.container}>
            <div className={classes.Box} >
               <Avatar
                  color={"primary"}
                  sx={{
                     margin: .2,
                     backgroundColor: "primary.main",
                     color: "white",
                  }}>
                  <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5" className="p5 pt-5 pb-5" color="grey.300">
                  سامانه علیین
               </Typography>
               
               <Paper sx={{ backgroundColor: alpha(theme.palette.background.paper, theme.palette.action.selectedOpacity) ,
                   borderRadius:theme.shape.borderRadius + "1px"
                    }}>
                  <Tabs
                     value={value}
                     onChange={handleChange}
                     indicatorColor="secondary"
                     textColor="inherit"
                     variant="fullWidth"
                     aria-label="full width tabs">
                     <Tab label="ورود" {...a11yProps(0)} />
                     {/* <Tab label="ثبت نام" {...a11yProps(1)} /> */}
                  </Tabs>
                  <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
                     <TabPanel value={value} index={0} dir={theme.direction}>
                        <LoginForm />
                     </TabPanel>
                     {/* <TabPanel value={value} index={1} dir={theme.direction}>
                        <RegisterForm />
                     </TabPanel> */}
                  </SwipeableViews>
               </Paper>
            </div>
         </Grid>
         {/* <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
               backgroundImage: `url(${MainLogoHq})`,
               backgroundRepeat: "no-repeat",
               backgroundColor: (theme) =>
                  theme.palette.mode === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
               backgroundPosition: "center",
               backgroundSize: "contain",
            }}
         /> */}
         <Box
            m={2}
            sx={{
               position: "fixed",
               bottom: 0,
               left: 0,
               right: 0,
            }}>
            {/* <Copyright /> */}
         </Box>
      </Grid>
   );
}

export { LoginAndRegisterPage };
