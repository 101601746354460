export const degreeNameItem = [
    {
       id: 1,
       name: "جزء یکم",
    },
    {
       id: 2,
       name: "جزء دوم",
    },
    {
       id: 3,
       name: "جزء سوم",
    },
    {
       id: 4,
       name: "جزء چهارم",
    },
    {
       id: 5,
       name: "جزء پنجم",
    },
    {
       id: 6,
       name: "جزء ششم",
    },
    {
       id: 7,
       name: "جزء هفتم",
    },
    {
       id: 8,
       name: "جزء هشتم",
    },
    {
       id: 9,
       name: "جزء نهم",
    },
    {
       id: 10,
       name: "جزء دهم",
    },
    {
       id: 11,
       name: "جزء يازدهم",
    },
    {
       id: 12,
       name: "جزء دوازدهم",
    },
    {
       id: 13,
       name: "جزء سيزدهم",
    },
    {
       id: 14,
       name: "جزء چهاردهم",
    },
    {
       id: 15,
       name: "جزء پانزدهم",
    },
    {
       id: 16,
       name: "جزء شانزدهم",
    },
    {
       id: 17,
       name: "جزء هفدهم",
    },
    {
       id: 18,
       name: "جزء هجدهم",
    },
    {
       id: 19,
       name: "جزء نوزدهم",
    },
    {
       id: 20,
       name: "جزء بيستم",
    },
    {
       id: 21,
       name: "جزء بيست و يکم",
    },
    {
       id: 22,
       name: "جزء بيست و دوم",
    },
    {
       id: 23,
       name: "جزء بيست و سوم",
    },
    {
       id: 24,
       name: "جزء بيست و چهارم",
    },
    {
       id: 25,
       name: "جزء بيست و پنجم",
    },
    {
       id: 26,
       name: "جزء بيست و ششم",
    },
    {
       id: 27,
       name: "جزء بيست و هفتم",
    },
    {
       id: 28,
       name: "جزء بيست و هشتم",
    },
    {
       id: 29,
       name: "جزء بيست و نهم",
    },
    {
       id: 30,
       name: "جزء سي ام",
    },
];