import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
    Autocomplete,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Switch,
    TextField,
    useTheme,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "jalali-moment";
import AdapterJalali from "@date-io/date-fns-jalali";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { daysItem } from "../../../utils/dayOfWeek.utils";
import classes from "./addClassStyle.module.scss";
import { DateTimePicker } from "@mui/x-date-pickers";
import { getCourses } from "api/course/courseApi";
import { getAllStudents } from "api/student/studentApi";
import { getAllTeachers } from "api/teacher/teacherApi";
import { CompleteClassRoom, CreateClassInformation, CreatePaymentPlan } from "api/classroom/classroomApi";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const steps = ["مشخصات کلاس", "پلن‌های مالی", "دانشجو"];

function AddClass() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [courseNameOpt, setCourseNameOpt] = useState<any>([]);
    const [studentsNameOpt, setStudentsNameOpt] = useState<any>([]);
    const [teachersNameOpt, setTeachersNameOpt] = useState<any>([]);
    const [courseNameValue, setCourseNameValue] = useState(null);
    const [courseTeachereOpt, setcourseTeachereOpt] = useState<any>([{ title: "courseTeacher1" }, { title: "courseTeacher2" }]);
    const [courseTeacherValue, setCourseTeacherValue] = useState(null);
    const [passStep1, setPassStep1] = useState<boolean>(false);
    const [passStep2, setPassStep2] = useState<boolean>(false);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
    const [classroomId, setClassroomId] = useState<any>(null);
    const [step2Response, setStep2Response] = useState<any>(null);
    const [showPassword, setShowPassword] = useState(false);
    // const [financePlan1 , setFinancePlan1] = useState(false);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const yupErrColor = "#D73535";
    const theme = useTheme();

    const getAllNeedData = async () => {
        const resCourse: any = await getCourses();
        const resStudents: any = await getAllStudents();
        const resTeachers: any = await getAllTeachers();
        if (!(resCourse instanceof Error) && !(resStudents instanceof Error) && !(resTeachers instanceof Error)) {
            setCourseNameOpt(resCourse);
            setStudentsNameOpt(resStudents);
            setTeachersNameOpt(resTeachers);
        } else {
            toast.error("دریافت اطلاعات با مشکل مواجه شد");
        }
    };

    useEffect(() => {
        getAllNeedData();
    }, []);

    const isStepOptional = (step: number) => {
        return step === 4;
    };

    const isStepSkipped: any = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = async () => {
        if (activeStep !== steps.length - 1) {
            if (activeStep === 0) {
                addFormikStep1.handleSubmit();
            }
            if (activeStep === 1) {
                if (addFormikStep2.values.financePlan1) {
                    addFormikStep2.handleSubmit();
                } else {
                    toast.error("حداقل یک پنل مالی باید انتخاب شود");
                }
            }
        } else {
            addFormikStep3.handleSubmit();
        }
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    let showForms;

    const validationStep1 = Yup.object({
        CourseName: Yup.object().nullable().required("نباید خالی باشد"),
        ClassName: Yup.string().required("نباید خالی باشد"),
        ClassLink: Yup.string().required("نباید خالی باشد"),
        StartTime: Yup.string().required("نباید خالی باشد"),
        EndTime: Yup.string()
            .required("نباید خالی باشد")
            .test("ساعت خروج باید از ورود بزرگتر باشد", "ساعت خروج باید از ورود بزرگتر باشد", function (value: string) {
                return moment(value).diff(moment(this.parent.StartTime)) > 0;
            }),
        DaysOfWeek: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number(),
                    title: Yup.string(),
                })
            )
            .nullable()
            .required("نباید خالی باشد"),
        examDayTime: Yup.string()
            // .required("نباید خالی باشد")
            .test("تاریخ و ساعت معتبر نیست", "تاریخ و ساعت معتبر نیست", function (value: string) {
                if(value !== null && value!=="undefine"){
                    return moment(value).isValid();
                }
                }),
        StartDate: Yup.string()
            .nullable()
            .required("نباید خالی باشد")
            .test("تاریخ معتبر نیست", "تاریخ معتبر نیست", function (value: string) {
                return moment(value).isValid();
            }),
        EndDate: Yup.string()
            .nullable()
            .required("نباید خالی باشد")
            .test("تاریخ معتبر نیست", "تاریخ معتبر نیست", function (value: string) {
                return moment(value).isValid();
            })
            .test("تاریخ پایان از شروع باید بزرگتر باشد", "تاریخ پایان از شروع باید بزرگتر باشد", function (value: string) {
                return moment(value).diff(this.parent.StartDate, "day") + 1 > 0;
            }),
        // examTime: Yup.string().required("نباید خالی باشد"),
    });

    const addFormikStep1 = useFormik({
        initialValues: {
            CourseName: null,
            ClassName: "",
            ClassLink: "",
            StartDate: null,
            EndDate: null,
            StartTime: "",
            EndTime: "",
            DaysOfWeek: null,
            ClassDescription: "",
            classCompleted: false,
            examDayTime: "",
            // examTime: "",
        },
        validationSchema: validationStep1,
        onSubmit: async (values, { resetForm }) => {
            const examDateTimeArray = [];
            if(values.examDayTime.length === 0){

            }else{
                examDateTimeArray.push(values.examDayTime);
            }
            console.log({
                    courseId: values.CourseName.id,
                    name: values.ClassName,
                    classLink: values.ClassLink,
                    description: values.ClassDescription,
                    startDate: values.StartDate,
                    endDate: values.EndDate,
                    startTime: values.StartTime,
                    endTime: values.EndTime,
                    days: values.DaysOfWeek,
                    // complated: values.classCompleted,
                    examsDateTime: examDateTimeArray,
                });
            const res = await CreateClassInformation({
                courseId: values.CourseName.id,
                name: values.ClassName,
                classLink: values.ClassLink,
                description: values.ClassDescription,
                startDate: values.StartDate,
                endDate: values.EndDate,
                startTime: values.StartTime,
                endTime: values.EndTime,
                days: values.DaysOfWeek,
                // complated: values.classCompleted,
                examsDateTime: examDateTimeArray,
            });
            if (!(res instanceof Error)) {
                console.log(res);
                setClassroomId(res.id);
                resetForm();
                toast.success("درج مشخصات کلاس با موفقیت انجام شد");

                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            } else {
                toast.error("درج کلاس با خطا مواجه شد");
            }
        },
    });

    const addFormikStep2 = useFormik({
        initialValues: {
            financePlan1: true,
        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values);

            const res = await CreatePaymentPlan(classroomId);
            if (!(res instanceof Error)) {
                console.log(res);
                setStep2Response(res);

                toast.success("درج پلن مالی با موفقیت انجام شد");

                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            } else {
                toast.error("درج کلاس با خطا مواجه شد");
            }
        },
    });

    const validationStep3 = Yup.object({
        StudentName: Yup.array().nullable().required("نباید خالی باشد"),
        TeacherName: Yup.object().nullable().required("نباید خالی باشد"),
    });

    const addFormikStep3 = useFormik({
        initialValues: {
            StudentName: null,
            TeacherName: null,
        },
        validationSchema: validationStep3,
        onSubmit: async (values, { resetForm }) => {
            const studentsId = values.StudentName.map((item: any) => {
                return item.id;
            });

            const res = await CompleteClassRoom({ studentsId, teacherId: values.TeacherName.id }, classroomId);
            if (!(res instanceof Error)) {
                console.log(res);
                resetForm();

                toast.success("درج کلاس با موفقیت انجام شد");

                let newSkipped = skipped;
                if (isStepSkipped(activeStep)) {
                    newSkipped = new Set(newSkipped.values());
                    newSkipped.delete(activeStep);
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            } else {
                toast.error("درج کلاس با خطا مواجه شد");
            }
        },
    });

    switch (activeStep + 1) {
        case 1:
            showForms = (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                options={courseNameOpt ?? []}
                                onChange={(e, value) => {
                                    addFormikStep1.setFieldValue("CourseName", value ? value : "");
                                    setCourseNameValue(value);
                                }}
                                getOptionLabel={(user: any) => user.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={addFormikStep1.touched.CourseName && Boolean(addFormikStep1.errors.CourseName)}
                                        //@ts-ignore
                                        helperText={addFormikStep1.touched.CourseName && addFormikStep1.errors.CourseName}
                                        required
                                        fullWidth
                                        label="نام دوره"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name={"ClassName"}
                                onChange={addFormikStep1.handleChange}
                                value={addFormikStep1.values.ClassName}
                                error={addFormikStep1.touched.ClassName && Boolean(addFormikStep1.errors.ClassName)}
                                helperText={addFormikStep1.touched.ClassName && addFormikStep1.errors.ClassName}
                                fullWidth
                                required
                                type={"string"}
                                label="نام کلاس"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                value={addFormikStep1.values.DaysOfWeek || []}
                                onChange={(event: any, newValue: any) => {
                                    console.log(newValue);
                                    setSelectedDaysOfWeek(newValue ? newValue : null);
                                    addFormikStep1.setFieldValue("DaysOfWeek", newValue.length === 0 ? null : newValue);
                                }}
                                multiple
                                options={daysItem}
                                disableCloseOnSelect
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                            {option.name}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="روزهای تشکیل کلاس"
                                        required
                                        placeholder="روزهای تشکیل کلاس در هفته را انتخاب کنید"
                                        error={addFormikStep1.touched.DaysOfWeek && Boolean(addFormikStep1.errors.DaysOfWeek)}
                                        //@ts-ignore
                                        helperText={addFormikStep1.touched.DaysOfWeek && addFormikStep1.errors.DaysOfWeek}
                                        name={"DaysOfWeek"}
                                        id="DaysOfWeek"
                                        value={addFormikStep1.values.DaysOfWeek}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name={"ClassLink"}
                                onChange={addFormikStep1.handleChange}
                                value={addFormikStep1.values.ClassLink}
                                error={addFormikStep1.touched.ClassLink && Boolean(addFormikStep1.errors.ClassLink)}
                                helperText={addFormikStep1.touched.ClassLink && addFormikStep1.errors.ClassLink}
                                fullWidth
                                required
                                type={"string"}
                                label="لینک کلاس"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <DatePicker
                                    label="تاریخ شروع کلاس"
                                    mask="____/__/__"
                                    value={addFormikStep1.values.StartDate}
                                    onChange={(newValue: any) =>
                                        moment(newValue, "YYYY/MM/DD").isValid() && addFormikStep1.setFieldValue("StartDate", moment(newValue).locale("en").format("YYYY-MM-DD"))
                                    }
                                    renderInput={(params: any) => (
                                        <TextField
                                            fullWidth
                                            required
                                            label={"از تاریخ"}
                                            {...params}
                                            error={addFormikStep1.touched.StartDate && Boolean(addFormikStep1.errors.StartDate)}
                                            //@ts-ignore
                                            helperText={addFormikStep1.touched.StartDate && addFormikStep1.errors.StartDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <DatePicker
                                    label="تاریخ پایان کلاس"
                                    mask="____/__/__"
                                    value={addFormikStep1.values.EndDate}
                                    onChange={(newValue: any) =>
                                        moment(newValue, "YYYY/MM/DD").isValid() && addFormikStep1.setFieldValue("EndDate", moment(newValue).locale("en").format("YYYY-MM-DD"))
                                    }
                                    renderInput={(params: any) => (
                                        <TextField
                                            fullWidth
                                            required
                                            label={"تا تاریخ"}
                                            {...params}
                                            error={addFormikStep1.touched.EndDate && Boolean(addFormikStep1.errors.EndDate)}
                                            //@ts-ignore
                                            helperText={addFormikStep1.touched.EndDate && addFormikStep1.errors.EndDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <TimePicker
                                    label="ساعت شروع"
                                    ampm={false}
                                    value={addFormikStep1.values.StartTime}
                                    onChange={(newValue) => {
                                        setSelectedStartTime(newValue);
                                        addFormikStep1.setFieldValue("StartTime", newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            fullWidth
                                            {...params}
                                            error={addFormikStep1.touched.StartTime && Boolean(addFormikStep1.errors.StartTime)}
                                            helperText={addFormikStep1.touched.StartTime && addFormikStep1.errors.StartTime}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <TimePicker
                                    label="ساعت پایان"
                                    ampm={false}
                                    value={addFormikStep1.values.EndTime}
                                    onChange={(newValue) => {
                                        setSelectedEndTime(newValue);
                                        addFormikStep1.setFieldValue("EndTime", newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required
                                            {...params}
                                            error={addFormikStep1.touched.EndTime && Boolean(addFormikStep1.errors.EndTime)}
                                            helperText={addFormikStep1.touched.EndTime && addFormikStep1.errors.EndTime}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name={"ClassDescription"}
                                onChange={addFormikStep1.handleChange}
                                value={addFormikStep1.values.ClassDescription}
                                error={addFormikStep1.touched.ClassDescription && Boolean(addFormikStep1.errors.ClassDescription)}
                                helperText={addFormikStep1.touched.ClassDescription && addFormikStep1.errors.ClassDescription}
                                fullWidth
                                type={"string"}
                                label="توضیحات کلاس"
                                variant="outlined"
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                     <LocalizationProvider dateAdapter={AdapterJalali}>
                        <DatePicker
                           label="تاریخ امتحان"
                           //    okText={"تایید"}
                           //    cancelText={"لغو"}
                           mask="____/__/__"
                           value={addFormikStep1.values.examDay}
                           onChange={(newValue: any) =>
                              moment(newValue, "YYYY/MM/DD").isValid() &&
                              addFormikStep1.setFieldValue("examDay", moment(newValue).locale("en").format("YYYY-MM-DD"))
                           }
                           renderInput={(params: any) => <TextField fullWidth {...params} 
                           error={addFormikStep1.touched.examDay && Boolean(addFormikStep1.errors.examDay)}
                                 helperText={addFormikStep1.touched.examDay && addFormikStep1.errors.examDay}
                           />}
                        />
                     </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <LocalizationProvider dateAdapter={AdapterJalali}>
                        <TimePicker
                           label="ساعت امتحان"
                           ampm={false}
                           value={addFormikStep1.values.examTime}
                           onChange={(newValue) => {
                              setSelectedEndTime(newValue);
                              addFormikStep1.setFieldValue("examTime", newValue);
                           }}
                           renderInput={(params) => (
                              <TextField
                                 fullWidth
                                 required
                                 {...params}
                                 error={addFormikStep1.touched.examTime && Boolean(addFormikStep1.errors.examTime)}
                                 helperText={addFormikStep1.touched.examTime && addFormikStep1.errors.examTime}
                              />
                           )}
                        />
                     </LocalizationProvider>
                  </Grid> */}
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterJalali}>
                                <DateTimePicker
                                    ampm={false}
                                    label="تاریخ و ساعت امتحان"
                                    value={addFormikStep1.values.examDayTime}
                                    onChange={(newValue: any) => addFormikStep1.setFieldValue("examDayTime", newValue ? newValue : "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            // required
                                            error={addFormikStep1.touched.examDayTime && Boolean(addFormikStep1.errors.examDayTime)}
                                            // @ts-ignore
                                            helperText={addFormikStep1.touched.examDayTime && addFormikStep1.errors.examDayTime}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                sx={{
                                    "&  .MuiFormControlLabel-label": {
                                        fontWeight: 400,
                                    },
                                }}
                                control={
                                    <Switch
                                        checked={addFormikStep1.values.classCompleted}
                                        onChange={(event) => addFormikStep1.setFieldValue("classCompleted", event.target.checked)}
                                        name="financePlan1"
                                    />
                                }
                                label={`${addFormikStep1.values.classCompleted ? "پایان یافته" : "پایان نیافته"}`}
                            />
                        </Grid> */}
                    </Grid>
                </>
            );
            break;
        case 2:
            showForms = (
                <>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box className={classes.fianceCard}>
                                <Grid container alignItems={"center"} justifyContent={"center"} spacing={1} sx={{ px: 3, pt: 0.5, pb: 3 }}>
                                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                                        <Typography variant="button">پلن مالی اول</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
                                            مشخصات :
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ display: "inline-block" }}>
                                            مشخصات پلن مالی اول
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
                                            توضیحات :
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ display: "inline-block" }}>
                                            توضیحات پلن مالی اول
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
                                            مدت :
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ display: "inline-block" }}>
                                            12
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: "start" }}>
                                        <FormControlLabel
                                            sx={{
                                                "&  .MuiFormControlLabel-label": {
                                                    fontWeight: 400,
                                                },
                                            }}
                                            control={
                                                <Switch
                                                    checked={addFormikStep2.values.financePlan1}
                                                    onChange={(event) => addFormikStep2.setFieldValue("financePlan1", event.target.checked)}
                                                    name="financePlan1"
                                                />
                                            }
                                            label={`${addFormikStep2.values.financePlan1 ? "فعال" : "غیرفعال"}`}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            );
            break;
        case 3:
            showForms = (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                value={addFormikStep3.values.StudentName || []}
                                onChange={(event: any, newValue: any) => {
                                    addFormikStep3.setFieldValue("StudentName", newValue.length === 0 ? null : newValue);
                                }}
                                multiple
                                options={studentsNameOpt ?? []}
                                disableCloseOnSelect
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.fullName}
                                renderOption={(props, option, { selected }) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                            {option.fullName}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="دانشجویان"
                                        required
                                        placeholder="دانشجویان کلاس را انتخاب کنید"
                                        error={addFormikStep3.touched.StudentName && Boolean(addFormikStep3.errors.StudentName)}
                                        //@ts-ignore
                                        helperText={addFormikStep3.touched.StudentName && addFormikStep3.errors.StudentName}
                                        name={"StudentName"}
                                        id="StudentName"
                                        value={addFormikStep3.values.StudentName}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                onChange={(event: any, newValue: any) => {
                                    addFormikStep3.setFieldValue("TeacherName", newValue);
                                }}
                                options={teachersNameOpt ?? []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.fullName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="استاد"
                                        required
                                        placeholder="استاد کلاس را انتخاب کنید"
                                        error={addFormikStep3.touched.TeacherName && Boolean(addFormikStep3.errors.TeacherName)}
                                        //@ts-ignore
                                        helperText={addFormikStep3.touched.TeacherName && addFormikStep3.errors.TeacherName}
                                        name={"TeacherName"}
                                        id="TeacherName"
                                        value={addFormikStep3.values.TeacherName}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </>
            );
            break;
    }

    return (
        <Card elevation={6} sx={{ paddingX: 2, paddingTop: 5, paddingBottom: 2 }}>
            <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepOptional(index)) {
                            // labelProps.optional = (
                            //   <Typography variant="caption">Optional</Typography>
                            // );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 3, mb: 1 }}>کلاس با موفقیت درج شد</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>درج کلاس جدید</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <Box sx={{ mt: 5 }}>
                        <form action="">{showForms}</form>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            {/* <Button color="inherit" disabled={activeStep === 0 || activeStep === 1 || activeStep === 2 || activeStep === 3} onClick={handleBack} sx={{ mr: 1 }}>
                                قبلی
                            </Button> */}
                            <Box sx={{ flex: "1 1 auto" }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    رد کردن
                                </Button>
                            )}
                            <Button onClick={handleNext}>{activeStep === steps.length - 1 ? "پایان" : "بعدی"}</Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Card>
    );
}

export { AddClass };
