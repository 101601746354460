import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, TableCell, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { useParams } from "react-router-dom";
import { ColumnPro } from "../../../../components/table-pro/table-pro.component";
import { sortDates, sortNumbers, sortStrings } from "../../utils/sort.utils";
import moment from "jalali-moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, Settings } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { accessLevelName } from "utils/accessLevel.utils";
import { CgInsertAfterR } from "react-icons/cg";
import { getClassStudents } from "api/student/studentApi";
import { insertStudentScore } from "api/score/scoreApi";
import { deleteUser } from "api/user/userApi";
import classes from "./studentClassList.module.scss";
import DownloadIcon from "@mui/icons-material/Download";


interface CellProps extends TableCellProps {
    active: boolean;
}

const TableCellStyled = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
    backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function StudentClassList({ startDate, endDate }: any) {
    const [rows, setRows] = useState<any>([]);
    const [edit, setEdit] = useState(null);
    const [remove, setRemove] = useState<any>(null);
    const [add, setAdd] = useState<any>(null);
    const [filteredRows, setFilteredRows] = useState<any>([]);
    const [acvTypes, setActvTypes] = useState<any>([]);
    const [contructors, setContructors] = useState<any>([]);
    const [selectContructors, setSelectContructors] = useState<any>([]);
    const [selectAcvTypes, setSelectAcvTypes] = useState<any>([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [rolesItem, setRolesItems] = useState([]);

    const refImage = useRef<HTMLInputElement>(null);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    useEffect(() => {
        getAllNeedData();
    }, [startDate, endDate]);

    const classId = useParams().classId;

    const getAllNeedData = async () => {
        // const res: any = await getClassStudents(classId);
        // if (!(res instanceof Error)) {
        //     setRows(res);
        // } else {
        //     toast.error("دریافت داده‌ها با مشکل مواجه شد");
        // }
    };

    // const validation = Yup.object({
    //     grade: Yup.number().nullable().required("نباید خالی باشد"),
    // });
    // const addFormik = useFormik({
    //     initialValues: {
    //         grade: null,
    //         // description: "",
    //     },
    //     validationSchema: validation,
    //     onSubmit: async (values, { resetForm }) => {
    //         console.log({...values, classId:classId}, add.id)
    //         const res: any = await insertStudentScore({...values, classId:Number(classId)}, add.id);
    //         if (!(res instanceof Error)) {
    //            console.log(res);
    //            getAllNeedData();
    //            setAdd(false);
    //            toast.success("درج نمره دانشجو با موفقیت انجام شد");
    //         } else {
    //            toast.error("ثبت اطلاعات با خطا مواجه شد");
    //         }
    //     },
    // });

    // const editFormik = useFormik({
    //     initialValues: {
    //         grade: null,
    //         // description: "",
    //     },
    //     validationSchema: validation,
    //     onSubmit: async (values, { resetForm }) => {
    //         const res: any = await insertStudentScore({...values,classId:Number(classId)}, edit.id);
    //         if (!(res instanceof Error)) {
    //            console.log(res);
    //            getAllNeedData();
    //            setEdit(false);
    //            toast.success("ویرایش کاربر با موفقیت انجام شد");
    //         } else {
    //            toast.error("ثبت اطلاعات با خطا مواجه شد");
    //         }
    //     },
    // });

    // useEffect(() => {
    //     if (edit) {
    //         // const tempRoles: object[] = [];
    //         // rolesItem.map((role: any) => {
    //         //     edit.roles.map((item: any) => {
    //         //         if (item === role.name) {
    //         //             tempRoles.push(role);
    //         //         }
    //         //     });
    //         // });
    //         // setFilteredRoles(tempRoles);

    //         editFormik.setValues({
    //             grade: add.grade,
    //             // description: add.description,
    //         });
    //     }
    // }, [edit]);

    // useEffect(() => {
    //     editFormik.setFieldValue("roles", filteredRoles);
    // }, [filteredRoles]);

    // const handleRemoveCourse = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     const res = await deleteUser(remove);
    //     if (!(res instanceof Error)) {
    //         const tmpRows = [...rows];
    //         const index = tmpRows.findIndex((row) => row.id === remove);
    //         await tmpRows.splice(index, 1);
    //         setRows(tmpRows);
    //         setRemove(null);
    //         toast.success("کاربر با موفقیت حذف شد");
    //     } else {
    //         toast.error("حذف کاربر با خطا مواجه شد");
    //     }
    // };

    // const itemsOfAction = [
    //     {
    //         title: (
    //             <Button color="inherit">
    //                 <CgInsertAfterR style={{fontSize:"1.5rem"}}/>
    //                 &nbsp; ثبت نمره این دانشجو
    //             </Button>
    //         ),
    //         onClick: async (user: any) =>
    //             await new Promise((resolve, reject) => {
    //                 setAdd(user);
    //             }),
    //     },
    //     {
    //         title: (
    //             <Button color="inherit">
    //                 <Edit style={{fontSize:"1.5rem"}}/>
    //                 &nbsp; ویرایش نمره این دانشجو
    //             </Button>
    //         ),
    //         onClick: async (user: any) =>
    //             await new Promise((resolve, reject) => {
    //                 setEdit(user);
    //             }),
    //     },
    //     // {
    //     //     title: (
    //     //         <>
    //     //             <Delete />
    //     //             &nbsp; حذف
    //     //         </>
    //     //     ),
    //     //     onClick: (user: any) => setRemove(user.id),
    //     // },
    // ];

    useEffect(() => {
        getAllNeedData();
    }, []);

    return (
        <>
            <Grid container sx={{ height: "80%" }}>
                <Grid item xs={6} sx={{ m: "auto" }}>
                    <Box className={classes.fianceCard}>
                        <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={2} sx={{ m: "auto" ,mr:2,ml: {lg: 1 }, width: "95%" }}>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <Typography variant="button">نام کلاس</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
                                    مشخصات :
                                </Typography>
                                <Typography variant="subtitle1" sx={{ display: "inline-block" }}>
                                    مشخصات کلاس
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
                                    توضیحات :
                                </Typography>
                                <Typography variant="subtitle1" sx={{ display: "inline-block" }}>
                                    توضیحات کلاس
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
                                    مدت :
                                </Typography>
                                <Typography variant="subtitle1" sx={{ display: "inline-block" }}>
                                    12
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
                                    نام استاد :
                                </Typography>
                                <Typography variant="subtitle1" sx={{ display: "inline-block" }}>
                                    نام استاد
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outlined" startIcon={<DownloadIcon />} sx={{width:"100%"}}>
                                    دانلود مدرک
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            {/* {add ? (
                <ModalIpa
                    open={Boolean(add)}
                    title={`ثبت نمره  ${add.fullName}`}
                    onClose={() => {
                        setAdd(null);
                        addFormik.handleReset(1);
                        setFilteredRoles([]);
                    }}>
                    <form noValidate onSubmit={addFormik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id={"grade"}
                                    variant="outlined"
                                    label={"نمره"}
                                    fullWidth
                                    type="number"
                                    value={addFormik.values.grade}
                                    onChange={addFormik.handleChange}
                                    error={addFormik.touched.grade && Boolean(addFormik.errors.grade)}
                                    //@ts-ignore
                                    helperText={addFormik.touched.grade && addFormik.errors.grade}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    id={"description "}
                                    variant="outlined"
                                    label={"توضیحات"}
                                    fullWidth
                                    type="text"
                                    sx={{".MuiOutlinedInput-input":{
                                      height: "85px",
                                    }}}
                                    value={addFormik.values.description}
                                    onChange={addFormik.handleChange}
                                    error={addFormik.touched.description && Boolean(addFormik.errors.description)}
                                    //@ts-ignore
                                    helperText={addFormik.touched.description && addFormik.errors.description}
                                />
                            </Grid> */}
            {/* <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    ثبت نمره
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
            {edit ? (
                <ModalIpa
                    open={Boolean(add)}
                    title={`ویرایش نمره  ${add.fullName}`}
                    onClose={() => {
                        setEdit(null);
                        editFormik.handleReset(1);
                        setFilteredRoles([]);
                    }}>
                    <form noValidate onSubmit={editFormik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    id={"grade"}
                                    variant="outlined"
                                    label={"نمره"}
                                    fullWidth
                                    type="number"
                                    value={editFormik.values.grade}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.grade && Boolean(editFormik.errors.grade)}
                                    //@ts-ignore
                                    helperText={editFormik.touched.grade && editFormik.errors.grade}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <TextField
                                    id={"description "}
                                    variant="outlined"
                                    label={"توضیحات"}
                                    fullWidth
                                    type="text"
                                    value={editFormik.values.description}
                                    onChange={editFormik.handleChange}
                                    error={editFormik.touched.description && Boolean(editFormik.errors.description)}
                                    //@ts-ignore
                                    helperText={editFormik.touched.description && editFormik.errors.description}
                                /> */}
            {/* </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth color="primary" variant="contained" type={"submit"}>
                                    ثبت نمره
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
            {remove ? (
                <ModalIpa open={Boolean(remove)} title={`آیا از حذف کاربر مطمئن هستید؟`} onClose={() => setRemove(null)}>
                    <form onSubmit={handleRemoveCourse}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ButtonsModal
                                    textSubmit={"تایید"}
                                    textClose={"انصراف"}
                                    onClose={() => {
                                        setRemove(false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null} */}
            {/* </> */}
        </>
    );
}

export { StudentClassList };
