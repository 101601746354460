import axios from "axios";
import {BASE_URL, TIME_OUT} from 'configs/configs';
import {history} from "./history.utils";
import {store} from "../redux/store";
import { actionExit, toggleLoader } from "redux/globalSlice";
import { toast } from "react-toastify";

const http = axios.create();
http.defaults.timeout = TIME_OUT;
http.defaults.baseURL = BASE_URL;

http.interceptors.request.use(function (config) {


  if (localStorage.hasOwnProperty('EllenUser'))
    config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('EllenUser'))['access_token']}`
  config.headers["Access-Control-Allow-Origin"] = "*"
//   store.dispatch({type: TOGGLE_LOADER, data: true})
  store.dispatch(toggleLoader(true));
  return config;
}, function (error) {
  //   store.dispatch({type: TOGGLE_LOADER, data: false})
  store.dispatch(toggleLoader(false));
  return Promise.reject(error);
});

http.interceptors.response.use(function (response) {
  //   store.dispatch({type: TOGGLE_LOADER, data: false})
  store.dispatch(toggleLoader(false));
  return response;
}, function (error) {
  // console.log(error.message === "Network Error")
  //   store.dispatch({type: TOGGLE_LOADER, data: false})
  store.dispatch(toggleLoader(false));
  if (error.response?.status === 401 
    // && !!(store.getState().global.user)
    // (!!store.getState().user)
    ) {
    store.dispatch(actionExit(""))
    toast.error("اجازه دسترسی به این صفحه را ندارید,لطفا دوباره وارد شوید")
    // history.push('/login')
    return
  } else if (error.response?.status === 403) {
    // history.push('403')
    return
  }
  return Promise.reject(error);
});


export default http;