import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { Link, useNavigate } from "react-router-dom";
import { ColumnPro } from "../../../../components/table-pro/table-pro.component";
import { sortDates, sortNumbers, sortStrings } from "../../../utils/sort.utils";
import moment from "jalali-moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, RotateLeft, Settings } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import { getAllClassrooms } from "api/classroom/classroomApi";
import { GetLogedInStudentClasses } from "api/student/studentApi";
import { toggleDetailsTable, toggleConfirmDetailsTable } from "redux/globalSlice";
import { useDispatch } from "react-redux";
import { GetAllCertificate, GetPDF, GetStudentCertificates } from "api/certificate/certificateApi";
import { TbCertificate } from "react-icons/tb";
import { TbCertificateOff } from "react-icons/tb";
import { borderRadius } from "@mui/system";
import { GiGraduateCap } from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";
import { RiNumber1 } from "react-icons/ri";
import { RiNumber2 } from "react-icons/ri";
import { AiOutlineLine } from "react-icons/ai";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import http from "utils/http.utils";

const columns: any = [
  {
    id: "counter",
    label: "#",
    align: "center",
    minWidth: 15,
    state: 0,
    numeric: true,
    show: true,
  },
  {
    id: "action",
    label: "عملیات",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: true,
    show: true,
    // sort: sortDates,
  },
  // {
  //   id: "name",
  //   label: "نام کلاس",
  //   align: "center",
  //   minWidth: 10,
  //   state: 0,
  //   numeric: false,
  //   show: true,
  //   sort: sortStrings,
  // },
  // {
  //   id: "userFullName",
  //   label: "نام کامل دانشجو",
  //   align: "center",
  //   minWidth: 10,
  //   state: 0,
  //   numeric: false,
  //   show: true,
  //   sort: sortStrings,
  // },
  // {
  //   id: "userFatherName",
  //   label: "نام پدر دانشجو",
  //   align: "center",
  //   minWidth: 10,
  //   state: 0,
  //   numeric: false,
  //   show: true,
  //   sort: sortStrings,
  // },
  // {
  //   id: "userBirthDate",
  //   label: "تاریخ تولد دانشجو",
  //   align: "center",
  //   minWidth: 30,
  //   state: 0,
  //   numeric: true,
  //   show: true,
  //   sort: sortDates,
  //   // format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  // },
  // {
  //   id: "userNationalCode",
  //   label: "کد ملی دانشجو",
  //   align: "center",
  //   minWidth: 10,
  //   state: 0,
  //   numeric: false,
  //   show: true,
  //   sort: sortStrings,
  // },
  {
    id: "name",
    label: "جز مورد آزمون",
    align: "center",
    minWidth: 100,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "grade",
    label: "نمره نهایی",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "maxGrade",
    label: "حداکثر نمره",
    align: "center",
    minWidth: 10,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "graduted",
    label: "وضعیت قبولی",
    align: "center",
    minWidth: 100,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "gradeType",
    label: "نوع قبولی",
    align: "center",
    minWidth: 100,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  {
    id: "createdDate",
    label: "تاریخ ساخت",
    align: "left",
    minWidth: 30,
    state: 0,
    numeric: true,
    show: true,
    sort: sortStrings,
    // format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  },
  {
    id: "description",
    label: "توضیحات",
    align: "left",
    minWidth: 100,
    state: 0,
    numeric: false,
    show: true,
    sort: sortStrings,
  },
  // {
  //    id: "createdAt",
  //    label: "تاریخ ثبت",
  //    align: "center",
  //    minWidth: 30,
  //    state: 0,
  //    numeric: true,
  //    show: true,
  //    sort: sortDates,
  //    format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
  // },
];

interface CellProps extends TableCellProps {
  active: boolean;
}

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
  backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function CertificateStudentList({ startDate, endDate }: any) {
  const [rows, setRows] = useState<any>([]);
  const [edit, setEdit] = useState<any>(null);
  const [remove, setRemove] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [showDeaitlsClass, setShowDeaitlsClass] = useState(false);
  const [disableDown, setDisableDown] = useState(false);

  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getAllNeedData = async () => {
    const res: any = await GetStudentCertificates();
    console.log(res);
    if (!(res instanceof Error)) {
      setRows(res);
    } else {
      toast.error("دریافت داده‌ها با مشکل مواجه شد");
    }
  };

  console.log(rows);

  useEffect(() => {
    getAllNeedData();
  }, []);

  useEffect(() => {
    getAllNeedData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (rows) {
      rows?.forEach((row: any) => {
        row?.paymentPlans?.forEach((item: any, index: any) => {
          row["paymentPlans"][index] = Object.entries(item);
        });
      });
    }
  }, [rows]);

  // const itemsOfAction: any = [
  //   {
  //     title: (
  //       <>
  //         <Button color="inherit" disabled={disableDown}>
  //           <DownloadIcon style={{ fontSize: "1.5rem" }} />
  //           &nbsp; دانلود مدرک
  //         </Button>
  //       </>
  //     ),
  //     onClick: async (certsRow: any) =>
  //       await new Promise(async (resolve, reject) => {
  //         if (!disableDown) {
  //           const res: any = await GetPDF(certsRow.id);
  //           if (!(res instanceof Error)) {
  //             // saveAs(res,"مدرک")
  //           } else {
  //             toast.error("دریافت داده‌ها با مشکل مواجه شد");
  //           }
  //         }
  //       }),
  //   },
  //   //     {

  //   //       title: (
  //   //         <>
  //   //          <Delete />
  //   //          &nbsp; حذف
  //   //       </>
  //   //    ),
  //   //    onClick: (course: any) => setRemove(course.id),
  //   //   }
  // ];

  const handleGradeColor: any = (value: any) => {
    if (value) {
      switch (true) {
        case value < 150:
          return (
            <Typography variant="button" sx={{ color: theme.palette.error.light, fontSize: theme.typography.subtitle1.fontSize }}>
              {value}
            </Typography>
          );
        case value >= 150 && value <= 179:
          return (
            <Typography
              variant="button"
              sx={{ color: theme.palette.warning.light, fontSize: theme.typography.subtitle1.fontSize }}>
              {value}
            </Typography>
          );
        case value >= 180 && value <= 194:
          return (
            <Typography
              variant="button"
              sx={{ color: theme.palette.success.light, fontSize: theme.typography.subtitle1.fontSize }}>
              {value}
            </Typography>
          );
        case value >= 195:
          return (
            <Typography
              variant="button"
              sx={{ color: theme.palette.success.light, fontSize: theme.typography.subtitle1.fontSize }}>
              {value}
            </Typography>
          );
      }
    }
  };

  const handleGradeTypeCell: any = (value: any) => {
    if (value) {
      switch (value) {
        case "Excellent":
          return (
            <Typography sx={{ color: theme.palette.primary.main, display: "inline-block" }}>
              <AiFillStar
                style={{
                  display: "inline-block",
                  fontSize: "13.5",
                  position: "relative",
                  top: "6px",
                  right: "3px",
                }}
              />
              <GiGraduateCap style={{ display: "inline-block", fontSize: "24" }} />
              {/* ممتاز */}
            </Typography>
          );
        case "First":
          return (
            <Typography sx={{ color: theme.palette.primary.main, display: "inline-block" }}>
              <RiNumber1
                style={{
                  display: "inline-block",
                  fontSize: "13.5",
                  position: "relative",
                  top: "6px",
                  right: "3px",
                }}
              />
              <GiGraduateCap style={{ display: "inline-block", fontSize: "24" }} />
              {/* درجه1 */}
            </Typography>
          );
        case "Second":
          return (
            <Typography sx={{ color: theme.palette.primary.main, display: "inline-block" }}>
              <RiNumber2
                style={{
                  display: "inline-block",
                  fontSize: "13.5",
                  position: "relative",
                  top: "6px",
                  right: "3px",
                }}
              />
              <GiGraduateCap style={{ display: "inline-block", fontSize: "24" }} />
              {/* درجه2 */}
            </Typography>
          );
        case "Rejected":
          return (
            <Typography sx={{ color: theme.palette.error.light, display: "inline-block", position: "absolute", mr: "25px" }}>
              <AiOutlineLine
                style={{
                  display: "inline-block",
                  fontSize: "32.5",
                  zIndex: 3,
                  position: "relative",
                  top: "-3px",
                  right: "29px",
                  transform: "rotate(45deg)",
                  height: "33px",
                }}
              />
              <GiGraduateCap style={{ display: "inline-block", fontSize: "24" }} />
              {/* درجه1 */}
            </Typography>
          );
      }
    }
  };

  const handleDownloadPDF = async (row: any) => {
    if (!disableDown) {
      await http.get(`api/v2/Certificate/GetPDF/${row.id}`, { responseType: "arraybuffer" }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resume.pdf");
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* <ListInfo /> */}
        <Grid item xs={12} style={{ width: 10 }}>
          <Paper>
            <TableProIpa
              columns={columns}
              bodyFunc={(column: any, row: any, index: any) => {
                const head = column.id;
                const value = row[column.id];
                if (head === "action") {
                  return (
                    <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                      {/* <MenuActionTable
                        menuId={row.id}
                        items={itemsOfAction}
                        icon={<Settings />}
                        user={row}
                      /> */}
                      <Button
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        onClick={() => handleDownloadPDF(row)}
                        disabled={!row?.graduted}>
                        دانلود مدرک
                      </Button>
                    </TableCellStyled>
                  );
                } else if (head === "graduted") {
                  console.log(value);
                  return (
                    <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        style={{
                          width: column.minWidth,
                          backgroundColor: value ? theme.palette.success.main : theme.palette.error.main,
                          color: theme.palette.common.white,
                          borderRadius: "7px",
                          // paddingRight: 15,
                          // paddingLeft: 15
                        }}>
                        {value ? (
                          <>
                            <TbCertificate style={{ fontSize: "25px" }} />
                            <Typography style={{ fontSize: "15px" }}>قبول</Typography>
                          </>
                        ) : (
                          <>
                            <TbCertificateOff style={{ fontSize: "25px" }} />
                            <Typography style={{ fontSize: "15px" }}>مردود</Typography>
                          </>
                        )}
                      </Button>
                    </TableCellStyled>
                  );
                } else if (head === "grade") {
                  return (
                    <TableCell key={column.id + row.id} align={column.align}>
                      <Button
                        variant={"text"}
                        color={"info"}
                        style={{
                          width: column.minWidth,
                        }}>
                        {handleGradeColor(value)}
                      </Button>
                    </TableCell>
                  );
                } else if (head === "name") {
                  return (
                    <TableCell key={column.id + row.id} align={column.align}>
                      <Button
                        variant={"text"}
                        color={"info"}
                        style={{
                          width: column.minWidth,
                        }}>
                        {value?.length > 4
                          ? value?.slice(0, 5)?.map((item: any, index: any) => {
                              console.log(index);
                              console.log(item);

                              if (index + 1 !== 5) {
                                return `${item}\n`;
                              } else {
                                return `و...`;
                              }
                            })
                          : value?.map((item: any) => {
                              return `${item}\n`;
                            })}
                      </Button>
                    </TableCell>
                  );
                } else if (head === "gradeType") {
                  return (
                    <TableCell key={column.id + row.id} align={column.align}>
                      <Button
                        variant={"text"}
                        color={"inherit"}
                        style={{
                          width: column.minWidth,
                        }}>
                        {handleGradeTypeCell(value)}
                      </Button>
                    </TableCell>
                  );
                } else if (head === "classLink") {
                  return (
                    <TableCell
                      key={column.id + row.id}
                      align={column.align}
                      onClick={() => dispatch(toggleDetailsTable(false))}
                      onMouseOut={() => {
                        dispatch(toggleConfirmDetailsTable(false));
                        dispatch(toggleDetailsTable(true));
                      }}>
                      <a href={value} rel="noreferrer" target="_blank" id="class-link-tag">
                        {value}
                      </a>
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.format && value ? column.format(value) : value}
                  </TableCell>
                );
              }}
              rows={rows ?? []}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export { CertificateStudentList };
