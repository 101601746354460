import http from "../../utils/http.utils";

type Id = string;

export function getAllStudents() {
    return http
        .get("/api/v2/Users/Students")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function getClassStudents(classId:Id) {
    return http
        .get(`/api/v2/ClassRooms/GetStudents/${classId}`)
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function GetLogedInStudentClasses() {
    return http
        .get("/api/v2/ClassRooms/GetLogedInStudentClasses")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function StudentDashboardInfo() {
    return http
        .get("/api/v2/ClassRooms/StudentDashboard")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function GetStudentClassesSchedule() {
    return http
        .get("/api/v2/classrooms/GetStudentClassesSchedule")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

