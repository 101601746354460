import _ from "lodash";
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { memo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { selectContrastMainTheme } from 'app/store/fuse/settingsSlice';
import { alpha, Card, Divider, Tab, Tabs, Typography  , useTheme} from "@mui/material";
import { makeStyles} from '@mui/styles';


const useStyles = makeStyles((theme:any) => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    color: theme.palette.background.paper,
    borderRadius:"5px !important" 
  },
}));

function Widget1({ReceivedData}:any) {
 

  const classes = useStyles();
  const theme = useTheme();
  

//   const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));
  const data = _.merge({}, ReceivedData);

  const [tabValue, setTabValue] = useState(1);
  const series = data.series[Object.keys(data.series)[tabValue]];

  _.setWith(data, 'options.fill.colors', [theme.palette.secondary.main]);
  _.setWith(data, 'options.markers.colors', [theme.palette.secondary.main]);
  _.setWith(data, 'options.stroke.colors', [theme.palette.primary.contrastText]);
  _.setWith(data, 'options.markers.strokeColors', [theme.palette.primary.contrastText]);
  _.setWith(data, 'options.grid.borderColor', alpha(theme.palette.primary.contrastText, 0.3));
  
  

  return (
    // <ThemeProvider theme={contrastTheme}>
      <Card className={clsx(classes.root )}>
        <div className="container relative p-16 sm:p-24 flex flex-col sm:flex-row justify-between items-center">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className="flex flex-col items-center sm:items-start mb-16 sm:mb-0">
              <Typography className="h2 font-medium" >
                {data.title}
              </Typography>
              <Typography className="h5" color="textSecondary">
              بر اساس ماه
                            </Typography>
            </div>
          </motion.div>

          <div className="flex flex-row items-center">
            <Tabs
              value={tabValue}
              onChange={(event:any, value:any) => setTabValue(value)}
              indicatorColor="primary"
              variant="scrollable"
              // scrollButtons="false"
              className="w-full -mx-4 min-h-40"
              classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
              TabIndicatorProps={{
                style:{
                  width: "0px",
                }
              }}
              sx={{color:theme.palette.common.white}}
            >
              {Object.keys(data.series).map((key) => (
                <Tab
                  key={key}
                  className="text-14 min-h-40 min-w-64 mx-4 capitalize"
                  disableRipple
                  label={key}
                   sx={{color:theme.palette.grey[300]}}
                />
              ))}
            </Tabs>
          </div>
        </div>
        <div className="container relative h-200 sm:h-256 pb-16">
          <ReactApexChart
            options={data.options}
            series={series}
            type={data.options.chart.type}
            height={data.options.chart.height}
          />
        </div>
      </Card>
    // </ThemeProvider>
  );
}

export default memo(Widget1);
