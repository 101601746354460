import styled from "@emotion/styled";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Paper,
  FormLabel,
  RadioGroup,
  Radio,
  Card,
  CardContent,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  ListItem,
  List,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "jalali-moment";
import { DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import { PhotoCamera } from "@mui/icons-material";
import { changePassword, getUser, putUser } from "api/user/userApi";

const useStyle = makeStyles((theme: any) => ({
  cardContainer: {},
  appBarStyle: {
    borderTopLeftRadius: theme.shape.borderRadius + 5,
    borderTopRightRadius: theme.shape.borderRadius + 5,
  },
}));

const CaptionTypoStyle = styled(Typography)((theme: any) => ({
  wordBreak: "break-word",
}));

function EditDetails() {
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(null);

  const classes = useStyle();

  const user = useSelector((state: any) => state.global.user);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const getAllNeedData = async () => {
    const res: any = await getUser(user.userId);
    if (!(res instanceof Error)) {
      setEdit(res);
    } else {
      toast.error("دریافت اطلاعات با مشکل مواجه شد");
    }
  };

  useEffect(() => {
    getAllNeedData();
  }, []);

  const validation = Yup.object({
    // firstName: Yup.string()
    // .required("نباید خالی باشد")
    // .test(
    //     "نام را به درستی وارد کنید",
    //     "نام را به درستی وارد کنید",
    //     //@ts-ignore
    //     function (name = "") {
    //         const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
    //         return re.test(String(name).toLowerCase());
    //     }
    // ),
    // lastName: Yup.string()
    // .required("نباید خالی باشد")
    // .test(
    //     "نام خانوادگی را به درستی وارد کنید",
    //     "نام خانوادگی را به درستی وارد کنید",
    //     //@ts-ignore
    //     function (name = "") {
    //         const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
    //         return re.test(String(name).toLowerCase());
    //     }
    // ),
    fullName: Yup.string()
      .required("نباید خالی باشد")
      .test(
        "نام را به درستی وارد کنید",
        "نام را به درستی وارد کنید",
        //@ts-ignore
        function (name = "") {
          const re = /^(?=.{1,70}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
          return re.test(String(name).toLowerCase());
        }
      ),
    fatherName: Yup.string()
      .nullable()
      .required("نباید خالی باشد")
      .test(
        "نام پدر را به درستی وارد کنید",
        "نام پدر را به درستی وارد کنید",
        //@ts-ignore
        function (name = "") {
          const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
          return re.test(String(name).toLowerCase());
        }
      ),
    gender: Yup.string().required("نباید خالی باشد"),
    nationalCode: Yup.string()
      .required("نباید خالی باشد")
      .test(
        "کد ملی را به درستی وارد کنید",
        "کد ملی را به درستی وارد کنید",
        //@ts-ignore
        function (code = "") {
          const re = /^\d+$/;
          return re.test(String(code).toLowerCase());
        }
      ),
    //   nationality:Yup.string()
    //   .required("نباید خالی باشد")
    //   .test(
    //       "نام پدر را به درستی وارد کنید",
    //       "نام پدر را به درستی وارد کنید",
    //       //@ts-ignore
    //       function (name = "") {
    //           const re = /^(?=.{1,20}$)(?![_.])(?!.*[_.]{2})(?![a-zA-Z0-9._@])[\u0600-\u06FF\s]+(?<![_.])$/;
    //           return re.test(String(name).toLowerCase());
    //       }
    //   ),
    //   roles: Yup.array().nullable().required("نباید خالی باشد"),
    email: Yup.string()
      .required("نباید خالی باشد")
      .test("ایمیل معتبر نیست", "ایمیل معتبر نیست", function (email = "") {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }),
    //   image: Yup.string().required("نباید خالی باشد"),
    //   imageName: Yup.string().required("نباید خالی باشد"),
    // imageDesc: Yup.string().required("نباید خالی باشد"),
    birthDate: Yup.string()
      .nullable()
      .required("نباید خالی باشد")
      .test("تاریخ معتبر نیست", "تاریخ معتبر نیست", function (value: string) {
        return moment(value).isValid();
      }),
    phoneNumber: Yup.string()
      .nullable()
      .required("نباید خالی باشد")
      .test("شماره موبایل را به درستی وارد کنید", "شماره موبایل را به درستی وارد کنید", function (value = "") {
        const reg = /^09[0-9]{9}/;
        return reg.test(String(value).toLocaleLowerCase());
      }),
  });

  const editFormik = useFormik({
    initialValues: {
      // firstName: "",
      // lastName: "",
      // userName: "",
      fullName: "",
      fatherName: "",
      nationalCode: "",
      gender: "Male",
      // isActive: true,
      // roles: null,
      nationality: "",
      birthDate: null,
      // age: null,
      // image: "",
      // imageName: "",
      // imageDesc: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      alert("d");
      const res: any = await putUser(
        {
          UserName: values.nationalCode,
          FullName: values.fullName,
          FatherName: values.fatherName,
          NationalCode: values.nationalCode,
          Nationality: values.nationality,
          Gender: values.gender,
          BirthDate: values.birthDate,
          PhoneNumber: values.phoneNumber,
          Email: values.email,
        },
        edit.id
      );
      if (!(res instanceof Error)) {
        setEdit(false);
        toast.success("ویرایش اطاعات با موفقیت انجام شد");
      } else {
        toast.error("ثبت اطلاعات با خطا مواجه شد");
      }
    },
  });

  useEffect(() => {
    if (edit) {
      console.log(edit);
      editFormik.setValues({
        // firstName: edit.fullName.trim().split(/\s+/)[0],
        // lastName: edit.fullName.trim().split(/\s+/)[1],
        // userName: edit.userName,
        fullName: edit.fullName,
        fatherName: edit.fatherName,
        nationalCode: edit.nationalCode,
        gender: edit.gender,
        nationality: edit.nationality,
        birthDate: edit.birthDate,
        // age: edit.age,
        // image: edit.image,
        // imageName: edit.imageName,
        // imageDesc: edit.imageDesc,
        email: edit.email,
        phoneNumber: edit.phoneNumber,
      });
    }
  }, [edit]);

  const passValidation = Yup.object({
    CurrentPassword: Yup.string()
      .required("نباید خالی باشد")
    //   .test("رمز شما باید حداقل 8 رقمی باشد", "رمز شما باید حداقل 8 رقمی باشد", function (value = "") {
    //     return value?.length > 7;
    //   })
    ,
      NewPassword: Yup.string()
      .required("نباید خالی باشد")
      .test("رمز شما باید حداقل 8 رقمی باشد", "رمز شما باید حداقل 8 رقمی باشد", function (value = "") {
        return value?.length > 7;
      }),
      ConfirmNewPassword: Yup.string()
      .required("نباید خالی باشد")
      .test("رمز ها مطابقت ندارند", "رمز ها مطابقت ندارند", function (value = "") {
        return value === this.parent.NewPassword;
      }),
  });

  const passFormik = useFormik({
    initialValues: {
      CurrentPassword: "",
      NewPassword: "",
      ConfirmNewPassword: "",
    },
    validationSchema: passValidation,
    onSubmit: async (values, { resetForm }) => {
        delete values.ConfirmNewPassword
        const res: any = await changePassword({...values , UserName:user.userName});
        if (!(res instanceof Error)) {
          toast.success("ویرایش رمز با موفقیت انجام شد");
          //@ts-ignore
        } else if (!res.isSuccess && res.message === "Failed : PasswordMismatch") {
            passFormik.handleReset(1)
            toast.error("رمز فعلی درست نیست");
        }else{
            toast.error("ثبت اطلاعات با خطا مواجه شد");
        }
    },
  });

  return (
    <motion.div variants={container} initial="hidden" animate="show">
      <Grid container spacing={4}>
        <Grid item xs={12} xl={9}>
          <Paper
            elevation={3}
            className={clsx(classes.cardContainer, "mb-7")}
            sx={{ borderRadius: "5px" }}
            component={motion.div}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}>
            <AppBar position="static" elevation={0} className={classes.appBarStyle}>
              <Toolbar className="px-8">
                <Typography variant="subtitle1" className="px-7">
                  تغییر مشخصات
                </Typography>
              </Toolbar>
            </AppBar>

            <form noValidate onSubmit={editFormik.handleSubmit}>
              <Grid container spacing={3} sx={{ p: 2 }}>
                <Grid item xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.5 } }}>
                  <TextField
                    id={"fullName"}
                    variant="outlined"
                    label={"نام کامل"}
                    fullWidth
                    required
                    value={editFormik.values.fullName}
                    onChange={editFormik.handleChange}
                    error={editFormik.touched.fullName && Boolean(editFormik.errors.fullName)}
                    //@ts-ignore
                    helperText={editFormik.touched.fullName && editFormik.errors.fullName}
                  />
                </Grid>
                {/* <Grid item sm={6} xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.6 } }}>
                                    <TextField
                                        id={"lastName"}
                                        variant="outlined"
                                        label={"نام خانوادگی"}
                                        fullWidth
                                        required
                                        value={editFormik.values.lastName}
                                        onChange={editFormik.handleChange}
                                        error={editFormik.touched.lastName && Boolean(editFormik.errors.lastName)}
                                        //@ts-ignore
                                        helperText={editFormik.touched.lastName && editFormik.errors.lastName}
                                    />
                                </Grid> */}
                {/* <Grid item sm={6} xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.7 } }}>
                                    <TextField
                                        id={"userName"}
                                        variant="outlined"
                                        label={"نام کاربری"}
                                        fullWidth
                                        required
                                        value={editFormik.values.userName}
                                        onChange={editFormik.handleChange}
                                        error={editFormik.touched.userName && Boolean(editFormik.errors.userName)}
                                        helperText={editFormik.touched.userName && editFormik.errors.userName}
                                    />
                                </Grid> */}
                <Grid item sm={6} xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.8 } }}>
                  <TextField
                    id={"fatherName"}
                    variant="outlined"
                    label={"نام پدر"}
                    fullWidth
                    value={editFormik.values.fatherName}
                    onChange={editFormik.handleChange}
                    error={editFormik.touched.fatherName && Boolean(editFormik.errors.fatherName)}
                    helperText={editFormik.touched.fatherName && editFormik.errors.fatherName}
                  />
                </Grid>
                <Grid item sm={6} xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.9 } }}>
                  <TextField
                    id={"nationalCode"}
                    type="text"
                    variant="outlined"
                    label={"کد ملی"}
                    fullWidth
                    value={editFormik.values.nationalCode}
                    onChange={editFormik.handleChange}
                    error={editFormik.touched.nationalCode && Boolean(editFormik.errors.nationalCode)}
                    helperText={editFormik.touched.nationalCode && editFormik.errors.nationalCode}
                  />
                </Grid>
                <Grid item sm={6} xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1 } }}>
                  <TextField
                    id={"nationality"}
                    variant="outlined"
                    label={"ملیت"}
                    fullWidth
                    value={editFormik.values.nationality}
                    onChange={editFormik.handleChange}
                    error={editFormik.touched.nationality && Boolean(editFormik.errors.nationality)}
                    helperText={editFormik.touched.nationality && editFormik.errors.nationality}
                  />
                </Grid>
                <Grid item sm={6} xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1.1 } }}>
                  <LocalizationProvider dateAdapter={AdapterJalali}>
                    <DatePicker
                      //    okText={"تایید"}
                      //    cancelText={"لغو"}
                      label="تاریخ تولد"
                      mask="____/__/__"
                      value={editFormik.values.birthDate}
                      onChange={(newValue: any) =>
                        moment(newValue, "YYYY/MM/DD").isValid() && editFormik.setFieldValue("birthDate", moment(newValue).locale("en").format("YYYY-MM-DD"))
                      }
                      renderInput={(params: any) => <TextField fullWidth {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1.3 } }}>
                  <TextField
                    id={"email"}
                    variant="outlined"
                    label={"ایمیل"}
                    required
                    type={"email"}
                    fullWidth
                    value={editFormik.values.email}
                    onChange={editFormik.handleChange}
                    error={editFormik.touched.email && Boolean(editFormik.errors.email)}
                    helperText={editFormik.touched.email && editFormik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1.3 } }}>
                  <TextField
                    id={"phoneNumber"}
                    type="text"
                    variant="outlined"
                    label={"شماره موبایل"}
                    required
                    fullWidth
                    value={editFormik.values.phoneNumber}
                    onChange={editFormik.handleChange}
                    error={editFormik.touched.phoneNumber && Boolean(editFormik.errors.phoneNumber)}
                    helperText={editFormik.touched.phoneNumber && editFormik.errors.phoneNumber}
                  />
                </Grid>
                <Grid item sm={6} xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1.4 } }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">جنسیت</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name={"gender"}
                      id="gender"
                      style={{ flexDirection: "row" }}
                      value={editFormik.values.gender}
                      onChange={(event: any) => editFormik.setFieldValue("gender", event.target.value)}
                      // @ts-ignore
                      error={editFormik.touched.gender && Boolean(editFormik.errors.gender)}>
                      <FormControlLabel value={"Male"} control={<Radio />} label="مرد" />
                      <FormControlLabel value={"Female"} control={<Radio />} label="زن" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth color="secondary" variant="contained" type={"submit"} sx={{ color: (theme: any) => theme.palette.common.white }}>
                    ثبت مشخصات
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} xl={3}>
          {/* <Card component={motion.div} className="w-full mb-32 rounded-16 shadow">
                <AppBar position="static" elevation={0}>
                    <Toolbar className="px-8">
                        <Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-medium">
                            Friends
                        </Typography>
                        <Button color="inherit" size="small">
                            See 454 more
                        </Button>
                    </Toolbar>
                </AppBar>
                <CardContent className="flex flex-wrap p-16"> */}
          {/* {friends.map((friend: any) => (
                        <img key={friend.id} className="w-64 m-4 rounded-16 block" src={friend.avatar} alt={friend.name} />
                    ))} */}
          {/* </CardContent> */}
          {/* </Card> */}

          <Paper
            elevation={3}
            className={clsx(classes.cardContainer, "mb-7")}
            sx={{ borderRadius: "5px" }}
            component={motion.div}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 1.8 } }}>
            <AppBar position="static" elevation={0} className={classes.appBarStyle}>
              <Toolbar className="px-8">
                <Typography variant="subtitle1" className="px-7">
                  تغییر رمز عبور
                </Typography>
                {/* <Button color="inherit" size="small">
                            See 6 more
                        </Button>  */}
              </Toolbar>
            </AppBar>
            <CardContent className="p-0">
              <List className="p-0">
                <form action="" noValidate onSubmit={passFormik.handleSubmit}>
                  <Grid container alignItems={"center"} justifyContent={"center"} spacing={4}>
                    <Grid item xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}>
                      <TextField
                        id={"CurrentPassword"}
                        variant="outlined"
                        label={"رمز فعلی"}
                        required
                        type={"password"}
                        fullWidth
                        value={passFormik.values.CurrentPassword}
                        onChange={passFormik.handleChange}
                        error={passFormik.touched.CurrentPassword && Boolean(passFormik.errors.CurrentPassword)}
                        helperText={passFormik.touched.CurrentPassword && passFormik.errors.CurrentPassword}
                      />
                    </Grid>
                    <Grid item xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}>
                      <TextField
                        id={"NewPassword"}
                        variant="outlined"
                        label={"رمز جدید"}
                        required
                        type={"password"}
                        fullWidth
                        value={passFormik.values.NewPassword}
                        onChange={passFormik.handleChange}
                        error={passFormik.touched.NewPassword && Boolean(passFormik.errors.NewPassword)}
                        helperText={passFormik.touched.NewPassword && passFormik.errors.NewPassword}
                      />
                    </Grid>
                    <Grid item xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 2.2 } }}>
                      <TextField
                        id={"ConfirmNewPassword"}
                        variant="outlined"
                        label={"تکرار رمز جدید"}
                        required
                        type={"password"}
                        fullWidth
                        value={passFormik.values.ConfirmNewPassword}
                        onChange={passFormik.handleChange}
                        error={passFormik.touched.ConfirmNewPassword && Boolean(passFormik.errors.ConfirmNewPassword)}
                        helperText={passFormik.touched.ConfirmNewPassword && passFormik.errors.ConfirmNewPassword}
                      />
                    </Grid>
                    <Grid item xs={12} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 2.4 } }}>
                      <Button fullWidth color="secondary" variant="contained" type={"submit"} sx={{ color: (theme: any) => theme.palette.common.white }}>
                        تغییر رمز عبور
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </List>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </motion.div>
  );
}

export { EditDetails };
