import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Switch,
    TableCell,
    TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import "date-fns";
import { ButtonsModal, MenuActionTable, ModalIpa, TableProIpa } from "components";
import { sortDates, sortNumbers, sortStrings } from "../../../utils/sort.utils";
import moment from "jalali-moment";
import { DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ListInfo } from "pages/list-info/listInfo";
import { Delete, Edit, Settings } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TableCellProps } from "@mui/material/TableCell";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { daysItem } from "../../../utils/dayOfWeek.utils";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toggleConfirmDetailsTable, toggleDetailsTable } from "redux/globalSlice";
import { EditClassInformation, EditClassroomMembers, getAllClassrooms } from "api/classroom/classroomApi";
import { deleteCourse, getCourses } from "api/course/courseApi";
import { getAllStudents } from "api/student/studentApi";
import { getAllTeachers } from "api/teacher/teacherApi";

const columns: any = [
    {
        id: "counter",
        label: "#",
        align: "center",
        minWidth: 15,
        state: 0,
        numeric: true,
        show: true,
    },
    {
        id: "name",
        label: "نام کلاس",
        align: "center",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "teacherName",
        label: "نام استاد",
        align: "center",
        minWidth: 10,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "startTime",
        label: "ساعت شروع",
        align: "left",
        minWidth: 30,
        state: 0,
        numeric: true,
        show: true,
        sort: sortStrings,
        format: (value: string) => new Date(value).toLocaleTimeString("fa-IR"),
    },
    {
        id: "endTime",
        label: "ساعت پایان",
        align: "left",
        minWidth: 30,
        state: 0,
        numeric: true,
        show: true,
        sort: sortStrings,
        format: (value: string) => new Date(value).toLocaleTimeString("fa-IR"),
    },
    {
        id: "startDate",
        label: "تاریخ شروع",
        align: "center",
        minWidth: 30,
        state: 0,
        numeric: true,
        show: true,
        sort: sortDates,
        format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
    },
    {
        id: "endDate",
        label: "تاریخ پایان",
        align: "center",
        minWidth: 30,
        state: 0,
        numeric: true,
        show: true,
        sort: sortDates,
        format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
    },
    // {
    //     id: "paymentPlans",
    //     label: "نام پلن مالی",
    //     align: "left",
    //     minWidth: 130,
    //     state: 0,
    //     numeric: false,
    //     show: true,
    //     sort: sortStrings,
    // },
    {
        id: "days",
        label: "روزهای تشکیل کلاس",
        align: "left",
        minWidth: 130,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    // {
    //     id: "examsDateTime",
    //     label: "تاریخ امتحان",
    //     align: "left",
    //     minWidth: 30,
    //     state: 0,
    //     numeric: true,
    //     show: true,
    //     sort: sortStrings,
    //     format: (value: string) => value.length !== 0 ? new Date(value).toLocaleDateString("fa-IR") : "ندارد",
    // },
    // {
    //     id: "examsDateTime",
    //     label: "ساعت امتحان",
    //     align: "left",
    //     minWidth: 30,
    //     state: 0,
    //     numeric: true,
    //     show: true,
    //     sort: sortStrings,
    //     format: (value: string) => value.length !== 0 ? new Date(value).toLocaleTimeString("fa-IR") : "ندارد",
    // },
    {
        id: "complated",
        label: "تمام شده",
        align: "left",
        minWidth: 60,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "classLink",
        label: "لینک کلاس",
        align: "left",
        minWidth: 100,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    {
        id: "description",
        label: "توضیحات",
        align: "left",
        minWidth: 100,
        state: 0,
        numeric: false,
        show: true,
        sort: sortStrings,
    },
    // {
    //    id: "createdAt",
    //    label: "تاریخ ثبت",
    //    align: "center",
    //    minWidth: 30,
    //    state: 0,
    //    numeric: true,
    //    show: true,
    //    sort: sortDates,
    //    format: (value: string) => new Date(value).toLocaleDateString("fa-IR"),
    // },
    {
        id: "action",
        label: "عملیات",
        align: "center",
        minWidth: 10,
        state: 0,
        numeric: true,
        show: true,
        // sort: sortDates,
    },
];

interface CellProps extends TableCellProps {
    active: boolean;
}

const TableCellStyled = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== "active",
})<CellProps>(({ theme, active }) => ({
    backgroundColor: active ? "inherit" : "rgba(255,0,0,.2)",
}));

function ClassList({ startDate, endDate }: any) {
    const [rows, setRows] = useState<any>([]);
    const [editClassInfo, setEditClassInfo] = useState(null);
    const [editClassFinPlan, setEditClassFinPlan] = useState(null);
    const [editClassTeachSted, setEditClassTeachSted] = useState(null);
    const [remove, setRemove] = useState<any>(null);
    const [filteredRows, setFilteredRows] = useState<any>([]);
    const [acvTypes, setActvTypes] = useState<any>([]);
    const [contructors, setContructors] = useState<any>([]);
    const [selectContructors, setSelectContructors] = useState<any>([]);
    const [selectAcvTypes, setSelectAcvTypes] = useState<any>([]);
    const [courseNameOpt, setCourseNameOpt] = useState<any>([]);
    const [studentsNameOpt, setStudentsNameOpt] = useState<any>(null);
    const [teachersNameOpt, setTeachersNameOpt] = useState<any>(null);
    const [compatibleDaysList, setCompatibleDaysList] = useState<any>([]);
    const [compatibleCourse, setCompatibleCourse] = useState({});

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const dispatch = useDispatch();

    useEffect(() => {
        getAllNeedData();
    }, [startDate, endDate]);

    const getAllNeedData = async () => {
        const res: any = await getAllClassrooms();
        await getCoursesList();
        if (!(res instanceof Error)) {
            setRows(res);
        } else {
            toast.error("دریافت داده‌ها با مشکل مواجه شد");
        }
    };


    const getCoursesList = async () => {
        const resCourse: any = await getCourses();
        if (!(resCourse instanceof Error)) {
            setCourseNameOpt(resCourse);
        } else {
            toast.error("دریافت اطلاعات با مشکل مواجه شد");
        }
    };

    const getTeachStedList = async () => {
        const resStudents: any = await getAllStudents();
        const resTeachers: any = await getAllTeachers();
        if (!(resStudents instanceof Error) && !(resTeachers instanceof Error)) {
            setStudentsNameOpt(resStudents);
            setTeachersNameOpt(resTeachers);
        } else {
            toast.error("دریافت اطلاعات با مشکل مواجه شد");
        }
    };


    useEffect(() => {
        if (Boolean(editClassInfo)) {
            getCoursesList();
        }
    }, [editClassInfo]);

    useEffect(() => {
        if (Boolean(editClassTeachSted)) {
            getTeachStedList();
        }
    }, [editClassTeachSted]);


    //ClassInfo Edit Formik

    const editInfoValidation = Yup.object({
        CourseName: Yup.object().nullable().required("نباید خالی باشد"),
        ClassName: Yup.string().nullable().required("نباید خالی باشد"),
        ClassLink: Yup.string().nullable().required("نباید خالی باشد"),
        StartTime: Yup.string().nullable().required("نباید خالی باشد"),
        EndTime: Yup.string().nullable().required("نباید خالی باشد"),
        DaysOfWeek: Yup.array().nullable()
            .of(
                Yup.object().shape({
                    id: Yup.number(),
                    title: Yup.string(),
                })
            )
            .nullable()
            .required("نباید خالی باشد"),
        // ClassDescription: Yup.string().nullable().required("نباید خالی باشد"),
        // examDayTime: Yup.mixed().nullable()
        // .required("نباید خالی باشد"),
        StartDate: Yup.string().nullable().required("نباید خالی باشد"),
        EndDate: Yup.string().nullable().required("نباید خالی باشد"),
        // examTime: Yup.array().required("نباید خالی باشد"),
    });


    const editInfoFormik = useFormik({
        initialValues: {
            CourseName: null,
            ClassName: "",
            ClassLink: "",
            StartDate: null,
            EndDate: null,
            StartTime: "",
            EndTime: "",
            DaysOfWeek: null,
            ClassDescription: "",
            classCompleted: false,
            examDayTime: null,
            // examTime: "",
        },
        validationSchema: editInfoValidation,
        onSubmit: async (values, { resetForm }) => {
            let examDateTimeArray:any = [];
            if(values.examDayTime !== null){
                if(Object.prototype.toString.call(values.examDayTime) === '[object Array]'){
                    examDateTimeArray = values.examDayTime 
                }else{
                    examDateTimeArray.push(values.examDayTime);
                }
            }else{

            }
             const res = await EditClassInformation({
                courseId: values.CourseName.id,
                     name: values.ClassName,
                     classLink: values.ClassLink,
                     description: values.ClassDescription,
                     startDate: values.StartDate,
                     endDate: values.EndDate,
                     startTime: values.StartTime,
                     endTime: values.EndTime,
                     days: values.DaysOfWeek,
                     complated: values.classCompleted,
                     examsDateTime: examDateTimeArray,
             } , editClassInfo.id);
             if (!(res instanceof Error)) {
                getAllNeedData()
                resetForm();
                setEditClassInfo(null)
                toast.success("ویرایش مشخصات کلاس با موفقیت انجام شد");
             } else {
                toast.error("ویرایش کلاس با خطا مواجه شد");
             }
        },
    });

    useEffect(() => {
        if (editClassInfo) {
            const tempDays: object[] = [];
            daysItem?.map((day: any) => {
                editClassInfo?.days?.map((item: any) => {
                    if (day.name === item) {
                        tempDays.push(day);
                    }
                });
            });

            setCompatibleDaysList(tempDays)

            console.log(courseNameOpt)

             courseNameOpt?.map((course:any)=>{
                console.log(course)
                    if(course?.id === editClassInfo?.courceId){
                       setCompatibleCourse(course)
                    }
                })

            //  let tempCourse:{} = {};   
            //  courseNameOpt.forEach((course:any) =>{
            //     console.log(course)
            //     if(course.id === editClassInfo.courceId){
            //         console.log(course)
            //         tempCourse = course;
            //         console.log(tempCourse)
                    
            //  }
            //  })
               
            // })

            // console.log(tempCourse)

            // setCompatibleCourse(tempCourse)

            editInfoFormik.setValues({
                CourseName: compatibleCourse,
                ClassName: editClassInfo.name,
                ClassLink: editClassInfo.classLink,
                StartDate: editClassInfo.startDate,
                EndDate: editClassInfo.endDate,
                StartTime: editClassInfo.startTime,
                EndTime: editClassInfo.endTime,
                DaysOfWeek: tempDays,
                ClassDescription: editClassInfo.description,
                classCompleted: editClassInfo.complated,
                examDayTime: editClassInfo.examsDateTime,
            });
        }
    }, [editClassInfo]);

    
    useEffect(() => {
        editInfoFormik.setFieldValue("DaysOfWeek", compatibleDaysList)
    }, [compatibleDaysList])


    useEffect(() => {
        editInfoFormik.setFieldValue("CourseName", compatibleCourse)
    }, [compatibleCourse])
    
    //end

    //Teachers & Students Edit Formik

    const editTeachStedValidation = Yup.object({
        StudentName: Yup.array().nullable().required("نباید خالی باشد"),
        TeacherName: Yup.object().nullable().required("نباید خالی باشد"),
    });

    const editTeachStedFormik = useFormik({
        initialValues: {
            StudentName: null,
            TeacherName: null,
        },
        validationSchema: editTeachStedValidation,
        onSubmit: async (values, { resetForm }) => {
            const studentsId = values.StudentName.map((item: any) => {
                return item.id;
            });
            console.log(values)
             const res = await EditClassroomMembers({ StudentsId:studentsId , TeacherId: values.TeacherName.id }, editClassTeachSted.id);
             if (!(res instanceof Error)) {
                getAllNeedData();
                resetForm();
                setEditClassTeachSted(null);
                toast.success("ویرایش کاربران کلاس با موفقیت انجام شد");
             } else {
                toast.error("ویرایش کاربران کلاس با خطا مواجه شد");
             }
        },
    });

    useEffect(() => {
        if (editClassTeachSted) {
            teachersNameOpt?.map((teach: any) => {
                if (teach.fullName === editClassTeachSted.teacherName) {
                    editTeachStedFormik.setFieldValue("TeacherName", teach);
                }
            });
            // studentsNameOpt?.map((std: any) => {
            //     if (teach.fullName === editClassTeachSted.teacherName) {
            //         console.log(teach);
            //         editTeachStedFormik.setFieldValue("TeacherName", teach);
            //     }
            // });
            editTeachStedFormik.setFieldValue("StudentName",editClassTeachSted.students)
        }
    }, [editClassTeachSted ,teachersNameOpt]);




    //end
    useEffect(() => {
        getAllNeedData();
    }, []);

    useEffect(() => {
        if (rows) {
            rows?.forEach((row: any) => {
                row?.paymentPlans?.forEach((item: any, index: any) => {
                    row["paymentPlans"][index] = Object.entries(item);
                });
            });
        }
    }, [rows]);

    // const handleRemoveCourse = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     const res = await deleteCourse(remove);
    //     if (!(res instanceof Error)) {
    //         const tmpRows = [...rows];
    //         const index = tmpRows.findIndex((row) => row.id === remove);
    //         await tmpRows.splice(index, 1);
    //         setRows(tmpRows);
    //         setRemove(null);
    //         toast.success("دوره با موفقیت حذف شد");
    //     } else {
    //         toast.error("حذف کاربر با خطا مواجه شد");
    //     }
    // };

    const itemsOfAction = [
        {
            title: (
                <>
                 <Button color="inherit" >
                    <Edit style={{fontSize:"1.5rem"}}/>
                    &nbsp; ویرایش مشخصات کلاس
                 </Button>
                </>
            ),
            onClick: async (course: any) =>
                await new Promise((resolve, reject) => {
                    setEditClassInfo(course);
                }),
        },
        {
            title: (
                <>
                <Button color="inherit">
                    <FaUserEdit style={{ fontSize: "1.5rem", padding: "1px", marginLeft: "1px" }} />
                    &nbsp;ویرایش استاد و دانشجو
                </Button>
                </>
            ),
            onClick: async (course: any) =>
                await new Promise((resolve, reject) => {
                    setEditClassTeachSted(course);
                }),
        },
        // {
        //     title: (
        //         <>
        //         <Button color="inherit">
        //             <AttachMoneyIcon  style={{fontSize:"1.5rem"}}/>
        //             &nbsp;ویرایش پلن مالی
        //         </Button>
        //         </>
        //     ),
        //     onClick: async (course: any) =>
        //         await new Promise((resolve, reject) => {
        //             toast.info("به صورت پیش ‌فرض یک پلن مالی وجود دارد که فعلا غیرقابل تغییر است", { autoClose: 5000 });
        //             //  setEditClassFinPlan(course);
        //         }),
        // },
        // {
        //     title: (
        //         <>
        //          <Button color="inherit" >
        //             <Delete style={{fontSize:"1.5rem"}}/>
        //             &nbsp;  حذف
        //          </Button>
        //         </>
        //     ),
        //     onClick: (course: any) => setRemove(course.id),
        // },
    ];

    return (
        <>
            <Grid container spacing={2} sx={{mt:2}}>
                {/* <ListInfo /> */}
                <Grid item xs={12} style={{ width: 10 }}>
                    <Paper>
                        <TableProIpa
                            columns={columns}
                            bodyFunc={(column: any, row: any, index: any) => {
                                const head = column.id;
                                const value = row[column.id];
                                if (head === "action") {
                                    return (
                                        <TableCellStyled active={true} key={column.id + row.id} align={column.align}>
                                            <MenuActionTable menuId={row.id} items={itemsOfAction} icon={<Settings />} user={row} />
                                        </TableCellStyled>
                                    );
                                } else if (head === "complated") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align} 
                                        onClick={() => dispatch(toggleDetailsTable(false))}
                                        onMouseOut={() => {
                                            dispatch(toggleConfirmDetailsTable(false))
                                            dispatch(toggleDetailsTable(true))
                                        }}
                                        >
                                            <Button
                                                variant={"text"}
                                                color={value ? "success" : "warning"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value ? "بله" : "خیر"}
                                            </Button>
                                        </TableCell>
                                    );
                                } else if (head === "days") {
                                    return (
                                        <TableCell key={column.id + row.id} align={column.align}
                                        onClick={() => dispatch(toggleDetailsTable(false))}
                                        onMouseOut={() => {
                                            dispatch(toggleConfirmDetailsTable(false))
                                            dispatch(toggleDetailsTable(true))
                                        }}
                                        >
                                            <Button
                                                variant={"text"}
                                                color={"info"}
                                                style={{
                                                    width: column.minWidth,
                                                }}>
                                                {value?.map((item: any, index: any) => {
                                                    if (index + 1 !== value?.length) {
                                                        return `${item}/`;
                                                    } else {
                                                        return `${item}`;
                                                    }
                                                })}
                                            </Button>
                                        </TableCell>
                                    );
                                }
                                //  else if (head === "paymentPlans") {
                                //     return (
                                //         <TableCell key={column.id + row.id} align={column.align}
                                //         onClick={() => dispatch(toggleDetailsTable(false))}
                                //         onMouseOut={() => {
                                //             dispatch(toggleConfirmDetailsTable(false))
                                //             dispatch(toggleDetailsTable(true))
                                //         }}
                                //         >
                                //             <Button
                                //                 variant={"text"}
                                //                 color={"inherit"}
                                //                 style={{
                                //                     width: column.minWidth,
                                //                 }}>
                                //                 {value?.map((arrayPlanItem: any) => {
                                //                     return arrayPlanItem?.map((planItem: any) => {
                                //                         if (planItem[0] === "paymentplanName") {
                                //                             return `${planItem[1]}\n`;
                                //                         }
                                //                     });
                                //                 })}
                                //             </Button>
                                //         </TableCell>
                                //     );
                                // }
                                 else if (head === "classLink") {
                                  return (
                                        <TableCell key={column.id + row.id} align={column.align}
                                        onClick={() => dispatch(toggleDetailsTable(false))}
                                        onMouseOut={() => {
                                            dispatch(toggleConfirmDetailsTable(false))
                                            dispatch(toggleDetailsTable(true))
                                        }}
                                        >
                                            <a href={value} rel="noreferrer" target='_blank' id="class-link-tag">{value}</a>
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.format && value ? column.format(value) : value}
                                    </TableCell>
                                );
                            }}
                            rows={rows ?? []}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {editClassInfo ? (
                <ModalIpa
                    open={Boolean(editClassInfo)}
                    title={`ویرایش ${editClassInfo.name}`}
                    onClose={() => {
                        setEditClassInfo(null);
                        editInfoFormik.handleReset(1);
                    }}>
                    <form noValidate onSubmit={editInfoFormik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    value={editInfoFormik.values.CourseName ?? []}
                                    options={courseNameOpt ?? []}
                                    onChange={(e, value) => {
                                        editInfoFormik.setFieldValue("CourseName", value.length === 0 ? null : value);
                                    }}
                                    getOptionLabel={(user: any) => user.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={editInfoFormik.touched.CourseName && Boolean(editInfoFormik.errors.CourseName)}
                                            //@ts-ignore
                                            helperText={editInfoFormik.touched.CourseName && editInfoFormik.errors.CourseName}
                                            required
                                            fullWidth
                                            label="نام دوره"
                                            variant="outlined"
                                            name={"CourseName"}
                                            id="CourseName"
                                            // value={editInfoFormik.values.CourseName ?? []}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name={"ClassName"}
                                    onChange={editInfoFormik.handleChange}
                                    value={editInfoFormik.values.ClassName}
                                    error={editInfoFormik.touched.ClassName && Boolean(editInfoFormik.errors.ClassName)}
                                    helperText={editInfoFormik.touched.ClassName && editInfoFormik.errors.ClassName}
                                    fullWidth
                                    required
                                    type={"string"}
                                    label="نام کلاس"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    value={editInfoFormik.values.DaysOfWeek ?? []}
                                    onChange={(event: any, newValue: any) => {
                                        console.log(newValue);
                                        editInfoFormik.setFieldValue("DaysOfWeek", newValue.length === 0 ? null : newValue);
                                    }}
                                    multiple
                                    options={daysItem}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option, { selected }) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="روزهای تشکیل کلاس"
                                            required
                                            placeholder="روزهای تشکیل کلاس در هفته را انتخاب کنید"
                                            error={editInfoFormik.touched.DaysOfWeek && Boolean(editInfoFormik.errors.DaysOfWeek)}
                                            //@ts-ignore
                                            helperText={editInfoFormik.touched.DaysOfWeek && editInfoFormik.errors.DaysOfWeek}
                                            name={"DaysOfWeek"}
                                            id="DaysOfWeek"
                                            value={editInfoFormik.values.DaysOfWeek}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name={"ClassLink"}
                                    onChange={editInfoFormik.handleChange}
                                    value={editInfoFormik.values.ClassLink}
                                    error={editInfoFormik.touched.ClassLink && Boolean(editInfoFormik.errors.ClassLink)}
                                    helperText={editInfoFormik.touched.ClassLink && editInfoFormik.errors.ClassLink}
                                    fullWidth
                                    required
                                    type={"string"}
                                    label="لینک کلاس"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterJalali}>
                                    <DatePicker
                                        label="تاریخ شروع کلاس"
                                        mask="____/__/__"
                                        value={editInfoFormik.values.StartDate}
                                        onChange={(newValue: any) =>
                                            moment(newValue, "YYYY/MM/DD").isValid() &&
                                            editInfoFormik.setFieldValue("StartDate", moment(newValue).locale("en").format("YYYY-MM-DD"))
                                        }
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                label={"از تاریخ"}
                                                {...params}
                                                error={editInfoFormik.touched.StartDate && Boolean(editInfoFormik.errors.StartDate)}
                                                //@ts-ignore
                                                helperText={editInfoFormik.touched.StartDate && editInfoFormik.errors.StartDate}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterJalali}>
                                    <DatePicker
                                        label="تاریخ پایان کلاس"
                                        mask="____/__/__"
                                        value={editInfoFormik.values.EndDate}
                                        onChange={(newValue: any) =>
                                            moment(newValue, "YYYY/MM/DD").isValid() &&
                                            editInfoFormik.setFieldValue("EndDate", moment(newValue).locale("en").format("YYYY-MM-DD"))
                                        }
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                label={"تا تاریخ"}
                                                {...params}
                                                error={editInfoFormik.touched.EndDate && Boolean(editInfoFormik.errors.EndDate)}
                                                //@ts-ignore
                                                helperText={editInfoFormik.touched.EndDate && editInfoFormik.errors.EndDate}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterJalali}>
                                    <TimePicker
                                        label="ساعت شروع"
                                        ampm={false}
                                        value={editInfoFormik.values.StartTime}
                                        onChange={(newValue: any) => {
                                            editInfoFormik.setFieldValue("StartTime", newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                fullWidth
                                                {...params}
                                                error={editInfoFormik.touched.StartTime && Boolean(editInfoFormik.errors.StartTime)}
                                                helperText={editInfoFormik.touched.StartTime && editInfoFormik.errors.StartTime}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterJalali}>
                                    <TimePicker
                                        label="ساعت پایان"
                                        ampm={false}
                                        value={editInfoFormik.values.EndTime}
                                        onChange={(newValue: any) => {
                                            editInfoFormik.setFieldValue("EndTime", newValue);
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                required
                                                {...params}
                                                error={editInfoFormik.touched.EndTime && Boolean(editInfoFormik.errors.EndTime)}
                                                helperText={editInfoFormik.touched.EndTime && editInfoFormik.errors.EndTime}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name={"ClassDescription"}
                                    onChange={editInfoFormik.handleChange}
                                    value={editInfoFormik.values.ClassDescription}
                                    error={editInfoFormik.touched.ClassDescription && Boolean(editInfoFormik.errors.ClassDescription)}
                                    helperText={editInfoFormik.touched.ClassDescription && editInfoFormik.errors.ClassDescription}
                                    fullWidth
                                    type={"string"}
                                    label="توضیحات کلاس"
                                    variant="outlined"
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                     <LocalizationProvider dateAdapter={AdapterJalali}>
                        <DatePicker
                           label="تاریخ امتحان"
                           //    okText={"تایید"}
                           //    cancelText={"لغو"}
                           mask="____/__/__"
                           value={editInfoFormik.values.examDay}
                           onChange={(newValue: any) =>
                              moment(newValue, "YYYY/MM/DD").isValid() &&
                              editInfoFormik.setFieldValue("examDay", moment(newValue).locale("en").format("YYYY-MM-DD"))
                           }
                           renderInput={(params: any) => <TextField fullWidth {...params} 
                           error={editInfoFormik.touched.examDay && Boolean(editInfoFormik.errors.examDay)}
                                 helperText={editInfoFormik.touched.examDay && editInfoFormik.errors.examDay}
                           />}
                        />
                     </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <LocalizationProvider dateAdapter={AdapterJalali}>
                        <TimePicker
                           label="ساعت امتحان"
                           ampm={false}
                           value={editInfoFormik.values.examTime}
                           onChange={(newValue) => {
                              setSelectedEndTime(newValue);
                              editInfoFormik.setFieldValue("examTime", newValue);
                           }}
                           renderInput={(params) => (
                              <TextField
                                 fullWidth
                                 required
                                 {...params}
                                 error={editInfoFormik.touched.examTime && Boolean(editInfoFormik.errors.examTime)}
                                 helperText={editInfoFormik.touched.examTime && editInfoFormik.errors.examTime}
                              />
                           )}
                        />
                     </LocalizationProvider>
                  </Grid> */}
                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterJalali}>
                                    <DateTimePicker
                                        ampm={false}
                                        label="تاریخ و ساعت امتحان"
                                        value={editInfoFormik.values.examDayTime}
                                        onChange={(newValue: any) => editInfoFormik.setFieldValue("examDayTime", newValue)}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={editInfoFormik.touched.examDayTime && Boolean(editInfoFormik.errors.examDayTime)}
                                                //@ts-ignore
                                                helperText={editInfoFormik.touched.examDayTime && editInfoFormik.errors.examDayTime}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    sx={{
                                        "&  .MuiFormControlLabel-label": {
                                            fontWeight: 400,
                                        },
                                    }}
                                    control={
                                        <Switch
                                            checked={editInfoFormik.values.classCompleted}
                                            onChange={(event) => editInfoFormik.setFieldValue("classCompleted", event.target.checked)}
                                            name="financePlan1"
                                        />
                                    }
                                    label={`${editInfoFormik.values.classCompleted ? "پایان یافته" : "پایان نیافته"}`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonsModal
                                    textSubmit={"ذخیره"}
                                    textClose={"انصراف"}
                                    onClose={() => {
                                        setEditClassInfo(null);
                                        editInfoFormik.handleReset(1);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
            {editClassTeachSted ? (
                <ModalIpa
                    open={Boolean(editClassTeachSted)}
                    title={`ویرایش ${editClassTeachSted.name}`}
                    onClose={() => {
                        setEditClassTeachSted(null);
                        editTeachStedFormik.handleReset(1);
                    }}>
                    <form noValidate onSubmit={editTeachStedFormik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={editTeachStedFormik.values.StudentName ?? []}
                                    onChange={(event: any, newValue: any) => {
                                        editTeachStedFormik.setFieldValue("StudentName", newValue.length === 0 ? null : newValue);
                                    }}
                                    multiple
                                    limitTags={3}
                                    options={studentsNameOpt ?? []}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.fullName}
                                    renderOption={(props, option, { selected }) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.fullName}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="دانشجویان"
                                            required
                                            placeholder="دانشجویان کلاس را انتخاب کنید"
                                            error={
                                                editTeachStedFormik.touched.StudentName && Boolean(editTeachStedFormik.errors.StudentName)
                                            }
                                            //@ts-ignore
                                            helperText={editTeachStedFormik.touched.StudentName && editTeachStedFormik.errors.StudentName}
                                            name={"StudentName"}
                                            id="StudentName"
                                            value={editTeachStedFormik.values.StudentName}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={editTeachStedFormik.values?.TeacherName}
                                    onChange={(event: any, newValue: any) => {
                                        editTeachStedFormik.setFieldValue("TeacherName", newValue);
                                    }}
                                    options={teachersNameOpt ?? []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.fullName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="استاد"
                                            required
                                            placeholder="استاد کلاس را انتخاب کنید"
                                            error={
                                                editTeachStedFormik.touched.TeacherName && Boolean(editTeachStedFormik.errors.TeacherName)
                                            }
                                            //@ts-ignore
                                            helperText={editTeachStedFormik.touched.TeacherName && editTeachStedFormik.errors.TeacherName}
                                            name={"TeacherName"}
                                            id="TeacherName"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonsModal
                                    textSubmit={"ذخیره"}
                                    textClose={"انصراف"}
                                    onClose={() => {
                                        setEditClassTeachSted(null);
                                        editInfoFormik.handleReset(1);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null}
            {/* {remove ? (
                <ModalIpa open={Boolean(remove)} title={`آیا از حذف دوره مطمئن هستید؟`} onClose={() => setRemove(null)}>
                    <form onSubmit={handleRemoveCourse}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ButtonsModal
                                    textSubmit={"تایید"}
                                    textClose={"انصراف"}
                                    onClose={() => {
                                        setRemove(false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalIpa>
            ) : null} */}
        </>
    );
}

export { ClassList };
