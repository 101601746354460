import http from "../../utils/http.utils";

type Id = string;

export function getAllTeachers() {
    return http
        .get("/api/v2/Users/Teachers")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function GetLogedInTeacherClasses() {
    return http
        .get("/api/v2/ClassRooms/GetLogedInTeacherClasses")
        .then((res:any) => res.data.data)
        .catch((error:any) => error);
}

export function TeacherDashboardInfo() {
    return http
            .get("/api/v2/ClassRooms/TeacherDashboard")
            .then((res:any) => res.data.data)
            .catch((error:any) => error)
}