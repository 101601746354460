import React from "react";
import { StylesManager, Model, surveyLocalization } from "survey-core";
import { Survey } from "survey-react-ui";
import { persianSurveyStrings } from "../../utils/persianSurveyStrings";
import { json } from "./questionJson";
import "survey-core/modern.css";
import "./surveyStyle.scss";

StylesManager.applyTheme("modern");

surveyLocalization.locales["fa"] = persianSurveyStrings;
surveyLocalization.localeNames["fa"] = "فارْسِى";

var surveyValueChanged = function (sender: any, options: any) {
    var el = document.getElementById(options.name);
    console.log(el);
    if (el) {
        //@ts-ignore
        el.value = options.value;
    }
};

function SurveyMainPage() {
    const survey = new Model(json);
    survey.locale = "fa";
    survey.onComplete.add(function (sender: any) {
        // document.querySelector('#surveyResult').textContent = "Result JSON:\n" + JSON.stringify(sender.data, null, 3);
        console.log(JSON.stringify(sender.data));
    });
    return <Survey model={survey} onValueChanged={surveyValueChanged} />;
}

export { SurveyMainPage };
