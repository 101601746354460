import React, { useState } from "react";
import { Box, Divider, Drawer, FormControlLabel, IconButton, Radio, RadioGroup, Slider, Typography, useMediaQuery, useTheme } from "@mui/material";
import classes from "./setting-ipa.module.scss";
import { RotateLeft, SettingsOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { changeFontFamily } from "redux/globalSlice";
import { AiOutlineFontSize } from "react-icons/ai";
import SettingsIcon from '@mui/icons-material/Settings';

function SettingIpa() {
  //font-size
  //font-family
  //border-radius
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const fontFamily = useSelector((state: any) => state.global.fontFamily);

  const matches = useMediaQuery(useTheme().breakpoints.down("md"));

  return (
    <>
      <IconButton
        className={`${matches ? classes["icon-setting"] : null} setting-icon-help`}
        onClick={() => setOpen(true)}
        sx={{
          minHeight: 48,
          justifyContent:"center",
          px: 2.5,
          paddingBottom:"0px"
        }}>
        {matches ? (
          <SettingsIcon
            sx={{
              minWidth: 0,
              mr:"auto",
              justifyContent: "center",
              color:(theme)=> theme.palette.common.white
            }}
          />
        ) : (
          <AiOutlineFontSize
            style={{
              minWidth: 0,
              marginRight: "auto",
              justifyContent: "center",
              
            }}
          />
        )}
      </IconButton>
      <Drawer
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 112,
        }}
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}>
        {/* <Box m={2}>
        <Box boxShadow={2} style={{width: 200, borderRadius: useTheme().shape.borderRadius}}>
          <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography style={{padding: '5px 10px'}}>
              شعاع مرزها
            </Typography>
            <IconButton onClick={() => setBorderRadius(10)} size="large">
              <RotateLeft/>
            </IconButton>
          </Box>
          <Divider variant={'fullWidth'}/>
          <Box mt={5} px={2} style={{display: 'flex', alignItems: 'center'}}>
            <Typography>
              0
            </Typography>
            <Slider
                aria-label="borderRadius"
                value={borderRadius}
                valueLabelDisplay={'on'}
                step={2}
                onChange={(e, value) => setBorderRadius(value)}
                style={{margin: 'auto 1rem'}}
                marks
                min={0}
                max={30}
            />
            <Typography>
              30
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box m={2}>
        <Box boxShadow={2} style={{width: 200, borderRadius: useTheme().shape.borderRadius}}>
          <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography style={{padding: '5px 10px'}}>
              سایز فونت
            </Typography>
            <IconButton onClick={() => setFontSize(14)} size="large">
              <RotateLeft/>
            </IconButton>
          </Box>
          <Divider variant={'fullWidth'}/>
          <Box mt={5} px={2} style={{display: 'flex', alignItems: 'center'}}>
            <Typography>
              10
            </Typography>
            <Slider
                aria-label="Temperature"
                value={fontSize || 10}
                valueLabelDisplay={'on'}
                step={2}
                onChange={(e, value) => setFontSize(value)}
                style={{margin: 'auto 1rem'}}
                marks
                min={10}
                max={30}
            />
            <Typography>
              30
            </Typography>
          </Box>
        </Box>
      </Box> */}
        <Box m={2}>
          <Box boxShadow={2} style={{ width: 200, borderRadius: useTheme().shape.borderRadius }}>
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography style={{ padding: "5px 10px" }}>فونت</Typography>
              <IconButton onClick={() => dispatch(changeFontFamily("IRANSans"))} size="large">
                <RotateLeft />
              </IconButton>
            </Box>
            <Divider variant={"fullWidth"} />
            <Box mt={1} px={2} style={{ display: "flex", alignItems: "center" }}>
              <RadioGroup name="fontSize" value={fontFamily} onChange={({ target }) => dispatch(changeFontFamily(target.value))}>
                <FormControlLabel value="IRANSans" control={<Radio color="primary" />} label="ایران سنس" />
                <FormControlLabel value="BYekan" control={<Radio color="primary" />} label="بی یکان" />
                <FormControlLabel value="Vazir" control={<Radio color="primary" />} label="وزیر" />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export { SettingIpa };
