import { useEffect, useState } from "react";
import { Widget2 } from "../widgets/widget2";
import { motion } from "framer-motion";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Widget3 } from "../widgets/widget3";
import Widget4 from "../widgets/widget4";
import { GetStudentClassesSchedule, StudentDashboardInfo } from "api/student/studentApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Widget5 } from "../widgets/widget5";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Widget6 } from "../widgets/widget6";
import { getLicense } from "api/license/licenseApi";
import Widget7 from "../widgets/widget7";
import { Widget8 } from "../widgets/widget8";

function StudentDashboard() {
  const [dashboardInfo, setDashboardInfo] = useState<any>([]);
  const [dashboardSchedule, setDashboardSchedule] = useState<any>([]);
  const [dashboardLicense, setDashboardLicense] = useState<any>([]);

  const theme = useTheme();

  const user = useSelector((state: any) => state.global.user);

  const matches = useMediaQuery("(min-width:950px)");

  const getAllNeedData = async () => {
    const res: any = await StudentDashboardInfo();
    const resSche: any = await GetStudentClassesSchedule();
    const resLicense: any = await getLicense(user.userId);
    if (!(res instanceof Error)) {
      setDashboardInfo(res);
    } else {
      toast.error("دریافت داده‌ها با مشکل مواجه شد");
    }
    if (!(resSche instanceof Error)) {
      setDashboardSchedule(resSche);
    } else {
      toast.error("دریافت داده‌ها با مشکل مواجه شد");
    }
    if (!(resLicense instanceof Error)) {
      setDashboardLicense(resLicense);
    } else {
      toast.error("دریافت داده‌ها با مشکل مواجه شد");
    }
  };

  useEffect(() => {
    getAllNeedData();
  }, []);


  const data1 = {
    id: "card1-std",
    title: "کلاس‌ها",
    data: {
      name: "تعداد کل کلاس‌ها",
      count: dashboardInfo?.totalClasses,
      extra: {
        name: "تعداد کلاس‌های تمام شده:",
        count: dashboardInfo?.totalComplatedClasses,
      },
    },
    bgColor: "rgba(33, 150, 243 , .7)",
    color: "rgb(33, 150, 243)",
  };
  const data2 = {
    id: "card2-std",
    title: "ساعت‌ها",
    data: {
      name: "مجموع ساعت کلاس‌ها",
      count: dashboardInfo?.totalClassHour,
      extra: {
        name: "تعداد کلاس‌های تمام نشده:",
        // count: dashboardInfo?.totalRemainingClassHour,
           count: "",
      },
    },
    bgColor: "rgba(76, 175, 80 , .7)",
    color: "rgb(76, 175, 80)",
  };
  const data3 = {
    id: "card3-std",
    title: "نمرات",
    data: {
      name: "تعداد نمرات ممتاز",
      count: dashboardInfo?.totalExcellentGrades,
      extra: {
        name: "بهترین نمره ثبت شده:",
        count: dashboardInfo?.bestGradeEver,
      },
    },
    bgColor: "rgba(255, 152, 0 , .7)",
    color: "rgb(255, 152, 0)",
  };
  const data4 = {
    id: "card4-std",
    title: "مدارک",
    data: {
      name: "تعداد کل مدرک‌ها",
      count: dashboardInfo?.totalCertificates,
      extra: {
        name: "مجموع امتحان‌های رد شده:",
        count: dashboardInfo?.totalUnrejectedExams,
      },
    },
    bgColor: "rgba(153, 151, 151 , .8)",
    color: "rgb(153, 151, 151)",
  };

  const data5 = {
    id: "card4-std",
    title: "لایسنس",
    data: {
      license: user?.license?.length !== 0 ? user?.license : "لایسنس برای شما درج نشده است",
    },
    color: theme.palette.primary.main,
  };

  const data6 = {
    totalGradeOne: dashboardInfo?.totalGradeOne,
    totalgradeTwo: dashboardInfo?.totalgradeTwo,
    totalRejected: dashboardInfo?.totalRejected,
    totalExcellentGrades: dashboardInfo?.totalExcellentGrades,
  };

  const data7 = {
    yearlySchedule:dashboardInfo?.yearlySchedule
  }

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Grid container justifyContent={"space-between"} spacing={4}>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.4 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data1} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.7 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data2} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data3} />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} component={motion.div} initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 1.3 } }}>
        <div style={{ height: "100%" }}>
          <Widget2 widget={data4} />
        </div>
      </Grid>
       {/* Stepper */}
       <Grid item xs={12} sx={{height:{xs:"12vh", sm:"8.8vh"}}}>
          <Typography variant="h6" sx={{fontWeight:"bold"}}>برنامه هفتگی</Typography>
          <Typography >در این قسمت می‌توانید برنامه کلاس‌ها در هفته جاری مشاهده کنید.</Typography>
        </Grid>
      <Grid item xs={12}>
        <div>{matches ? <Widget5 dashboardSchedule={dashboardSchedule} /> : <Widget6 dashboardSchedule={dashboardSchedule} />}</div>
      </Grid>

        {/* License Table */}
        <Grid item xs={12} sx={{height:{xs:"9.2",sm:"8.8vh"}}}>
          <Typography variant="h6" sx={{fontWeight:"bold"}}>جدول لایسنس‌ها</Typography>
          <Typography >در این قسمت می‌توانید کل لایسنس‌های که به شما اختصاص داده شده است را مشاهده کنید.</Typography>
        </Grid>
      <Grid
        item
        xs={12}
        component={motion.div}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 1.3 } }}
        sx={{
          marginTop: "15px",
        }}>
        <div style={{ height: "100.8%" }}>
          <Widget3 widget={dashboardLicense} />
        </div>
      </Grid>

        <Grid item xs={12} md={6} sx={{height:{xs:"9vh", sm:"8.5vh"}}}>
          <Typography variant="h6" sx={{fontWeight:"bold"}}>نمودار مجموع ساعت کلاس‌ها</Typography>
          <Typography >در این نمودار می‌توانید مجموع ساعت کل کلاس‌ها را در هر ماه مشاهده کنید.</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{height:{xs:"9vh", sm:"8.5vh"} , display:{xs:"none", md :"block"}}}>
          <Typography variant="h6" sx={{fontWeight:"bold"}}>چارت نمرات</Typography>
          <Typography >در این چارت می‌توانید تعداد مدرک‌ها بر حسب نوع مشاهده کنید.</Typography>
        </Grid>
      {/* BarChar */}
      <Grid
        item
        xs={12}
        md={6}
        component={motion.div}
        initial={{ x: -1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 1.3, type: "spring", mass: 0.4 }}>
        <div style={{height:"100%"}}>
          <Widget7 widget={data7}/>
        </div>
      </Grid>
      {/* PolarArea */}
      <Grid item xs={12} sx={{height:{xs:"12vh", sm:"8.8vh"} , display:{xs:"block", md :"none"}}}>
          <Typography variant="h6" sx={{fontWeight:"bold"}}>چارت نمرات</Typography>
          <Typography >در این چارت می‌توانید تعداد مدرک‌ها بر حسب نوع مشاهده کنید.</Typography>
        </Grid>
      <Grid
        item
        xs={12}
        md={6}
        component={motion.div}
        initial={{ x: -1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 1.3, type: "spring", mass: 0.4 }}>
        <div style={{height:"100%"}}>
          <Widget4 widget={data6}/>
        </div>
      </Grid>
     
    
      {/* <Grid item xs={4}>
        <Widget8 />
      </Grid> */}
    </Grid>
  );
}

export { StudentDashboard };
